import React, { useState, useEffect } from "react";
import { toastNotify, validateForm, loadingData } from "../../../components/Helper";
import { fetchData, DOWNLOAD_HOME } from "../../../components/Service";

function DownloadSection() {
    window.document.title = "Download Section";

    const [langType, setLangType] = useState('en');
    const [data, setData] = useState('');
    const [tinyloader, setTinyloader] = useState(false);
    const [iosValue, setIosValue] = useState(false)
    const [androidValue, setAndroidValue] = useState(false)

    const handleInputChange = async (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    useEffect(() => {
        loadingData(true)
        fetchData(DOWNLOAD_HOME + `?lang_type=${langType}`, 'GET', '', true, false, (res) => {
            loadingData(false)
            setData(res.records)
            res.records.download_ios_on === "true" ? setIosValue(true) : setIosValue(false)
            res.records.download_android_on === "true" ? setAndroidValue(true) : setAndroidValue(false)
        }, (err) => { })
    }, [langType])

    const updateGeneralData = async (e) => {
        let formData = ({
            download_title: data.download_title,
            download_subtitle: data.download_subtitle,
            download_android: data.download_android,
            download_ios: data.download_ios,
            download_ios_on: iosValue,
            download_android_on: androidValue
        })
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(DOWNLOAD_HOME + `?lang_type=${langType}`, 'POST', formData, true, false, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateGeneralData').reset()
        setLangType(lang)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Download Section</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateGeneralData" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                                <div className="bg-white p-3 rounded-10" id="englishbox" role="tabpanel">
                                    <div className="row my-3">
                                        <label className="col-md-3 text-md-end col-form-label">Title:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="download_title" id="download_title" spellCheck="false" defaultValue={data.download_title} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Subtitle:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <textarea className="form-control" rows="6" name="download_subtitle" id="download_subtitle" defaultValue={data.download_subtitle} required></textarea>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label"><strong>Android</strong> App Url:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''} >
                                            <div className="input-group">
                                                <label className="input-group-text">
                                                    <input className="form-check-input mt-0 me-2" type="checkbox" name="download_android_on" defaultValue="" id="download_android_on" aria-label="Checkbox for following text input" checked={androidValue} onClick={() => setAndroidValue(!androidValue)} /> Enable
                                                </label>
                                                <input type="url" className="form-control" name="download_android" id="download_android" defaultValue={data.download_android} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label"><strong>iOS</strong> App Url:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <div className="input-group">
                                                <label className="input-group-text">
                                                    <input className="form-check-input mt-0 me-2" type="checkbox" name="download_ios_on" id="download_ios_on" defaultValue="" aria-label="Checkbox for following text input" checked={iosValue} onClick={() => setIosValue(!iosValue)} /> Enable
                                                </label>
                                                <input type="url" className="form-control" name="download_ios" id="download_ios" defaultValue={data.download_ios} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-31 py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateGeneralData(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                        :
                                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DownloadSection;
