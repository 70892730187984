import React, { useEffect, useRef, memo } from "react";
const ComboSelect = memo(function ComboSelect(props) {
    const myRefname = useRef(null);

    useEffect(() => {
        if (document.querySelector('.offcanvas')!==null){
            document.querySelector('.offcanvas').addEventListener('hidden.bs.offcanvas', function (e) {
                document.querySelector('.offcanvas .offcanvas-body').scroll({
                    top: 0
                });
                document.querySelector(`[data-mslect="${props.id}"] .select-dropdown`).innerHTML = '';
                [...document.querySelectorAll(`[data-mslect="${props.id}"] li.active`)].map((list) => {
                    return list.classList.remove('active')
                });
            })
        }  

        if (document.querySelector(`[data-mslect="${props.id}"] .select-dropdown`) !== null) {
            selectedBadge(props.id, props.type);
        }
    
    }, [props.selected]);


    const selectedBadge = (idd, type) => {
        if (idd !== undefined) {
            let sl = props.selected && props.selected !== '' ? (props.selected).split(',') : [];
            if(type==='multiple'){    
                document.getElementById(idd).value = sl;
                document.querySelector(`[data-mslect="${idd}"] .select-dropdown`).innerHTML = '';
                document.querySelector(`[data-mslect="${idd}"] .select-dropdown`).innerHTML += sl.map((ch) => {
                    let listCheck = document.querySelector(`[data-mslect="${idd}"] .dropdown-menu .dropdown-item[data-keyvalue="${ch}"]`);
                    if (listCheck !== null) {
                        listCheck.classList.add('active');
                        return (`<span class="badge" data-keyvalue=${ch}>${listCheck.innerText}
                            <strong onclick="removeBadge('${idd}', '${ch}')">&times;</strong>
                        </span>`
                        )
                    }
                }).join('');
            }
            else{
                let listCheck = document.querySelector(`[data-mslect="${idd}"] .dropdown-menu .dropdown-item[data-keyvalue="${sl}"]`);
                if (listCheck !== null) {
                    listCheck.classList.add('active');
                    document.getElementById(idd).value = sl;
                    document.querySelector(`[data-mslect="${idd}"] .select-dropdown`).innerHTML = listCheck.innerText
                }
            }
        }
    }

    // setTimeout(function(){
    // }, 1000)

    const ddItem = window.ddItem = (e, key, label, idz, type) => {
        if(type==='single'){
            document.getElementById(idz).value = key;
            document.querySelector(`[data-mslect=${idz}] .select-dropdown`).innerHTML = label;
            [...document.querySelectorAll(`[data-mslect="${idz}"] li`)].forEach((elem) => {
                elem.classList.remove('active');
            })
            e.target.classList.add('active')
        }
        else{ /*Multiple Selection*/
            e.preventDefault();
            e.target.classList.add('active');
            document.querySelector(`[data-mslect="${idz}"] .select-dropdown`).insertAdjacentHTML('beforeend', 
                `<span class="badge" data-keyvalue="${key}">
                    ${label} <strong onclick="removeBadge('${idz}', '${key}')">&times;</strong>
                </span>`
            );
        }

        valueSetter(idz);
        return props.method && props.method!==''? props.method(myRefname.current.value): '';
    }

    const valueSetter = (itemid) => {
        document.getElementById(itemid).value = '';
        document.getElementById(itemid).value = [...document.querySelectorAll(`[data-mslect="${itemid}"] li.active`)].map(list => {
            return list.dataset.keyvalue
        });
    }

    window.removeBadge = (idz, key) => {
        let badgebox = document.querySelector(`[data-mslect="${idz}"] .select-dropdown [data-keyvalue="${key}"]`);
        let listBox = document.querySelector(`[data-mslect="${idz}"] li[data-keyvalue="${key}"]`);
        if (badgebox !== null) {
            badgebox.remove();
            if (listBox !== null) {
                listBox.classList.remove('active');
            }
        }
        valueSetter(idz);
    }

    return (
        <>
        <div className="dropdown dropdown-badge" data-mslect={props.id}>
            <input type="text" className="form-control d-none" 
                name={props.name} 
                id={props.id} 
                defaultValue={""} 
                placeholder="Select" 
                required={props.required}
                ref={myRefname}
            />
            <div className="form-select select-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" data-placeholder={props.placeholder}></div>
                <ul className="dropdown-menu w-100 border-0 shadow rounded-15 px-2" data-popper-placement="auto" data-types={props.type}>
                {
                    props.options && props.options!==''? Object.entries(props.options).map((ele, ind) => {
                        return (
                            <li className="dropdown-item rounded-20" onClick={(e) => ddItem(e, ele[0], ele[1], props.id, props.type)} data-keyvalue={ele[0]} key={ind} role="button">{ele[1]}</li>
                        )
                    }) : <li className="dropdown-item pe-none opacity-50">No Records</li>
                }
            </ul>
            <div className="invalid-feedback">This is required field.</div>
        </div>
        </>
    )
})
export default ComboSelect