import React from "react";

function FrontStyle(props){
    return (
        <style>
            {`
            @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
            :root {
                --blue-dark: #004f7f;
                --blue-light: #56b6e7;
            }
            body {
                font-family: 'Montserrat', sans-serif;
            }
            `}
        </style>
    )
}

export default FrontStyle