import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, ConfirmationModal, loadingData } from "../../components/Helper";
import { fetchData, SUBSCRIPTION_LIST, CREATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION, DELETE_SUBSCRIPTION } from "../../components/Service";

function SubscriptionData() {
    window.document.title = "Subscription Data";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Subscription Menu")
    const [formfield, setFormfield] = useState({
        subscriptionmenu_id: "",
        title: "",
        price: "",
        discount: "",
        duration: "+ 0 day",
        year: ""
    });

    /*Start | Datatable Method*/ 
    const [records, setRecords] = useState("");
    const [tablespinner, setTablespinner] = useState(true)

    const [dt, setDt] = useState({
        fetchurl: SUBSCRIPTION_LIST,
        search: '',
        perpage: '5',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        
        loadingData(true)
        setTablespinner(true)
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            setTablespinner(false)
        });

        document.getElementById("subscriptionForm").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset();
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Subscription Menu");
            setFormfield({
                subscriptionmenu_id: "",
                title: "",
                price: "",
                discount: "",
                duration: "+ 0 day",
                year: ""
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/


    const updateSubscriptionForm = async (e) => {
        let formdata = new FormData(document.getElementById('subscriptionForm'));
        let subs_id = document.querySelector('#subscriptionForm [name="subscriptionmenu_id"]').value;
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (subs_id === null || subs_id === '') {
                fetchData(CREATE_SUBSCRIPTION, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        toastNotify('danger', res.message)
                    }
                    setTinyloader(false);
                }, (err) => {});
            } else {
                fetchData(UPDATE_SUBSCRIPTION + '/' + subs_id, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        toastNotify('danger', res.message)
                    }
                    setTinyloader(false);
                }, (err) => {});
            }
        }
    }

    const editForm = (rec) => {
        setOffcanvastitle("Update Subscription Menu");
        setFormfield(rec)
    }

    const deleteRecords = (id) => {
        setUpdatetable(true);
        fetchData(DELETE_SUBSCRIPTION + '/' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Subscription Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#subscriptionForm">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner" data-tablespinner={tablespinner}>
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th data-orderby="asc" data-sortby="title" onClick={(e) => dtActions(e, 'sortby')}>Title</th>
                                                <th data-orderby="asc" data-sortby="price" onClick={(e) => dtActions(e, 'sortby')}>Price (QAR)</th>
                                                <th data-orderby="asc" data-sortby="discount" onClick={(e) => dtActions(e, 'sortby')}>Discount</th>
                                                <th data-orderby="asc" data-sortby="duration" onClick={(e) => dtActions(e, 'sortby')}>Duration</th>
                                                <th width="140">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.title}</td>
                                                    <td>{ele.price}</td>
                                                    <td>{ele.discount}</td>
                                                    <td>{ele.duration}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#subscriptionForm" onClick={() => editForm(ele)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, subscriptionmenu_id: ele.subscriptionmenu_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="subscriptionForm" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <input type="text" className="d-none" name="subscriptionmenu_id" defaultValue={formfield.subscriptionmenu_id} />
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Title<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="title" spellCheck="false" defaultValue={formfield.title} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, title: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Price (QAR)<strong className="text-danger">*</strong></div>
                        <input type="number" className="form-control" name="price" min="0" step=".01" defaultValue={formfield.price} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, price: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Discount (%)<strong className="text-danger">*</strong></div>
                        <input type="number" className="form-control" name="discount" min="0" step=".01" defaultValue={formfield.discount} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, discount: e.target.value
                        }))} required />
                    </label>
                    <div className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Duration<strong className="text-danger">*</strong></div>
                        <div className="d-flex">
                            <input type="number" className="form-control maxw-120 me-3" name="duration" min="0" step=".01" value={formfield.duration.split(' ')[1]} onInput={(e) => setFormfield(prevState => ({
                                ...prevState, duration: e.target.value
                            }))} required/>
                            <select className="form-select" name="year" defaultValue={formfield.duration.split(' ')[2]} required>
                                <option value="day" selected={formfield.duration.split(' ')[2] === "daye"}>Day</option>
                                <option value="month" selected={formfield.duration.split(' ')[2] === "month"}>Month</option>
                                <option value="year" selected={formfield.duration.split(' ')[2] === "year"}>Year</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => updateSubscriptionForm(e)} disabled={tinyloader}>
                    {
                        !tinyloader ? 'Submit'
                        :
                        <div className="spinner-border spinner-border-sm ms-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.subscriptionmenu_id)} />
        </>
    )
}

export default SubscriptionData
