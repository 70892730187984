import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, ORDER_LIST, CHANGE_ORDER_STATUS, ASSET_ENDPOINT } from "../../components/Service";

function OrderList() {
    window.document.title = "Order List";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [formfield, setFormfield] = useState({
        order_id: "",
        order_code: "",
        user_id: "",
        payment_id: "",
        company_id: "",
        product_id: "",
        product_name: "",
        product_price: "",
        order_status: "",
        order_at: "",
        guarantee: "",
        paid_amount: "",
        payment_method: "",
        payment_status: "",
        user_name: "",
        company_name: "",
        guarantee_imageb64: ""
    })

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: ORDER_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        f_type: '',
        pay_status_type: '',
        pay_method_type: '',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'orderlistTable')
        Datatable(dt, (result) => {
            tableSpinner(false, 'orderlistTable')
            loadingData(false)
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        /*Start*/ 
        document.getElementById("guaranteePreviewModal").addEventListener('show.bs.modal', function (e) {
            setFormfield(prevState => ({
                ...prevState,
                order_id: e.relatedTarget.dataset.orderid,
                guarantee: e.relatedTarget.dataset.previewimg
            }))
        });
        document.getElementById("guaranteePreviewModal").addEventListener('hidden.bs.modal', function (e) {
            setFormfield(prevState => ({
                ...prevState,
                order_id: "",
                guarantee_imageb64: ""
            }))
        });
        /*Close*/ 

        document.getElementById("guaranteeModal").addEventListener('show.bs.modal', function (e) {
            setFormfield(prevState => ({
                ...prevState,
                order_id: e.relatedTarget.dataset.orderid
            }))
        });

        document.getElementById("guaranteeModal").addEventListener('hidden.bs.modal', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setFormfield(prevState => ({
                ...prevState,
                order_id: "",
                guarantee_imageb64: ""
            }))
        });

        document.getElementById("updateOrder").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setFormfield({ // Set empty state
                order_id: "",
                order_code: "",
                user_id: "",
                payment_id: "",
                company_id: "",
                product_id: "",
                product_name: "",
                product_price: "",
                order_status: "",
                order_at: "",
                guarantee: "",
                paid_amount: "",
                payment_method: "",
                payment_status: "",
                user_name: "",
                company_name: ""
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const setOrderStatus = (e) => {
        let formdataz = document.querySelector('#updateOrder [name="status"]').value;
        setTinyloader(true);
        setUpdatetable(true);
        if (formfield.order_id !== null || formfield.order_id !==''){
            fetchData(CHANGE_ORDER_STATUS + '/' + formfield.order_id, 'POST', { status: formdataz}, true, false, (res) => {
                if (res.status) {
                    toastNotify('success', res.message)
                    document.querySelector('#updateOrder [data-bs-dismiss="offcanvas"]').click();
                    setUpdatetable(false);
                } else {
                    toastNotify('danger', res.message)
                }
                setTinyloader(false);
            }, (err) => { });
        }
    }


    const orderData = (rec) => {
        setFormfield(rec)  
    }


    const guranteeImgFunction = async (e) => {
        let formdata = new FormData(document.getElementById('guaranteeModal'));
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            fetchData(CHANGE_ORDER_STATUS + '/' + formfield.order_id, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    document.querySelector('#guaranteeModal [data-bs-dismiss="modal"]').click();
                    document.querySelector('#guaranteePreviewModal [data-bs-dismiss="modal"]').click();
                    setUpdatetable(false);
                } else {
                    toastNotify('danger', res.message)
                }
            }, (err) => { });
        }
    }
    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            guarantee_imageb64: imgb64
        }))
    }


    const setFilter = (type, vl) =>{
        if (type === 'order_status'){
            setDt(prevState => ({
                ...prevState,
                f_type: vl
            }))
        }
        if (type ==='payment_status'){
            setDt(prevState => ({
                ...prevState,
                pay_status_type: vl
            }))
        }
        if (type === 'payment_method') {
            setDt(prevState => ({
                ...prevState,
                pay_method_type: vl,
            }))
        }
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Order List</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="dropdown">
                                            <button type="button" className="btn btn-pageof bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                                <svg className="icon mx-1" role="img"><use href="#icon_filter"></use></svg>
                                                Filters
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end shadow rounded-10 p-3 border-0 w-sm-500">
                                                <div className="row row-cols-1 row-cols-sm-3 g-3">
                                                    <div className="col">
                                                        <h6 className="text-blue fw-medium text-nowrap mb-sm-3">Order Status</h6>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="order_status" value="all" onChange={() => setFilter('order_status', '')} defaultChecked/>
                                                            <div className="form-check-label">All</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="order_status" value="on_progress" onChange={() => setFilter('order_status', 'order_status__progress')}/>
                                                            <div className="form-check-label">On Progress</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="order_status" value="on_deliver" onChange={() => setFilter('order_status', 'order_status__deliver')} />
                                                            <div className="form-check-label">On Deliver</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="order_status" value="completed" onChange={() => setFilter('order_status', 'order_status__done')} />
                                                            <div className="form-check-label">Completed</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="order_status" value="failed" onChange={() => setFilter('order_status', 'order_status__failed')} />
                                                            <div className="form-check-label">Failed</div>
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-blue fw-medium text-nowrap mb-sm-3">Payment Status</h6>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_status" value="all" onChange={() => setFilter('payment_status', '')} defaultChecked />
                                                            <div className="form-check-label">All</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_status" value="pending" onChange={() => setFilter('payment_status', 'payment_status__pending')} />
                                                            <div className="form-check-label">Pending</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_status" value="success" onChange={() => setFilter('payment_status', 'payment_status__success')} />
                                                            <div className="form-check-label">Success</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_status" value="failed" onChange={() => setFilter('payment_status', 'payment_status__failed')} />
                                                            <div className="form-check-label">Failed</div>
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-blue fw-medium text-nowrap mb-sm-3">Payment Method</h6>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_method" value="all" onChange={() => setFilter('payment_method', '')} defaultChecked />
                                                            <div className="form-check-label">All</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_method" value="credit_card" onChange={() => setFilter('payment_method', 'payment_method__cc')}/>
                                                            <div className="form-check-label">Credit Card</div>
                                                        </label>
                                                        <label className="form-check">
                                                            <input className="form-check-input" type="radio" name="payment_method" value="cash_on_delivery" onChange={() => setFilter('payment_method', 'payment_method__cod')}/>
                                                            <div className="form-check-label">Cash On Delivery</div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="orderlistTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th>Order Date</th>
                                                <th width="180" data-orderby="asc" data-sortby="order_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Order Code
                                                </th>
                                                <th data-orderby="asc" data-sortby="order_status" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Order Status
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="user_name" onClick={(e) => dtActions(e, 'sortby')}>User</th>
                                                <th data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>Company</th>
                                                <th data-orderby="asc" data-sortby="product_name" onClick={(e) => dtActions(e, 'sortby')}>Product</th>
                                                <th data-orderby="asc" data-sortby="product_price" onClick={(e) => dtActions(e, 'sortby')}>Paid Total</th>
                                                <th data-orderby="asc" data-sortby="payment_method" onClick={(e) => dtActions(e, 'sortby')}>Payment Method</th>
                                                <th width="154" data-orderby="asc" data-sortby="payment_status" onClick={(e) => dtActions(e, 'sortby')}>Payment Status</th>
                                                <th width="110" className="text-center">Guarantee</th>
                                                <th width="90" className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.order_at).toDateString()}</td>
                                                    <td>{ele.order_code}</td>
                                                    <td className="text-capitalize">
                                                        {
                                                            ele.order_status !== null && ele.order_status.split('__')[1]
                                                        }
                                                    </td>
                                                    <td>{ele.user_name}</td>
                                                    <td>{ele.company_name}</td>
                                                    <td>{ele.product_name}</td>
                                                    <td>{ele.product_price}</td>
                                                    <td>
                                                        {
                                                            (ele.payment_method).split('__')[1] === "cod" ? "Cash on Delivery" : "Credit Card"
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            (ele.payment_status).split('__')[1] === "pending" ? <span className="badge text-dark bg-warning rounded-pill fw-medium minw-70">Pending</span> : (ele.payment_status).split('__')[1] === "failed" ? <span className="badge bg-danger rounded-pill fw-medium minw-70">Faild</span> : <span className="badge bg-success rounded-pill fw-medium minw-70">Success</span>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            ele.guarantee !== null ?
                                                            <div className="img-hoveraction position-relative rounded-6 shadow-sm overflow-hidden" role="button" data-bs-target="#guaranteePreviewModal" data-bs-toggle="modal" title="Preview" data-orderid={ele.order_id} data-previewimg={ele.guarantee}>
                                                                <img src={ASSET_ENDPOINT + ele.guarantee} alt="guarantee" className="w120-h80 objfit-cover bg-info-light-15" onError={noimageHandle} />
                                                                <div className="top-0 position-absolute w-100 h-100 d-flex justify-content-center align-items-center zindex-1 img-showaction text-blue">
                                                                    <svg className="icon fs-3 lh-1" role="img"><use href="#icon_eye"/></svg>
                                                                </div>
                                                            </div>
                                                            :
                                                            <label data-bs-toggle="modal" data-bs-target="#guaranteeModal" data-orderid={ele.order_id} className="btn btn-sm btn-primary px-3">Upload</label>
                                                        }
                                                    </td>
                                                    <td className="text-nowrap text-center">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => orderData(ele)} data-bs-toggle="offcanvas" data-bs-target="#updateOrder">
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={11}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={11}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            {/* Start | Modal for Image Preview */}
            <div className="modal fade" tabIndex="-1" id="guaranteePreviewModal" data-bs-backdrop="true" data-bs-keyboard="false">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content rounded-10 overflow-hidden border-0 shadow-lg">
                        <div className="moday-body p-3">
                            <img src={!formfield.guarantee_imageb64 ? ASSET_ENDPOINT + '/' + formfield.guarantee : formfield.guarantee_imageb64} alt="ads_image" className="w-100 rounded-6" onError={noimageHandle}/>
                        </div>
                        <div className="modal-footer bg-light justify-content-between">
                            <label htmlFor="guarantee_image" className="btn btn-primary px-3">Browse Image</label>
                            <button type="button" className="btn btn-success me-auto minw-140" form="guaranteeModal" onClick={(e) => guranteeImgFunction(e)} disabled={tinyloader}>
                                {
                                    !tinyloader ? 'Update Image'
                                    :
                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End | Modal for Image Preview */}


            {/* Start | Upload Gurantee Image */}
            <form className="modal fade needs-validation" tabIndex="-1" id="guaranteeModal" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-10 overflow-hidden border-0 shadow-lg">
                        <div className="modal-header border-bottom bg-light px-sm-4">
                            <h5 className="text-blue m-0">Upload Guarantee</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="moday-body p-4 py-5">
                            <input type="hidden" value="upload_doc" name="status" className="d-none" />
                            <label className="image-uploading d-flex align-items-center">
                                <img src={formfield.guarantee_imageb64} alt="ads_image" className="me-3" onError={noimageHandle} />
                                <button type="button" className="btn btn-info text-nowrap">Upload Image</button>
                                <input type="file" id="guarantee_image" accept="image/*" className="form-field" name="verification_document" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                                    ...prevState,
                                    guarantee: imageSet(e)
                                }))} required />
                                <div className="invalid-feedback">
                                    Required to upload image.
                                </div>
                            </label>
                        </div>
                        <div className="modal-footer justify-content-center justify-content-sm-start border-top bg-light p-3 px-sm-4">
                            <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => guranteeImgFunction(e)} disabled={tinyloader}>
                                {
                                    !tinyloader ? 'Submit'
                                    :
                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </button>
                            <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </form>
            {/* End | Upload Gurantee Image */}


            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="updateOrder" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">Update Order</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Order Code<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="order_code" defaultValue={formfield.order_code} disabled/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Order Date/Time<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="order_at" defaultValue={formfield.order_at} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Order Date/Time<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="order_at" defaultValue={formfield.order_at} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">User Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="user_name" defaultValue={formfield.user_name} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="product_name" defaultValue={formfield.product_name} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Quantity<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="product_amount" defaultValue={formfield.product_amount} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Price<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="product_amount" defaultValue={formfield.product_amount} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Total<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="product_price" defaultValue={formfield.product_price} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Payment Method<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="payment_method" defaultValue={formfield.payment_method} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Payment Status<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control text-capitalize" name="payment_status" defaultValue={
                            formfield.payment_status.split('__')[1]
                        } disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Transaction Code*<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="transaction_code" defaultValue={formfield.transaction_code} disabled />
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light text-center text-sm-start p-3 p-sm-4">
                    <div className="mb-4">
                        <select name="status" className="form-select" required>
                            <option value="order_status__progress" selected={formfield.order_status === 'order_status__progress' ? true: false}>On Progress</option>
                            <option value="order_status__deliver" selected={formfield.order_status === 'order_status__deliver' ? true: false}>On Deliver</option>
                            <option value="order_status__done" selected={formfield.order_status === 'order_status__done' ? true: false}>Completed</option>
                            <option value="order_status__failed" selected={formfield.order_status === 'order_status__failed' ? true: false}>Failed</option>
                        </select>
                    </div>
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => setOrderStatus(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
        </>
    )
}

export default OrderList