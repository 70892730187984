import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { ConfirmationModal, toastNotify, loadingData } from "../../components/Helper";
import { DELETE_WITHDRAW, fetchData, WITHDRAW_CONFIRMATION, WITHDRAW_LIST } from "../../components/Service";

function WithdrawConfirmation() {
    window.document.title = "Withdraw Confirmation";
    const [records, setRecords] = useState("");
    const [tinyloader, setTinyloader] = useState(false);
    const [tablespinner, setTablespinner] = useState(true)
    const [updatetable, setUpdatetable] = useState(true);
    const [offcanvastitle, setOffcanvastitle] = useState("Confirm Withdraw")
    const [formfield, setFormfield] = useState('')
    const [userType, setUserType] = useState('admin')

    const [dt, setDt] = useState({
        fetchurl: WITHDRAW_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        f_type:'confirm',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            setUserType(user.userType)
        }
        loadingData(true)
        setTablespinner(true)
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            setTablespinner(false)
        });

        document.getElementById("addupdateCategory").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Confirm Withdraw");
        });


    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }

    const editForm = (rec) => {
        setFormfield(rec)
        setOffcanvastitle("Confirm Withdraw");
    }

    const confirmWithdraw = async (e) => {
        setTinyloader(true);
        setUpdatetable(true);
        fetchData(WITHDRAW_CONFIRMATION+'?withdraw_id='+formfield.withdraw_id, 'POST', '', true, false, (res) => {
            if (res.status) {
                toastNotify('success', res.message);
                document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                setUpdatetable(false);
                localStorage.setItem('isnotify', 'true'); /*Update Notifications*/
            } else {
                if(Object.keys(res.errors).length>0){
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            document.querySelector(`[name="${v}"]`).value = "";
                            return toastNotify('danger', ele[v])
                        });
                    })
                }else{
                    toastNotify('danger', res.message)
                }
            }
            setTinyloader(false);
        }, (err) => { });
    }

    const deleteRecords = (id) => {
        setUpdatetable(true);
        fetchData(DELETE_WITHDRAW + '/' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
            if (res.status) {
                setUpdatetable(false);
                toastNotify('success', res.message)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }
    

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Withdraw Confirmation</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto">&nbsp;</div>
                                </div>
                            </div>
                            <div className="table-spinner" data-tablespinner={tablespinner}>
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th className="minw-200 text-nowrap" data-orderby="asc" data-sortby="created_at" onClick={(e) => dtActions(e, 'sortby')}>Requested At</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="pending_amount" onClick={(e) => dtActions(e, 'sortby')}>Requested Amount</th>
                                                { userType && userType === 'admin' ? <th className="text-nowrap" data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Company Name
                                                </th> : '' }
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="bank_name" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Bank Name
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="bank_holder_name" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Bank Account
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="bank_holder_account" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Bank Holder Account
                                                </th>
                                                <th width="90">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.requested_at).toDateString()}</td>
                                                    <td>{ele.withdraw_amount}</td>
                                                    { userType && userType === 'admin' ? <td>{ele.company_name}</td> : '' }
                                                    <td>{ele.bank_name}</td>
                                                    <td>{ele.bank_holder_name}</td>                                                    
                                                    <td>{(ele.bank_holder_account)}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={()=>editForm(ele)} data-bs-toggle="offcanvas" data-bs-target="#addupdateCategory">
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, withdraw_id: ele.withdraw_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addupdateCategory" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <input type="text" className="d-none" name="withdraw_id" defaultValue={formfield.withdraw_id}/>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Bank Name<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="bank_name" defaultValue={formfield.bank_name}required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Bank Holder Account<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="bank_holder_account" defaultValue={formfield.bank_holder_account}  required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Bank Holder Name<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="bank_holder_name" defaultValue={formfield.bank_holder_name} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Withdraw Amount<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="withdraw_amount" defaultValue={formfield.withdraw_amount} required/>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-success rounded-pill minw-140 fw-medium me-3" onClick={(e) => confirmWithdraw(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Confirm'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.withdraw_id)} />
        </>
    )
}

export default WithdrawConfirmation