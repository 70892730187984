import React, { useEffect, useState } from 'react';
import { toastNotify, noimageHandle, loadingData } from "../../components/Helper";
import { fetchData, RECENT_COMPANY_WORK, RECENT_COMPANY_WORK_DELETE, ASSET_ENDPOINT } from "../../components/Service";

function RecentWork(){
    window.document.title = "Recent Work";
    const [previewImage, setPreviewImage] = useState()
    const [relode, setRelode] = useState(1)

    const imageSet = async (e) => {
        let form = document.getElementById('recentWorkForm')
        let data = new FormData(form);
        fetchData(RECENT_COMPANY_WORK, 'POST', data, true, true, (res) => {
            if (res.status) {
                toastNotify('success', res.message)
                setRelode(relode + 1)
            } else {
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.message)
                }
            }
        }, (err) => {});
    }


    const removeImage = (img) => {
        fetchData(RECENT_COMPANY_WORK_DELETE, 'POST', {img: img}, true, false, (res) => {
            if (res.status) {
                toastNotify('success', res.message)
                setRelode(relode + 1)
            } else {
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.message)
                }
            }
        }, (err) => {});
    }
    

    useEffect(() => {
        loadingData(true)
        fetchData(RECENT_COMPANY_WORK, 'GET', '', true, false, (res) => {
            loadingData(false)
            if (res.status) {
                setPreviewImage(res.records)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }, [relode])


    return(
        <>
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between d-md-none">
                    <div className="col mb-2">
                        <h4 className="page-heading">Recent Work</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row mx-0 bg-white rounded-15 p-4 shadow-sm">
                            <div className="col-sm-12 mt-2">
                                { previewImage ? previewImage.map((item, key) => {
                                    return(
                                        <>
                                        <div className="mmwh-160 position-relative d-inline-block me-3 mb-3 align-top border rounded-6" key={key} id={item.id}>
                                            <img src={ASSET_ENDPOINT + '/' + item.works_image} className="objfit-cover h-100 w-100 position-absolute p-2" alt="Work" onError={noimageHandle} />
                                            <button type="button" className="btn d-flex justify-content-center align-items-center btn-outline-danger rounded-pill p-0 position-absolute top-0 end-0 mt-1 me-1 wh-28 bg-white" title="Remove" onClick={() => removeImage(item.works_image)}><span className="tscale-0p8 btn-close"></span></button>
                                        </div>
                                        </>
                                    )
                                }) : 'Loading....'} 
                            </div>
                            <form className="col-12 needs-validation" id="recentWorkForm" noValidate>
                                <div className="col-sm-6 my-2">
                                    <label htmlFor="profile_photo" className="btn btn-primary text-nowrap fw-medium rounded-pill mt-2 px-4">
                                        <svg className="icon tscale-1p4 me-3" role="img" aria-label="more">
                                            <use href="#icon_upload" />
                                        </svg>
                                        Upload Recent Works
                                    </label>
                                    <input type="file" accept="image/*" className="d-none" name="works_image[]" title="Upload Image" multiple  onChange={async (e) => imageSet(e)} id="profile_photo" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default RecentWork