import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { toastNotify, validateForm, loadingData } from "../../../components/Helper";
import { fetchData, INFORMATION_SECTION } from "../../../components/Service";

function GeneralSection() {
    window.document.title = "Information Section";

    const [data, setData] = useState('');
    const [langType, setLangType] = useState('en');
    const [tinyloader, setTinyloader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        setLang(langType)
        loadingData(true)
        fetchData(INFORMATION_SECTION +`?lang_type=${langType}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            if (res.status) {
                setData(res.records)

                const parser = new DOMParser();
                const doc = parser.parseFromString(res.records.site_footnote, 'text/html');
                const contentBlock = htmlToDraft(doc.body.innerText);
                const { contentBlocks, entityMap } = contentBlock;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorStatez = EditorState.createWithContent(contentState);
                setEditorState(editorStatez)
            }
        }, (err) => { })
    }, [langType]);

    const handleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const setLang = (lang) => {
        document.getElementById('updateInformationData').reset()
        setLangType(lang)
    }
    const updateInformationData = async (e) => {
        let formData = new FormData(document.getElementById('updateInformationData'));
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(INFORMATION_SECTION +`?lang_type=${langType}`, 'POST', formData, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Information Section</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateInformationData" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                                {data ?
                                    <div className="bg-white p-3 rounded-10" role="tabpanel">
                                        <div className="row mb-3">
                                            <div className="offset-md-3 col-md-7">
                                                <h6 className="text-blue">General *</h6>
                                                <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Email:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="email" className="form-control" name="site_email" id="site_email" defaultValue={data.site_email} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Working Hour:<strong className="text-danger">*</strong><strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="site_clock" id="site_clock" defaultValue={data.site_clock} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Phone Number:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="tel" className="form-control" name="site_phone" id="site_phone" defaultValue={data.site_phone} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Location:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="site_location" id="site_location" defaultValue={data.site_location} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Gmap Location URL:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="site_map" id="site_map" defaultValue={data.site_map} onChange={handleInputChange} required />
                                            </div>
                                        </div>

                                        {/* ============================================================================ */}
                                        <div className="row py-3">
                                            <div className="offset-md-3 col-md-7">
                                                <h6 className="text-blue">Social Media Link *</h6>
                                                <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Facebook:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="url_facebook" id="url_facebook" defaultValue={data.url_facebook} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Instagram:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="url_instagram" id="url_instagram" defaultValue={data.url_instagram} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-md-3 text-md-end col-form-label">Twitter:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="url_twitter" id="url_twitter" defaultValue={data.url_twitter} onChange={handleInputChange} required />

                                            </div>
                                        </div>

                                        <div className="row py-3">
                                            <div className="offset-md-3 col-md-7">
                                                <h6 className="text-blue">Footer <strong className="text-danger">*</strong></h6>
                                                <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="offset-md-3 col-md-7">
                                                <textarea className="form-control d-none" name="site_footnote" id='site_footnote' rows={20} value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}></textarea>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => onEditorStateChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3 py-3">
                                            <div className="offset-md-3 col-md-7">
                                                <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateInformationData(e)} disabled={tinyloader}>
                                                    {
                                                        !tinyloader ? 'Submit'
                                                            :
                                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>
    )
}
export default GeneralSection;
