import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, tableSpinner, ConfirmationModal, loadingData } from "../../components/Helper";
import { fetchData, TENDER_LIST, CONFIRM_TENDER_AND_REVIEW } from "../../components/Service";

function Category() {
    window.document.title = "Tender Confirmation";
    const [updatetable, setUpdatetable] = useState(true);
    const [formfield, setFormfield] = useState('')

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: TENDER_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        list_type:'not_confirm',
       pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'orderlistTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'orderlistTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const confirmTender = (id,type) => {
        setUpdatetable(true);
        fetchData(CONFIRM_TENDER_AND_REVIEW +`?${type}&tender_id=${id}`, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
            if (res.status) {
                localStorage.setItem('isnotify', 'true'); /*Update Notifications*/
                setUpdatetable(false);
                toastNotify('success', res.message)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Tender Confirmation</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto"></div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="orderlistTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th width="160">Tender Date</th>
                                                <th data-orderby="asc" data-sortby="tender_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Code
                                                </th>
                                                <th data-orderby="asc" data-sortby="tender_title" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Title
                                                </th>
                                                <th data-orderby="asc" data-sortby="tender_price" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Price
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="user_name" onClick={(e) => dtActions(e, 'sortby')}>User</th>
                                                <th data-orderby="asc" data-sortby="is_active" onClick={(e) => dtActions(e, 'sortby')}>Company</th>                              
                                                <th width="90">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.tender_date).toDateString()}</td>
                                                    <td>{ele.tender_code}</td>
                                                    <td>{ele.tender_title}</td>
                                                    <td>{ele.tender_price}</td>
                                                    <td>{ele.user_name}</td>
                                                    <td>{ele.company_name}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm btn-success px-3 fs-6" data-bs-toggle="modal" data-bs-target="#confModal" title="Action" 
                                                            onClick={(e) => setFormfield(prevState => ({
                                                                ...prevState, tender_id: ele.tender_id
                                                            }))}>
                                                            Confirm
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal msg="Please Confirm Tender" method={() => confirmTender(formfield.tender_id,"tender_confirm")} />
        </>
    )
}

export default Category