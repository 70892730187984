import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { scrollbarSetToTop } from '../components/Helper';

function AsideBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [context, setContext] = useState('');
    useEffect(() => {
        document.getElementById('tw_pagetitle').innerHTML = window.document.title
        scrollbarSetToTop();
        document.body.classList.remove('menu-active');
        setContext(JSON.parse(localStorage.getItem("tw-records")));
        if (!localStorage.getItem("tw-records") || localStorage.getItem("tw-records") === null) {
            navigate('/login')
            setContext('')
        }
    }, [location.pathname, navigate])
    return (
        <>
            <aside className="admin-aside">
                <div className="admin-logobox">
                    <Link to="/" className="admin-brandbox" title="Tawreed">
                        <img src={'images/tawreed-logo.svg'} alt="Tawreed-Logo" width="220" height="55.64" />
                    </Link>
                </div>
                <div className="admin-navbox" id="navtoggle">
                    <div className="accordion-item">
                        <NavLink to="dashboard" className="main-link" title="Dashboard">
                            <svg className="icon" role="img">
                                <use href="#icon_dashboard" />
                            </svg>
                            Dashboard
                        </NavLink>
                    </div>
                    {context && context.userType && context.userType === 'admin' ?
                        <div className="accordion-item">
                            <NavLink to="notification/list" className="main-link" title="Send Notification">
                                <svg className="icon tscale-1p1" role="img">
                                    <use href="#icon_bell" />
                                </svg>
                                Send Notification
                            </NavLink>
                        </div>
                        : ''}
                    {context && context.userType && context.userType === 'admin' ?
                        <div className="accordion-item">
                            <h6>Company & User</h6>
                            <div className="main-link collapsed" title="Company" data-bs-toggle="collapse" role="button" data-bs-target="#company_more" aria-expanded={location.pathname.match('/company/*') ? true : false} data-currentpage={location.pathname.match('/company/*') ? true : false}>
                                <svg className="icon" role="img"><use href="#icon_company" /></svg>
                                Company
                            </div>
                            <div id="company_more" className={`accordion-collapse collapse ${location.pathname.match('/company/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="company/verification" className="sub-link" title="Verification">Verification</NavLink>
                                <NavLink to="company/data" className="sub-link" title="Data">Data</NavLink>
                            </div>

                            <div className="main-link collapsed" role="button" title="Subscription" data-bs-toggle="collapse" data-bs-target="#subscription_more" aria-expanded={location.pathname.match('/subscription/*') ? true : false} data-currentpage={location.pathname.match('/subscription/*') ? true : false}>
                                <svg className="icon" role="img" aria-label="more"><use href="#icon_loyalty" /></svg>
                                Subscription
                            </div>
                            <div id="subscription_more" className={`accordion-collapse collapse ${location.pathname.match('/subscription/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="subscription/data" className="sub-link" title="Subscription">Subscription</NavLink>
                                <NavLink to="subscription/transaction" className="sub-link" title="Transaction">Transaction</NavLink>
                            </div>
                            <NavLink to="user/list" className="main-link" title="User">
                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                    <use href="#icon_user" />
                                </svg>
                                User
                            </NavLink>
                        </div>
                        : ''}
                    {context && context.userType && context.userType === 'admin' ?
                        <div className="accordion-item">
                            <h6>Advertisement</h6>
                            <div className="main-link collapsed" data-bs-toggle="collapse" title="Ads" role="button" data-bs-target="#ads_more" aria-expanded={location.pathname.match('/ads/*') ? true : false} data-currentpage={location.pathname.match('/ads/*') ? true : false}>
                                <svg className="icon" role="img"><use href="#icon_ads" /></svg>
                                Ads
                            </div>
                            <div id="ads_more" className={`accordion-collapse collapse ${location.pathname.match('/ads/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="ads/contacted-ads" className="sub-link" title="Contacted Ads">Contacted Ads</NavLink>
                                <NavLink to="ads/ads" className="sub-link" title="Ads">Ads</NavLink>
                            </div>
                        </div>
                        : ''}
                    {context ?
                        <div className="accordion-item">
                            <h6>Product {context.userType && context.userType === 'admin' ? ' & Category' : ''}</h6>
                            <NavLink to="product/list" className="main-link" title="Product">
                                <svg className="icon icon-h19" role="img" aria-label="more">
                                    <use href="#icon_cube" />
                                </svg>
                                Product
                            </NavLink>
                            {context.userType && context.userType === 'admin' ? <>
                                <div className="main-link collapsed" title="Category" data-bs-toggle="collapse" role="button" data-bs-target="#category_more" aria-expanded={location.pathname.match('/category/*') ? true : false} data-currentpage={location.pathname.match('/category/*') ? true : false}>
                                    <svg className="icon icon-h18" role="img"><use href="#icon_category" /></svg>
                                    Category
                                </div>
                                <div id="category_more" className={`accordion-collapse collapse ${location.pathname.match('/category/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="category/properties" className="sub-link" title="Properties">Properties</NavLink>
                                    <NavLink to="category/category" className="sub-link" title="Category">Category</NavLink>
                                    <NavLink to="category/sub-category" className="sub-link" title="Sub-Category">Sub-Category</NavLink>
                                </div>
                            </> : ''}
                        </div>
                        : ''}
                    {context ?
                        <div className="accordion-item">
                            <h6>Order</h6>
                            <NavLink to="order/list" className="main-link" title="Order" role="button">
                                <svg className="icon icon-h18" role="img"><use href="#icon_archive" /></svg>
                                Order
                            </NavLink>
                            {context.userType && context.userType === 'admin' ? <>
                                <div className="main-link collapsed" data-bs-toggle="collapse" title="Order Review" role="button" data-bs-target="#order_review_more" aria-expanded={location.pathname.includes('/order-review/') ? true : false} data-currentpage={location.pathname.includes('/order-review/') ? true : false}>
                                    <svg className="icon tscale-1p1" role="img"><use href="#icon_star" /></svg>
                                    Order Review
                                </div>
                                <div id="order_review_more" className={`accordion-collapse collapse ${location.pathname.includes('/order-review/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="order-review/list" className="sub-link" title="Data">Data</NavLink>
                                    <NavLink to="order-review/confirmation" className="sub-link" title="Confirmation">Confirmation</NavLink>
                                </div>
                            </> : ''}
                        </div>
                        : ''}
                    {context ?
                        <div className="accordion-item">
                            <h6>Tender</h6>
                            {context.userType && context.userType === 'admin' ? <>
                            <div className="main-link collapsed" title="Tender" data-bs-toggle="collapse" role="button" data-bs-target="#tender_more" aria-expanded={location.pathname.includes('/tender/') ? true : false} data-currentpage={location.pathname.includes('/tender/') ? true : false}>
                                <svg className="icon icon-h18" role="img"><use href="#icon_layers" /></svg>
                                Tender
                            </div>
                            <div id="tender_more" className={`accordion-collapse collapse ${location.pathname.includes('/tender/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="tender/confirmation" className="sub-link" title="Confirmation">Confirmation</NavLink>
                                <NavLink to="tender/list" className="sub-link" title="Data">Data</NavLink>
                            </div>
                            <div className="main-link collapsed" title="Tender Review" data-bs-toggle="collapse" role="button" data-bs-target="#tender_review_more" aria-expanded={location.pathname.includes('/tender-review/') ? true : false} data-currentpage={location.pathname.includes('/tender-review/') ? true : false}>
                                <svg className="icon tscale-1p1" role="img"><use href="#icon_star" /></svg>
                                Tender Review
                            </div>
                            <div id="tender_review_more" className={`accordion-collapse collapse ${location.pathname.includes('/tender-review/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="tender-review/confirmation" className="sub-link" title="Confirmation">Confirmation</NavLink>
                                <NavLink to="tender-review/list" className="sub-link" title="Data">Data</NavLink>
                            </div>
                            </> : 
                            <NavLink to="tender/list" title="Tender" className="main-link" role="button">
                                <svg className="icon icon-h18" role="img"><use href="#icon_layers" /></svg>
                                Tender
                            </NavLink> }
                        </div>
                        : ''}
                    {context ?
                        <div className="accordion-item">
                            {context.userType && context.userType === 'admin' ? <>
                                <h6>Payment</h6>
                                <div className="main-link collapsed" title="Payment" data-bs-toggle="collapse" role="button" data-bs-target="#payment_more" aria-expanded={location.pathname.match('/payment/*') ? true : false} data-currentpage={location.pathname.match('/payment/*') ? true : false}>
                                    <svg className="icon" role="img"><use href="#icon_payment" /></svg>
                                    Payment
                                </div>
                                <div id="payment_more" className={`accordion-collapse collapse ${location.pathname.match('/payment/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    <NavLink to="payment/data" className="sub-link" title="Payment">Payment</NavLink>
                                    <NavLink to="payment/wallet" className="sub-link" title="Wallet">Wallet</NavLink>
                                </div>
                            </> : ''}
                            <div className="main-link collapsed" title="Withdraw" data-bs-toggle="collapse" role="button" data-bs-target="#withdraw_more" aria-expanded={location.pathname.match('/withdraw/*') ? true : false} data-currentpage={location.pathname.match('/withdraw/*') ? true : false}>
                                <svg className="icon" role="img"><use href="#icon_dollar" /></svg>
                                Withdraw
                            </div>
                            <div id="withdraw_more" className={`accordion-collapse collapse ${location.pathname.match('/withdraw/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                {context && context.userType && context.userType === 'admin' ?
                                <NavLink to="withdraw/confirmation" className="sub-link" title="Confirmation">Confirmation</NavLink>
                                : <NavLink to="withdraw/request" className="sub-link" title="Request">Request</NavLink> }
                                <NavLink to="withdraw/history" className="sub-link" title="History">History</NavLink>
                            </div>
                        </div>
                        : ''}

                        {context && context.userType && context.userType === 'seller' ?
                        <div className="accordion-item">
                            <h6>SUBSCRIPTION</h6>
                            <NavLink to="transaction" title="Transaction" className="main-link" role="button">
                                <svg className="icon icon-h18" role="img"><use href="#icon_layers" /></svg>
                                Transaction
                            </NavLink>
                        </div>
                        : ''}

                    {context && context.userType && context.userType === 'admin' ? <>
                        <div className="accordion-item">
                            <h6>CMS</h6>
                            <div className="main-link collapsed" title="General" data-bs-toggle="collapse" role="button" data-bs-target="#general_more" aria-expanded={location.pathname.match('/general/*') ? true : false} data-currentpage={location.pathname.match('/general/*') ? true : false}>
                                <svg className="icon" role="img"><use href="#icon_general" /></svg>
                                General
                            </div>
                            <div id="general_more" className={`accordion-collapse collapse ${location.pathname.match('/general/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="cms/general/general" className="sub-link" title="General Section">General Section</NavLink>
                                <NavLink to="cms/general/information" className="sub-link" title="Information Section">Information Section</NavLink>
                            </div>

                            <div className="main-link collapsed" data-bs-toggle="collapse" title="Home Page" role="button" data-bs-target="#homepage_more" aria-expanded={location.pathname.match('/home/*') ? true : false} data-currentpage={location.pathname.match('/home/*') ? true : false}>
                                <svg className="icon  tscale-1p1" role="img"><use href="#icon_home" /></svg>
                                Home Page
                            </div>
                            <div id="homepage_more" className={`accordion-collapse collapse ${location.pathname.match('/home/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="cms/home/slider-section" className="sub-link" title="Slider Home">Slider Home</NavLink>
                                <NavLink to="cms/home/download-section" className="sub-link" title="Download Section">Download Section</NavLink>
                                <NavLink to="cms/home/feature" className="sub-link" title="Feature Section">Feature Section</NavLink>
                                <NavLink to="cms/home/testimonial" className="sub-link" title="Testimonial Section">Testimonial Section</NavLink>
                                <NavLink to="cms/home/instagram" className="sub-link" title="Instagram Section">Instagram Section</NavLink>
                            </div>

                            <div className="main-link collapsed" title="Menu Page" role="button" data-bs-toggle="collapse" data-bs-target="#menupage_more" aria-expanded={location.pathname.match('/menu/*') ? true : false} data-currentpage={location.pathname.match('/menu/*') ? true : false}>
                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                    <use href="#icon_menu" />
                                </svg>
                                Menu Page
                            </div>
                            <div id="menupage_more" className={`accordion-collapse collapse ${location.pathname.match('/menu/*') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="cms/menu/about-us" className="sub-link" title="About Us">About Us</NavLink>
                                <NavLink to="cms/menu/privacy-policy" className="sub-link" title="Privacy Policy">Privacy Policy</NavLink>
                                <NavLink to="cms/menu/terms-conditions" className="sub-link" title="Terms Conditions">Terms Conditions</NavLink>
                                <NavLink to="cms/menu/contact-us" className="sub-link" title="Contact Us">Contact Us</NavLink>
                                <NavLink to="cms/menu/advertise" className="sub-link" title="Advertise Page">Advertise Page</NavLink>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h6>Master Config</h6>
                            <NavLink to="staff/list" title="Staff" className="main-link">
                                <svg className="icon tscale-1p1" role="img" aria-label="more">
                                    <use href="#icon_user" />
                                </svg>
                                Staff
                            </NavLink>
                            <NavLink to="language-string" title="Language String" className="main-link">
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_globe" />
                                </svg>
                                Language String
                            </NavLink>
                        </div>
                    </> : ''}
                </div>
                <div className="admin-copyrightsbox">&copy; 2021 - Tawreed - All Rights Reserved.</div>
            </aside>
        </>
    )
}
export default AsideBar;