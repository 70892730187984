import React, { useEffect, useState } from "react";
import { fetchFrontData, PRIVACY } from "../../components/Service";
import TopHeader from "../../components/TopHeader";
import { scrollbarSetToTop } from "../../components/Helper";

function Privacy() {

    const[langType, setLangType] = useState(localStorage.getItem('lang_type'))
    const[data, setData] = useState('')

    useEffect(() => {
        scrollbarSetToTop()
        fetchFrontData(PRIVACY, 'GET', '', (res) => {
            if(res.status){
                setData(res.records.words)
            }

        }, (err) => {})

    }, [langType])


    return(
        <>
            <TopHeader refresh={setLangType} reload={true} />
            <section className={`container py-5 ${langType === 'ur' || langType === 'ar' ? ' direction-rtl' : ''}`}>
                <div dangerouslySetInnerHTML={{__html: data}}></div>
            </section>
        </>
    )   
}
export default Privacy