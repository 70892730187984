import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, ConfirmationModal, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, ADS_CONTACTED_LIST, UPDATE_CONTACTED_ADS, DELETE_CONTACT_ADS } from "../../components/Service";

function ContactedAds() {
    window.document.title = "Contacted Ads";
    const [updatetable, setUpdatetable] = useState(true)
    const [tinyloader, setTinyloader] = useState(false);
    const [formfield, setFormfield] = useState({
        id: "",
        name: "",
        email: "",
        message: ""
    })

    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: ADS_CONTACTED_LIST,
        search: '',
        perpage: '10',
        columns: 'id,name,email,description',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'adscontactedTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'adscontactedTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("contactedAds").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/ 


    const updateContactedForm = async (e) => {
        setTinyloader(true);
        setUpdatetable(true);
        let formdata = new FormData(document.getElementById('contactedAds'));
        if (validateForm(e)) {
            fetchData(UPDATE_CONTACTED_ADS+'/'+formfield.id, 'POST', formdata, true, true, (res) => {
                if (res.status) {
                    toastNotify('success', res.message);
                    document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                    setUpdatetable(false);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
                setTinyloader(false);
            }, (err) => { });
        }
    }

    const deleteRecords = (id) => {
        setUpdatetable(true);
        fetchData(DELETE_CONTACT_ADS + '/' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Contacted Ads</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="adscontactedTable">
                                <div className="table-responsive">
                                    <table id="mytable" className="table-common">
                                        <thead>
                                            <tr>
                                                <th className="minw-200" data-orderby="asc" data-sortby="name" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="email" onClick={(e) => dtActions(e, 'sortby')}>Email</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="description" onClick={(e) => dtActions(e, 'sortby')}>Message</th>
                                                <th scope="col" data-orderable="false" width="130">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.name}</td>
                                                    <td>{ele.email}</td>
                                                    <td>{ele.description}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" data-bs-toggle="offcanvas" data-bs-target="#contactedAds" onClick={() => setFormfield(ele)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={() => setFormfield(prevState => ({
                                                            ...prevState, id: ele.id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={4}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="contactedAds" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">Update Ads Contact</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <input type="text" className="d-none" name="id" defaultValue={formfield.id} />
                    <label className="w-100 mb-3">
                        <div className="form-label text-blue small fw-medium mb-1">Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="name" placeholder="Enter name" spellCheck="false" defaultValue={formfield.name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, name: e.target.value
                        }))} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Email<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="email" placeholder="Enter email" defaultValue={formfield.email} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, email: e.target.value
                        }))} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Message<strong className="text-danger">*</strong></div>
                        <textarea className="form-control" rows="5" name="message" placeholder="Enter your message..." defaultValue={formfield.description} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, message: e.target.value
                        }))} required></textarea>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e)=>updateContactedForm(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.id)} />
        </>
    )
}

export default ContactedAds
