import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toastNotify, validateForm} from '../../components/Helper';
import { fetchData, RESET_PASSWORD } from '../../components/Service';
import queryString from 'query-string';

function ResetPassword(){
    const location = useLocation()
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);

    const loginSubmission = (e) => {
        let query = queryString.parse(location.search)
        let requiredField = new FormData(document.getElementById('resetForm'));
        requiredField.append('token', query.token)
        requiredField.append('userType', query.userType)
        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(RESET_PASSWORD, 'POST', requiredField, false, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    navigate('/login')
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        };
    }
    return(
        <>
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                    <form className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="resetForm" noValidate>
                        <div className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm">
                            <div className="text-center">
                                <img src="images/tawreed-logo.svg" alt="Tawreed - Building Connections" className="w-100 maxw-280" width="280" height="70.81" />
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <h4 className="text-center my-3">Reset Password</h4>
                            
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type="password" className="form-control" id="password" name="password" placeholder="New Password" required />
                            </label>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type="password" className="form-control" id = "password_confirmation" name="password_confirmation" placeholder="New Confirm Password" required />
                            </label>
                            <button type="button" className="btn btn-primary btn-lg w-100 rounded-pill mb-3" onClick={loginSubmission} disabled={tinyloader}>
                                {
                                    !tinyloader ? 'Submit'
                                    :
                                    <div className="spinner-border spinner-border-sm ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </button>
                            
                            <div className="d-flex justify-content-between">
                                <div className="my-2">
                                    Go to <Link to="/login" className="fw-medium" title="Login">Login</Link>.
                                </div>
                                {/* <Link to="/forgot-password" className="my-2 fw-medium d-block" title="Reset Password">Forgot Password?</Link> */}
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default ResetPassword