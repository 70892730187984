import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, ConfirmationModal, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, ADS_LIST, ASSET_ENDPOINT, CREATE_ADS, UPDATE_ADS, DELETE_ADS } from "../../components/Service";

function Ads() {
    window.document.title = "Ads";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Ads")
    const [formfield, setFormfield] = useState({
        ads_id: "",
        ads_image: "/",
        ads_imageb64: false,
        ads_url: "",
        description: "",
        priority: "0",
        show_user: 1,
        show_company: 1
    })

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: ADS_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc'
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'adsTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'adsTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("addNewAds").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            document.querySelector(`#addNewAds [name="ads_image"]`).setAttribute('required', 'required');
            document.querySelector(`#addNewAds [alt="ads_image"]`).src="/"
            setOffcanvastitle("Add New Ads");
            setFormfield({ // Set empty state
                ads_id: "",
                ads_image: "/",
                ads_imageb64: false,
                ads_url: "",
                description: "",
                priority: "0",
                show_user: 1,
                show_company: 1
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const adsFunction = async (e) => {
        let formdata = new FormData(document.getElementById('addNewAds'));
        formdata.append('show_user', Number(formfield.show_user));
        formdata.append('show_company', Number( formfield.show_company));
        let adsid = document.querySelector('#addNewAds [name="ads_id"]').value;
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (adsid === null || adsid===''){
                fetchData(CREATE_ADS, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }else{
                fetchData(UPDATE_ADS+'/'+adsid, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                    setTinyloader(false);
                }, (err) => { });
            }
        }
    }

    const editForm = (rec) => {
        setOffcanvastitle("Update Ads");
        document.querySelector(`#addNewAds [name="ads_image"]`).removeAttribute('required');
        setFormfield(rec)    
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            ads_imageb64: imgb64
        }))
    }

    const deleteRecords = (id) =>{
        setUpdatetable(true);
        fetchData(DELETE_ADS+'/'+id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Ads</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            <li>
                                                <button type="button" title="5" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(5, 'perpage')}>5</button>
                                            </li>
                                            <li>
                                                <button type="button" title="10" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(10, 'perpage')}>10</button>
                                            </li>
                                            <li>
                                                <button type="button" title="15" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(15, 'perpage')}>15</button>
                                            </li>
                                            <li>
                                                <button type="button" title="20" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(20, 'perpage')}>20</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addNewAds">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="adsTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th width="80">Image</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="ads_url" onClick={(e) => dtActions(e, 'sortby')}>URL</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="description" onClick={(e) => dtActions(e, 'sortby')}>Description</th>
                                                <th data-orderby="asc" data-sortby="priority" width="130" onClick={(e) => dtActions(e, 'sortby')}>Priority</th>
                                                <th data-orderby="asc" data-sortby="show_user" width="130" onClick={(e) => dtActions(e, 'sortby')}>User</th>
                                                <th data-orderby="asc" data-sortby="show_company" width="130" onClick={(e) => dtActions(e, 'sortby')}>Company</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>
                                                        <img src={ASSET_ENDPOINT + ele.ads_image} alt={"adsimage"} className="mmwh-40 rounded-pill shadow-sm objfit-cover bg-light" onError={noimageHandle} />
                                                    </td>
                                                    <td>{ele.ads_url}</td>
                                                    <td>{ele.description}</td>
                                                    <td>{ele.priority}</td>
                                                    <td>{ele.show_user === 1 ? 'Yes' : 'No'}</td>
                                                    <td>{ele.show_company === 1 ? 'Yes' : 'No'}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={()=>editForm(ele)} data-bs-toggle="offcanvas" data-bs-target="#addNewAds">
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" data-bs-toggle="modal" data-bs-target="#confModal" title="Delete" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, ads_id: ele.ads_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addNewAds" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <input type="text" className="d-none" name="ads_id" defaultValue={formfield.ads_id}/>
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.ads_imageb64 ? ASSET_ENDPOINT + '/' + formfield.ads_image : formfield.ads_imageb64} alt="ads_image" className="me-3" onError={noimageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap">Upload Image</button>
                        <input type="file" accept="image/*" className="form-field" name="ads_image" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState, 
                            ads_image: imageSet(e),
                        }))} required/>
                        <div className="invalid-feedback">
                            Required to upload image.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Ads URL<strong className="text-danger">*</strong></div>
                        <input type="url" className="form-control form-field" name="ads_url" placeholder="" defaultValue={formfield.ads_url} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, ads_url: e.target.value
                        }))} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Description<strong className="text-danger">*</strong></div>
                        <textarea className="form-control form-field" name="description" spellCheck={false} rows={3} defaultValue={formfield.description} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, description: e.target.value
                        }))} required></textarea>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-0 fw-medium text-blue small">Priority ({formfield.priority})</div>
                        <input type="range" className="form-field w-100" min="0" max="100" name="priority" value={formfield.priority} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, priority: e.target.value
                        }))}/>
                    </label>
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Show <strong className="text-danger">*</strong></div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="show_user" type="checkbox" defaultValue={formfield.show_user} onClick={(e) => setFormfield(prevState => ({
                                ...prevState, show_user: e.target.checked ? 1 : 0
                            }))} checked={formfield.show_user === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">User</div>
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="show_company" type="checkbox" defaultValue={formfield.show_company} onClick={(e) => setFormfield(prevState => ({
                                ...prevState, show_company: e.target.checked ? 1 : 0
                            }))} checked={formfield.show_company === 1 ? true : false } />
                            <div className="align-middle d-inline-block lh-1">Company</div>                            
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => adsFunction(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
            
            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.ads_id)} />
        </>
    )
}

export default Ads