import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Datatable, dtControllers } from "../../components/Datatable";
import DateRangeFilter from '../../components/Filter';
import "react-datepicker/dist/react-datepicker.css";
import { fetchData, PAYMENT_LIST, TRANSACTION_LIST_EXPORT } from "../../components/Service";
import { loadingData } from "../../components/Helper";

function Payment() {
    window.document.title = "Payment Data";
    const [records, setRecords] = useState("");
    const location = useLocation();
    const [tablespinner, setTablespinner] = useState(true)
    const [dateRange, setDateRange] = useState(['', ''])
    const [startDate, endDate] = dateRange

    const [dt, setDt] = useState({
        fetchurl: PAYMENT_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        setTablespinner(true)
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            setTablespinner(false)
        });
    }, [dt])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }

    const getDateRange = (startDate, endDate) => {
        setDateRange([startDate, endDate])
    }

    const exportTransaction = () => {
        loadingData(true)
        fetchData(`${TRANSACTION_LIST_EXPORT}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, false, (res) => {
            loadingData(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report Transactions.xlsx`);
            document.body.appendChild(link);
            link.click();
        }, (err) => {}, "blob");
    }
    

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Payment Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row g-2 align-items-start">
                                <div className="col col-md-auto">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {location.state && 
                                <div className="col-md-auto ms-auto">
                                    <div className="input-group input-group-pill flex-nowrap">
                                        <div className="input-group-text">
                                            <svg className="icon tscale-1p3" role="img"><use href="#icon_calendar" /></svg>
                                        </div>
                                        <DateRangeFilter 
                                            daterange=""
                                            className="form-control"
                                            maxDate={new Date()}
                                            opens="left"
                                            name='d_daterange'
                                            placeholder="Select Date Range"
                                            disableUpdateInput={false}
                                            getDateRange={getDateRange}
                                        />
                                        <button type="button" className="btn btn-primary px-3 text-nowrap" onClick={exportTransaction}>
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_download" /></svg>
                                            <span className="d-none d-md-inline"> Export</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="table-spinner" data-tablespinner={tablespinner}>
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th className="minw-200 text-nowrap" data-orderby="asc" data-sortby="created_at" onClick={(e) => dtActions(e, 'sortby')}>Payment Date</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="payment_amount" onClick={(e) => dtActions(e, 'sortby')}>Amount (QAR)</th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="payment_method" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Method
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="payment_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Payment Code
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="payment_status" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Status
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="payment_on" onClick={(e) => dtActions(e, 'sortby')} width="220">
                                                    Payment On
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.created_at).toDateString()}</td>
                                                    <td>{ele.payment_amount}</td>
                                                    <td>
                                                        {
                                                            (ele.payment_method).split('__')[1]==="cod"? "Cash on Delivery":"Credit Card"
                                                        }
                                                    </td>
                                                    <td>
                                                        {ele.payment_code}
                                                    </td>                                                    
                                                    <td>
                                                        {
                                                            (ele.payment_status).split('__')[1] === "pending" ? <span className="badge text-dark bg-warning rounded-pill fw-medium minw-70">Pending</span> : (ele.payment_status).split('__')[1] === "failed" ? <span className="badge bg-danger rounded-pill fw-medium minw-70">Failed</span> : <span className="badge bg-success rounded-pill fw-medium minw-70">Success</span>
                                                        }
                                                    </td>
                                                    <td className="text-nowrap text-capitalize">
                                                        {(ele.payment_on).split('__')[1]}
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Payment