import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, ConfirmationModal, loadingData, tableSpinner } from "../../components/Helper";
import { fetchData, CATEGORY_LIST, PROPERTY_LIST_ONLY, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_UPDATE,   ASSET_ENDPOINT } from "../../components/Service";
import ComboSelect from "../../components/ComboSelect";

function Category() {
    window.document.title = "Category";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Category")
    const [formfield, setFormfield] = useState({
        category_id: "",
        category_image: "/",
        category_image_icon: "/",
        category_code: "",
        english_name: "",
        arabic_name: "",
        urdu_name: "",
        category_props: "",
        title: "",
        in_product: 1,
        in_tender: 1,
        is_active: 1,
        category_list: "",
        category_selected: "",
        category_imageb64: "",
        category_iconb64: ""
    })

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: CATEGORY_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc'
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'categoryTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'categoryTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });
    
        document.getElementById("addupdateCategory").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Category");
            setFormfield(prevState => ({ /*Set empty state*/
                ...prevState,
                category_id: "",
                category_image: "/",
                category_image_icon: "/",
                category_code: "category_",
                english_name: "",
                arabic_name: "",
                urdu_name: "",
                category_props: "",
                title: "",
                in_product: 1,
                in_tender: 1,
                is_active: 1,
                category_selected: "",
                category_imageb64: "",
                category_iconb64: ""
            }))
        });

        if (formfield.category_list===''){
            categoryPropsList();
        }

    }, [dt, updatetable, formfield.category_list])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const categoryPropsList = () => {
        fetchData(PROPERTY_LIST_ONLY, 'GET', '', true, true, (res) => {
            if (res.status) {
                setFormfield(prevState => ({
                    ...prevState,
                    category_list: res.records
                }))
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    const adsFunction = async (e) => {
        let formdata = new FormData(document.getElementById('addupdateCategory'));
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.category_id === null || formfield.category_id===''){
                fetchData(CATEGORY_CREATE, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#addupdateCategory [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }else{
                fetchData(CATEGORY_UPDATE + '/' + formfield.category_id, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }
        }
    }

    const editForm = async (getid) => {
        loadingData(true);

        fetchData(CATEGORY_UPDATE + '/' + getid, 'GET', '', true, false, (res) => {
            loadingData(false);
            if (res.status) {
                let langSetEng = [];
                let langSetArb = [];
                let langSetUrd = [];
                if (res.records.category_name_language.length > 0) {
                    res.records.category_name_language.map((a) => {
                        if (a.code === 'en') {
                            langSetEng.push(a.words);
                        }
                        if (a.code === 'ar') {
                            langSetArb.push(a.words);
                        }
                        if (a.code === 'ur') {
                            langSetUrd.push(a.words);
                        }
                    })
                }

                setFormfield(prevState => ({
                    ...prevState,
                    category_id: res.records.category_id.toString(),
                    category_image: res.records.category_image,
                    category_image_icon: res.records.category_image_icon,
                    category_code: res.records.category_code,
                    english_name: langSetEng,
                    arabic_name: langSetArb,
                    urdu_name: langSetUrd,
                    category_selected: res.records.category_props.toString(),
                    in_product: Number(res.records.in_product),
                    in_tender: Number(res.records.in_tender),
                    is_active: Number(res.records.is_active)
                }));

                setOffcanvastitle("Update Category");
                new Offcanvas(document.getElementById('addupdateCategory')).show();
            }
            else{
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.message)
                }
            }
        }, (err) => { });
    }

    const imageSet = async (e, type) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        if(type==='big'){
            setFormfield(prevState => ({
                ...prevState,
                category_imageb64: imgb64
            }))
        }else{
            setFormfield(prevState => ({
                ...prevState,
                category_iconb64: imgb64
            }))   
        }
    }

    const deleteRecords = (id) =>{
        setUpdatetable(true);
        fetchData(CATEGORY_DELETE+'/'+id, 'GET', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
                setFormfield(prevState => ({
                    ...prevState,
                    category_id: ""
                }))
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Category</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            <li>
                                                <button type="button" title="5" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(5, 'perpage')}>5</button>
                                            </li>
                                            <li>
                                                <button type="button" title="10" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(10, 'perpage')}>10</button>
                                            </li>
                                            <li>
                                                <button type="button" title="15" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(15, 'perpage')}>15</button>
                                            </li>
                                            <li>
                                                <button type="button" title="20" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(20, 'perpage')}>20</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addupdateCategory">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="categoryTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th width="80">Image</th>
                                                <th data-orderby="asc" data-sortby="category_code" onClick={(e) => dtActions(e, 'sortby')}>Code</th>
                                                <th data-orderby="asc" data-sortby="title" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="category_props" onClick={(e) => dtActions(e, 'sortby')}>Category Props</th>
                                                <th data-orderby="asc" data-sortby="is_active" width="120" onClick={(e) => dtActions(e, 'sortby')}>Active?</th>
                                                <th data-orderby="asc" data-sortby="in_product" width="120" onClick={(e) => dtActions(e, 'sortby')}>Product?</th>
                                                <th data-orderby="asc" data-sortby="in_tender" width="120" onClick={(e) => dtActions(e, 'sortby')}>Tender?</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>
                                                        <img src={ASSET_ENDPOINT + ele.category_image} alt={"adsimage"} className="mmwh-40 rounded-pill shadow-sm objfit-cover bg-light" onError={noimageHandle} />
                                                    </td>
                                                    <td>{ele.category_code}</td>
                                                    <td>{ele.title}</td>
                                                    <td>
                                                        {ele.category_props !== '' && ele.category_props !== null ? (ele.category_props).join(', ') : null}
                                                    </td>
                                                    <td>{ele.is_active === 1 ? 'Active' : 'Disabled'}</td>
                                                    <td>{ele.in_product === 1 ? 'Yes' : 'No'}</td>
                                                    <td>{ele.in_tender === 1 ? 'Yes' : 'No'}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={()=>editForm(ele.category_id)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" data-bs-toggle="modal" data-bs-target="#confModal" title="Delete" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, category_id: ele.category_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addupdateCategory" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <div className="d-flex mb-3">
                        <div className="w-50 text-center">
                            <label htmlFor="bigimage" className="image-uploading d-inline-block">
                                <img src={!formfield.category_imageb64 ? ASSET_ENDPOINT + '/' + formfield.category_image : formfield.category_imageb64} alt="Big Size" onError={noimageHandle} />
                            </label>
                            <input type="file" accept="image/*" className="d-none" name="category_image" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                                ...prevState,
                                category_image: imageSet(e, 'big'),
                            }))} id="bigimage" required={formfield.category_id===''?true:false} />
                            <div className="invalid-feedback">Image Required.</div>
                            <label htmlFor="bigimage" className="btn btn-sm btn-primary text-nowrap mt-2">Upload Image</label>
                        </div>
                        <div className="text-center">
                            <label htmlFor="smallimage" className="image-uploading d-inline-block">
                                <img src={!formfield.category_iconb64 ? ASSET_ENDPOINT + '/' + formfield.category_image_icon : formfield.category_iconb64} alt="icon" onError={noimageHandle} />
                            </label>
                            <input type="file" accept="image/*" className="d-none" name="category_image_icon" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                                ...prevState,
                                category_image_icon: imageSet(e, 'small'),
                            }))} id="smallimage" required={formfield.category_id === '' ? true : false} />
                            <div className="invalid-feedback">Icon Image Required.</div>
                            <label htmlFor="smallimage" className="btn btn-sm btn-primary text-nowrap mt-2">Upload Image Icon</label>
                        </div>
                    </div>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Category Code<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="category_code" placeholder="category_" defaultValue={formfield.category_code} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, category_code: e.target.value
                        }))} spellCheck="false" autoComplete="off" pattern="category_[a-zA-Z0-9_]+" required/>
                        <div className="invalid-feedback">
                            Code must be started with <strong>"category_"</strong> and should not be any space.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Category Name (English)<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="english_name" defaultValue={formfield.english_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, english_name: e.target.value
                        }))} spellCheck="false" autoComplete="off" required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Category Name (Arabic)</div>
                        <input type="text" className="form-control form-field" name="arabic_name" placeholder="" defaultValue={formfield.arabic_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, arabic_name: e.target.value
                        }))} autoComplete="off" dir="rtl" />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Category Name (Urdu)</div>
                        <input type="text" className="form-control" name="urdu_name" placeholder="" defaultValue={formfield.urdu_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, urdu_name: e.target.value
                        }))} autoComplete="off" dir="rtl" />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Category Props<strong className="text-danger">*</strong></div>
                        <ComboSelect
                            name="category_props"
                            options={formfield.category_list}
                            selected={formfield.category_selected}
                            id="categoryselect"
                            placeholder="Select"
                            required="requied"
                            type="multiple"
                            key="1"
                        />
                        <div className="invalid-feedback">This is required field.</div>
                    </label>
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Status</div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="is_active" type="checkbox" defaultValue={formfield.is_active} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_active: e.target.checked? 1 : 0
                            }))} checked={formfield.is_active === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Active</div>
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="in_product" type="checkbox" defaultValue={formfield.in_product} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, in_product: e.target.checked ? 1 : 0
                            }))} checked={formfield.in_product === 1 ? true : false } />
                            <div className="align-middle d-inline-block lh-1">In Product</div>                            
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="in_tender" type="checkbox" defaultValue={formfield.in_tender} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, in_tender: e.target.checked ? 1 : 0
                            }))} checked={formfield.in_tender === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">In Tender</div>
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => adsFunction(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Cancel">Cancel</button>
                </div>
            </form>
            
            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.category_id)} />
        </>
    )
}

export default Category