import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toastNotify, validateForm, asynsetItem} from '../../components/Helper';
import { fetchData, LOGIN } from '../../components/Service';

function Login(){
    const navigate = useNavigate();
    const[logUser] = useState(JSON.parse(localStorage.getItem('tw-records')))
    const [tinyloader, setTinyloader] = useState(false);

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }

    useEffect(() => {
        navigate(logUser && logUser.userType ? '/' +logUser.userType+ '/dashboard' : '/login');
    }, [logUser, navigate])

    const loginSubmission = (e) => {
        let requiredField = new FormData(document.getElementById('loginForm'));
        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(LOGIN, 'POST', requiredField, false, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    asynsetItem('tw-records', JSON.stringify(res.records)).then(function () {
                        navigate(`/${res.records.userType}/dashboard`);
                    })
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        };
    }

    return(
        <>
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                        <form className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="loginForm" noValidate>
                            <div className="text-center">
                                <img src="images/tawreed-logo.svg" alt="Tawreed - Building Connections" className="w-100 maxw-280" width="280" height="70.81"/>
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <h4 className="text-center my-3">Login</h4>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="email" placeholder="Username*" autoComplete="username" spellCheck={false} defaultValue={""} required/>
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg>
                                </span>
                                <input type="password" className="form-control rounded-pill-end-topbottom" onKeyUp={changeHandler} name="password" placeholder="Password*" autoComplete="current-password" minLength={8} defaultValue={""} title="You have to enter at least 8 digit!" required/>
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <label className="mb-3 "> 
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id='adminType' defaultChecked name="userType" value="admin" onKeyUp={changeHandler}/>
                                    <label className="form-check-label" htmlFor="adminType">Admin</label>
                                </div>

                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id='userType' name="userType" value="seller" onKeyUp={changeHandler}/>
                                    <label className="form-check-label" htmlFor="userType">Seller</label>
                                </div>
                                
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <button type="button" className="btn btn-primary btn-lg w-100 rounded-pill mb-3" onClick={(e)=>loginSubmission(e)} disabled={tinyloader}>
                                {
                                    !tinyloader ? 'Login'
                                    :
                                    <div className="spinner-border spinner-border-sm ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </button>
                            <div className="d-flex justify-content-between">
                                <Link to="/forgot-password" className="py-2 fw-medium d-block" title="Forgot Password">Forgot Password?</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login