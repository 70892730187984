import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, TENDER_LIST, TENDER_UPDATE } from "../../components/Service";

function Category() {
    window.document.title = "Tender List";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Category")
    const [userName, setUserName] = useState('')
    const [formfield, setFormfield] = useState('')
    const [status, setStatus] = useState('')
    const [userType, setUserType] = useState('')

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: TENDER_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        f_type:'',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            setUserType(user.userType)
        }
        tableSpinner(true, 'orderlistTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'orderlistTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("addupdateTender").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Category");
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/


    const tenderUpdateFunction = async (e) => {
        if (validateForm(e)){
            setTinyloader(true);
            setUpdatetable(true);
                fetchData(TENDER_UPDATE+'?tender_id='+formfield.tender_id, 'POST', status, true, false, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if(Object.keys(res.errors).length>0){
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    document.querySelector(`[name="${v}"]`).value = "";
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        }else{
                            toastNotify('danger', res.message)
                        }
                    }
                    setTinyloader(false);
                }, (err) => { });
            }
        }
        

    const editForm = (rec) => {
        setUserName(rec)
        setOffcanvastitle("Update Tender");
        fetchData(TENDER_UPDATE+'?tender_id='+rec.tender_id, 'GET', '', true, false, (res) => {
            setFormfield(res.records)
        }, (err) => { });  
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Tender List</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row g-2 align-items-start">
                                <div className="col col-md-auto">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                
                                
                                
                                <div className="col-md-auto ms-auto">
                                    <div className="input-group input-group-pill ">
                                        <label className="input-group-text" htmlFor="filtertenderstatus">
                                            <svg className="icon me-1" role="img"><use href="#icon_filter" /></svg>
                                            Tender Status
                                        </label>
                                        <select className="form-select w-auto" id="filtertenderstatus" onChange={(e) => dtActions(e, 'f_type')}>
                                            <option value="" selected>All</option>
                                            <option value="tender_status__negotiate">Negotiation</option>
                                            <option value="tender_status__progress">On Progress</option>
                                            <option value="tender_status__done">Completed</option>
                                            <option value="tender_status__failed">Failed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="orderlistTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th width="160">Tender Date</th>
                                                <th data-orderby="asc" data-sortby="tender_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Code
                                                </th>
                                                <th width="150" data-orderby="asc" data-sortby="tender_status" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Status
                                                </th>
                                                <th data-orderby="asc" data-sortby="tender_title" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Title
                                                </th>
                                                <th data-orderby="asc" data-sortby="tender_price" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Tender Price
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="user_name" onClick={(e) => dtActions(e, 'sortby')}>User</th>
                                                { userType && userType === 'admin' ? <th data-orderby="asc" data-sortby="is_active" onClick={(e) => dtActions(e, 'sortby')}>Company</th> : '' }
                                                <th width="90">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.tender_date).toDateString()}</td>
                                                    <td>{ele.tender_code}</td>
                                                    <td>
                                                        {
                                                            (ele.tender_status).split('__')[1] === "progress" ? <span className="badge text-dark bg-warning rounded-pill fw-medium minw-70">On Progress</span> : (ele.tender_status).split('__')[1] === "failed" ? <span className="badge bg-danger rounded-pill fw-medium minw-70">Failed</span> : (ele.tender_status).split('__')[1] === "done" ? <span className="badge bg-success rounded-pill fw-medium minw-70">Completed</span> : <span className="badge bg-dark rounded-pill fw-medium minw-70">Negotiation</span>
                                                        }
                                                    </td>
                                                    <td>{ele.tender_title}</td>
                                                    <td>{ele.tender_price}</td>
                                                    <td>{ele.user_name}</td>
                                                    { userType && userType === 'admin' ? <td>{ele.company_name}</td> : '' }
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={()=>editForm(ele)} data-bs-toggle="offcanvas" data-bs-target="#addupdateTender">
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addupdateTender" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <input type="text" className="d-none" name="ads_id" defaultValue={formfield.tender_id}/>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Tender Code<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="tender_code" placeholder="" defaultValue={formfield.tender_code} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Tender Date<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="tender_date" placeholder="" defaultValue={formfield.approved_at}  required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">User Name<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="user_name" placeholder="" defaultValue={userName.user_name} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Tender Title<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="tender_title" placeholder="" defaultValue={formfield.tender_title} required/>
                    </label>
                    { userType && userType === 'admin' ? 
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="company_name" placeholder="" defaultValue={userName.company_name} required/>
                    </label> : '' }
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Price (QAR)<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="tender_price" placeholder="" defaultValue={formfield.tender_price} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Payment Method<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="payment_method" placeholder="" defaultValue={formfield.payment_amount} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Payment Status<strong className="text-danger">*</strong></div>
                        <input type="text" readOnly className="form-control form-field" name="payment_status" placeholder="" defaultValue={formfield.payment_status} required/>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <div className="mb-4">
                        <div className="mb-1 fw-medium text-blue small">Tender Status<strong className="text-danger">*</strong></div>
                        <select className="form-select" name="tender_status" onChange={(e) => setStatus(prevState => ({
                            ...prevState, tender_status: e.target.value
                        }))} required>
                            <option value="tender_status__negotiate" selected={formfield.tender_status === 'tender_status__negotiate' ? true : false}>Negotiation</option>
                            <option value="tender_status__progress" selected={formfield.tender_status === 'tender_status__progress' ? true : false}>On Progress</option>
                            <option value="tender_status__done" selected={formfield.tender_status === 'tender_status__done' ? true : false}>Completed</option>
                            <option value="tender_status__failed" selected={formfield.tender_status === 'tender_status__failed' ? true : false}>Failed</option>
                        </select>
                    </div>
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => tenderUpdateFunction(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
        </>
    )
}

export default Category