import { Toast } from "bootstrap";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery';

export const scrollbarSetToTop = () => {
    setTimeout(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });  
    }, 200);
}

export const noimageHandle = (ev) => {
    ev.target.src = "images/no-image.png";
}

export const scrolldownpage = (e, id) => {
    e.preventDefault();
    if(document.getElementById(id) !== null){
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        [...document.querySelectorAll('.top-menu a, .bottom-mobile-menu .mobile-link')].forEach((i) => {
            i.classList.remove('active')
        })
        e.target.classList.add('active');
    }else{
        window.location.href = "/"
    }
}

export const ConfirmationModal = (props) => {
    return <>
        <div className="modal fade" id="confModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0 rounded-15">
                    <div className="modal-body fs-5 text-blue py-4 fw-medium text-center">
                        {props.msg}
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-secondary rounded-pill minw-100" data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-primary rounded-pill minw-100 ms-2" onClick={props.method}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export const toastNotify = (type, message) => {
    const idGenerate = Math.floor((Math.random() * 1000000) + 1);
    const htmlToast = `<div id="` + idGenerate + `" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000"><div class="d-flex bg-${type}"><div class="toast-body first-text-uppercase fw-medium1 fs-16px text-white">
        ${message}
    </div><button type="button" class="btn-close ms-auto me-2 mt-2 mmwh-22px" data-bs-dismiss="toast" aria-label="Close" title="Close"></button></div>
    </div>`;
    document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);
    // first toast id initialize then show by bootstrap 
    var getIdToast = document.getElementById(idGenerate);
    var toast = new Toast(getIdToast);
    toast.show();
    getIdToast.addEventListener('hidden.bs.toast', function () {
        setTimeout(() => {
            this.remove();
        }, 600);
    });
}

export const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result)
        };
        reader.readAsDataURL(blob);
    });
}

export const readFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = function (evt) {
            return evt.target.result;
        }
    }
}

export const checkFile = (e) => {
    const fileExt = ['jpeg', 'jpg', 'png'];
    const files = e.target.files[0];
    let ext = files.name.split('.').pop();
    if (!fileExt.includes(ext.toLowerCase())) {
        alert('Please upload file in pdf, jpeg, jpg, png format.');
        return ''
    } else {
        return files
    }
}


export const validateForm = (e) => {
    let forms = document.querySelectorAll('.needs-validation')
    let error = 0;
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            if (!form.checkValidity()) {
                error++;
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated')
        })
    if (error) {
        return false
    } else {
        return true
    }
}

export const loadingData = (e) => {
    if(e){
        document.querySelector('body').classList.add('loading-data');
    }else{
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const tableSpinner = (e, tableid) => {
    let tbl = document.getElementById(tableid);
    if(tbl!==null){
        if (e) {
            document.getElementById(tableid).classList.add('active');
        } else {
            document.getElementById(tableid).classList.remove('active');
        }
    }
}

export const asynsetItem = async (key, value) => {
    await null;
    return localStorage.setItem(key, value);
}
export const asyncgetItem = async (key) => {
    await null;
    return localStorage.getItem(key);
};



export const intlTel_phone = (phone_id, no = false) => {
    const input = document.querySelector(phone_id);
    let iti_phone = intlTelInput(input, { separateDialCode: true });
    window.intlTelInputGlobals.loadUtils("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js")
    if(no){
        iti_phone.setNumber(no)
    }
    input.addEventListener('keyup', function() {
        validatePhone(iti_phone, phone_id)
    });
    input.addEventListener('change', function() {
        validatePhone(iti_phone, phone_id)
    });
    return iti_phone
}

export const validatePhone = (iti_phone, phone_id) => {
    let errorMap = ["Phone number is invalid.", "Country code is invalid.", "Phone number is too short.", "Phone number is too long.", "Phone number is invalid."];
    $(phone_id).removeClass('is-invalid');
    let id = phone_id.split("#")[1]
    $(phone_id).parent().siblings('.intl-error').remove();
    if (!iti_phone.isValidNumber()) {
        var errorCode = iti_phone.getValidationError()
        $(phone_id).addClass('is-invalid')
        $(phone_id).parent().after(`<div class="text-danger intl-error" id=${id+ "-intl-error"}>${errorMap[errorCode] ? errorMap[errorCode] : 'Phone number is invalid.'}</div>`)
        return false;
    }else{
        return true;
    }
}

export const setNumber = (iti_phone, no = false) => {
    if(!no){
        no = iti_phone.getNumber();
    }
    iti_phone.setNumber(no)
}
