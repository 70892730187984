import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { WALLET_LIST } from "../../components/Service";
import { loadingData } from "../../components/Helper";

function Wallet() {
    window.document.title = "Payment Data";
    const [records, setRecords] = useState("");
    const [tablespinner, setTablespinner] = useState(true)

    const [dt, setDt] = useState({
        fetchurl: WALLET_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc'
    })

    useEffect(() => {
        loadingData(true)
        setTablespinner(true)
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            setTablespinner(false)
        });
    }, [dt])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Payment Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            <li>
                                                <button type="button" title="5" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(5, 'perpage')}>5</button>
                                            </li>
                                            <li>
                                                <button type="button" title="10" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(10, 'perpage')}>10</button>
                                            </li>
                                            <li>
                                                <button type="button" title="15" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(15, 'perpage')}>15</button>
                                            </li>
                                            <li>
                                                <button type="button" title="20" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(20, 'perpage')}>20</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-auto">&nbsp;</div>
                                </div>
                            </div>
                            <div className="table-spinner" data-tablespinner={tablespinner}>
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th className="minw-200 text-nowrap" data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>Company Name</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="wallet_balance" onClick={(e) => dtActions(e, 'sortby')}>Wallet Balance</th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="pending_balance" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Pending Balance
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="bank_name" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Bank Name
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="bank_holder_account" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Bank Holder Account
                                                </th>
                                                <th className="text-nowrap" data-orderby="asc" data-sortby="bank_holder_name" onClick={(e) => dtActions(e, 'sortby')} width="220">
                                                    Bank Holder Name
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.company_name}</td>
                                                    <td>{ele.wallet_balance}</td>
                                                    <td>
                                                        {
                                                            ele.pending_balance
                                                        }
                                                    </td>
                                                    <td>
                                                        {ele.bank_name}
                                                    </td>                                                    
                                                    <td className="text-capitalize">
                                                        {
                                                            (ele.bank_holder_account)
                                                        }
                                                    </td>
                                                    <td className="text-nowrap text-capitalize">
                                                        {ele.bank_holder_name}
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Wallet