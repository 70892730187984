import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { tableSpinner } from "../../components/Helper";
import { fetchData, TOTAL_TENDER_EXPORT, TOTAL_TENDER_LIST } from "../../components/Service";
import DateRangeFilter from '../../components/Filter';
import "react-datepicker/dist/react-datepicker.css";

function TotalTender() {
    window.document.title = "Tender List";
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: TOTAL_TENDER_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        f_type:'not_confirm',
        startdate: '',
        enddate: '',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        tableSpinner(true, 'totalTender')
        Datatable(dt, (result) => {
            tableSpinner(false, 'totalTender')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });
    },[dt])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    const getDateRange = (startDate, endDate) => {
        setStartDate(startDate)
        setEndDate(endDate)
        setDt(prevState => ({
            ...prevState,
            startdate: startDate,
            enddate: endDate,
        }))
    }

    const exportData = () =>{
        fetchData(`${TOTAL_TENDER_EXPORT}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, false, (res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report Tender.xlsx`);
            document.body.appendChild(link);
            link.click();
        }, (err) => {}, "blob");
    }
    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Tender List</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                        {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="input-group input-group-pill flex-nowrap">
                                            <div className="input-group-text">
                                                <svg className="icon tscale-1p3" role="img"><use href="#icon_calendar" /></svg>
                                            </div>
                                            <DateRangeFilter
                                                daterange=""
                                                className="form-control text-center"
                                                placeholder="Choose Date Range"
                                                maxDate={new Date()}
                                                opens="left"
                                                name='d_daterange'
                                                disableUpdateInput={false}
                                                getDateRange={getDateRange}
                                            />
                                            <button type="button" onClick={exportData} className="btn btn-primary px-3 text-nowrap">
                                                <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_download" /></svg>
                                                <span className="d-none d-md-inline">Export</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="totalTender">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th className="minw-200" data-orderby="asc" data-sortby="date" onClick={(e) => dtActions(e, 'sortby')}>Date</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="tender_code" onClick={(e) => dtActions(e, 'sortby')}>Tender Code</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="tender_title" onClick={(e) => dtActions(e, 'sortby')}>Title</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>Company</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="user_name" onClick={(e) => dtActions(e, 'sortby')}>User</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="tender_status" onClick={(e) => dtActions(e, 'sortby')}>Tender Status</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="payment_method" onClick={(e) => dtActions(e, 'sortby')}>Payment Method</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="tender_price" onClick={(e) => dtActions(e, 'sortby')}>Price (QAR)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.date).toDateString()}</td>
                                                    <td>{ele.tender_code}</td>
                                                    <td>{ele.tender_title}</td>
                                                    <td>{ele.company_name}</td>
                                                    <td>{ele.user_name}</td>
                                                    <td>{ele.tender_status}</td>
                                                    <td>{ele.payment_method}</td>
                                                    <td>{ele.tender_price}</td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={11}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={11}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TotalTender
