import React, { useEffect, useState } from "react";
import { fetchFrontData, ADVERTISE, PLACING_ADS, ASSET_ENDPOINT } from "../../components/Service";
import { noimageHandle, validateForm, toastNotify, scrollbarSetToTop } from "../../components/Helper";
import TopHeader from "../../components/TopHeader";

function Advertisement() {

    const[langType, setLangType] = useState(localStorage.getItem('lang_type'))
    const[data, setData] = useState({
        ads_image: '',
        cms__ads_email: '',
        cms__ads_error: '',
        cms__ads_message: '',
        cms__ads_name: '',
        cms__ads_submit: '',
        cms__ads_success: '',
        cms__ads_text: '',
        cms__ads_title: '',
    })

    useEffect(() => {
        scrollbarSetToTop()
        fetchFrontData(ADVERTISE, 'GET', '', (res) => {
            if(res.status){
                setData({
                    ads_image: res.records.ads_image,
                    cms__ads_email: res.records.cms__ads_email,
                    cms__ads_error: res.records.cms__ads_error,
                    cms__ads_message: res.records.cms__ads_message,
                    cms__ads_name: res.records.cms__ads_name,
                    cms__ads_submit: res.records.cms__ads_submit,
                    cms__ads_success: res.records.cms__ads_success,
                    cms__ads_text: res.records.cms__ads_text,
                    cms__ads_title: res.records.cms__ads_title,
                })
            }

        }, (err) => {})

    }, [langType])

    const submitForm = async (e) => {
        let formdata = new FormData(document.getElementById('placing_ads'));
        if (validateForm(e)) {
            fetchFrontData(PLACING_ADS, 'POST', formdata, (res) => {
                if (res.status) {
                    toastNotify('success', data.cms__ads_success)
                    let reset_id = document.getElementById('placing_ads')
                    reset_id.reset()
                    reset_id.classList.remove('was-validated');
                } else {
                    toastNotify('danger', data.cms__ads_error)
                }
            }, (err) => {}, false);
        }
    }

    return(
        <>
            <TopHeader refresh={setLangType} reload={true} />
            <section className={`container py-5 ${langType === 'ur' || langType === 'ar' ? ' direction-rtl' : ''}`}>
                <div className="row g-4">
                    <div className="col-lg-8">
                        <img className="w-100 rounded"  src={ASSET_ENDPOINT + data.ads_image} onError={noimageHandle} alt="advertize" />
                        <div className="pt-3" dangerouslySetInnerHTML={{__html: data.cms__ads_text}}></div>
                    </div>
                    <div className="col-lg-4 ps-lg-4">
                        <form className="border bg-light rounded p-4 needs-validation" id="placing_ads"  noValidate>
                            <h5 className="fw-bold py-3">{data.cms__ads_title}</h5>
                            <div className="row">
                                <div className="mb-3">
                                    <label htmlFor="name" className="label-control">{data.cms__ads_name}</label>
                                    <input name="name" type="text" placeholder={data.cms__ads_name} className="form-control input-custom" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="label-control">{data.cms__ads_email}</label>
                                    <input name="email" type="text" placeholder={data.cms__ads_email} className="form-control input-custom" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="label-control">{data.cms__ads_message}</label>
                                    <textarea name="message" rows="4" className="form-control input-custom" placeholder={data.cms__ads_message} required></textarea>
                                </div>
                                <div className="mb-3">
                                    <button type="button" onClick={(e) => submitForm(e)} className="btn btn-lg  btn-bluedark w-100">{data.cms__ads_submit}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )   
}
export default Advertisement