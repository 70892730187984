import React, { useEffect, useState } from "react";
import { fetchFrontData, SLIDER, DOWNLOAD, FEATURE, TESTIMONIAL, INSTAGRAM, ASSET_ENDPOINT } from "../../components/Service";
import { noimageHandle, scrollbarSetToTop } from "../../components/Helper";

import $ from 'jquery';
window.jQuery = $
require('owl.carousel/dist/assets/owl.carousel.css');
require('owl.carousel');

function Home() {
    scrollbarSetToTop()
    const [reload, setReload] = useState('1')
    const[download, setDownload] = useState({
        download_android: '',
        download_android_on: '',
        download_ios: '',
        download_ios_on: '',
        download_subtitle: '',
        download_title: '',
    })
    const[feature, setFeature] = useState('')
    const[testimonial, setTestimonial] = useState('')
    const[instagram, setInstagram] = useState({
        instagram_subtitle: '',
        instagram_title: '',
        instagram_token: '',
    })
    const[slider, setSlider] = useState('')

    useEffect(() => {

        $('#testimonial').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }
        });

        $('#banner').owlCarousel({
            items: 1,
            loop: true,
            margin: 0,
            nav: false,
            autoplay: true,
        });
        
        fetchFrontData(SLIDER, 'GET', '', (res) => {
            if(res.status){
                setSlider(res.records)
                $('#banner').owlCarousel("destroy")
                $('#banner').owlCarousel({
                    items: 1,
                    loop: true,
                    margin: 0,
                    nav: false,
                    autoplay: true,
                });
            }

        }, (err) => {})

        fetchFrontData(DOWNLOAD, 'GET', '', (res) => {
            if(res.status){
                setDownload({
                    download_android: res.records.download_android,
                    download_android_on: res.records.download_android_on,
                    download_ios: res.records.download_ios,
                    download_ios_on: res.records.download_ios_on,
                    download_subtitle: res.records.download_subtitle,
                    download_title: res.records.download_title,
                })
            }

        }, (err) => {})

        fetchFrontData(FEATURE, 'GET', '', (res) => {
            if(res.status){
                setFeature(res.records)
            }

        }, (err) => {})

        fetchFrontData(TESTIMONIAL, 'GET', '', (res) => {
            if(res.status){
                setTestimonial(res.records)
                $('#testimonial').owlCarousel("destroy")
                $('#testimonial').owlCarousel({
                    loop: true,
                    margin: 10,
                    nav: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    }
                });
            }

        }, (err) => {})

        fetchFrontData(INSTAGRAM, 'GET', '', (res) => {
            if(res.status){
                setInstagram({
                    instagram_subtitle: res.records.instagram_subtitle,
                    instagram_title: res.records.instagram_title,
                    instagram_token: res.records.instagram_token,
                })
            }

        }, (err) => {})


        document.querySelectorAll("li.languageChange").forEach((e)=>{
            e.addEventListener('click', () => {
                setTimeout(() => {
                    setReload(reload + 1)
                }, 100)
            })
        });
    }, [reload])

    return(
        <>
            <style>
                {`
                .owl-nav{
                    text-align: center;
                }
                .owl-nav button{
                    margin: 0 10px;
                    font-size: 40px !important;
                }
                `}
            </style>

            {/* Slider */}
            <div className="owl-carousel owl-theme owl-home" id="banner">
                {slider && slider.length > 0 ? slider.map((item, key) => {
                    return (<div className="item" key={key}>
                        <img src={ASSET_ENDPOINT + item.slider_image} onError={noimageHandle} className="item-image" alt="home" />
                        <div className="owl-caption">
                            <div className="container text-center">
                                <h2 className="display-2 fw-medium">{item.slider_title}</h2>
                                <p className="fs-5 text-white my-3">{item.slider_body}</p>
                                <a href={item.slider_url} className="btn btn-bluelight btn-lg minw-160 my-3">Go Now!</a>
                            </div>
                        </div>
                    </div>)
                }) : ''}
            </div>

            {/* App Download */}
            <div className="container text-center download-app py-5">
                <p className="m-0 fw-medium" style={{color: "#18AFE6"}}>Download Application</p>
                <h3 className="text-center mt-0 title">{download.download_title}</h3>
                <div className="download-content">
                    <p className="">{download.download_subtitle} </p>
                    <a href={download.download_android} className="text-decoration-none pe-2 mb-1">
                        <img src="images/google-play.png" onError={noimageHandle} alt="google play" />
                    </a>
                    <a href={download.download_ios} className="text-decoration-none pe-2 mb-1">
                        <img src="images/App-store.png" onError={noimageHandle} alt="app store" />
                    </a>
                </div>
            </div>

            {/* Features */}
            <section className="features container p-5">
                <p className="m-0 fw-medium text-center" style={{color: "#18AFE6"}}>Our Features</p>
                <h3 className="text-center mt-0 title">{ feature[0] && feature[0].feature_main_title ? feature[0].feature_main_title : '' }</h3>
                <div className="row">
                    {feature && feature.length > 0 ? feature.map((item, key) => {
                        return (<div className="col-6 col-sm-4 col-md-3" key={key}>
                            <div className="card mb-4 text-center">
                                <img src={ASSET_ENDPOINT + item.feature_image} onError={noimageHandle} className="card-img-top" alt="feature" />
                                <div className="card-body px-0">
                                    <h3 className="card-title pt-2 pb-3">{item.cms__feature_title}</h3>
                                    <p className="card-text">{item.cms__feature_body}</p>
                                </div>
                            </div>
                        </div>)
                    }) : ''}
                </div>
            </section>

            {/* Testimonial */}

            <section className="testimonials container p-5">
                <p className="m-0 fw-medium text-center" style={{color: "#18AFE6"}}>Our Testimonial</p>
                <h3 className="text-center mt-0 title">{ testimonial[0] && testimonial[0].testimonial_main_title ? testimonial[0].testimonial_main_title : '' }</h3>
                <div className="owl-carousel owl-theme owl-testimonial" id="testimonial">
                {testimonial && testimonial.length > 0 ? testimonial.map((item, key) => {
                    return (<div className="item" key={key}>
                        <div className="testimonial-block bg-white shadow">
                            <p className="comment pb-3">
                                {item.testimonial_body}
                            </p>
                            <div className="author pt-3">
                                <div className="author-image">
                                    <img src={ASSET_ENDPOINT + item.testimonial_image} onError={noimageHandle} className="w-100 rounded" alt="testimonial" />
                                </div>
                                <div className="author-detail text-center">
                                    <p className="h5 fw-bold">{item.testimonial_name}</p>
                                    <p className="m-0 fw-medium" style={{color: "#18AFE6"}}>{item.testimonial_title}</p>
                                    <div className="star-rating">
                                        <span style={{width: Number(item.testimonial_stars * 20) +'%'}}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }) : ''}
                </div>
            </section>

            {/* Instagram */}
            <section className="bg-light">
                <div className="container text-center py-5">
                <p className="m-0 fw-medium text-center" style={{color: "#18AFE6"}}>Instagram Photos</p>
                    <h3 className="mb-4 text-center title">{instagram.instagram_title}</h3>
                    <p className="mb-5 pt-3">{instagram.instagram_subtitle}</p>
                </div>
            </section>
        </>
    )   
}
export default Home