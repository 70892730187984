import React, { useState, useEffect } from "react";
import { toastNotify, validateForm, noimageHandle, blobToBase64, loadingData } from "../../../components/Helper";
import { fetchData, ASSET_ENDPOINT, GENERAL_SECTION } from "../../../components/Service";

function GeneralSection() {
    window.document.title = "General Section Settings";

    const [langType, setLangType] = useState('en');
    const [record, setRecord] = useState('');
    const [tinyloader, setTinyloader] = useState(false);
    const [siteIcon, setSiteIcon] = useState();
    const [siteLogo, setSiteLogo] = useState();
    const [siteLogoAlt, setSiteLogoAlt] = useState();
    const [siteLogoAlt2, setSiteLogoAlt2] = useState();

    const handleInputChange = async (e, type) => {
        if (type === 'site_icon') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setRecord(prevState => ({
                ...prevState,
                site_iconb64: imgb64
            }));
        }
        if (type === 'site_logo') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setRecord(prevState => ({
                ...prevState,
                site_logob64: imgb64
            }));
        }
        if (type === 'site_logo_alt') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setRecord(prevState => ({
                ...prevState,
                site_logo_altb64: imgb64
            }));
        }
        if (type === 'site_logo_alt2') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setRecord(prevState => ({
                ...prevState,
                site_logo_alt2b64: imgb64
            }));
        }
        else {
            setRecord(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        }
    }

    useEffect(() => {
        loadingData(true)
        fetchData(GENERAL_SECTION + `?lang_type=${langType}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            setRecord({
                site_title: res.records.site_title,
                site_tagline: res.records.site_tagline,
                site_bottom: res.records.site_bottom,
                link_advertise: res.records.link_advertise,
                link_home: res.records.link_home,
                link_contact: res.records.link_contact,
                link_about: res.records.link_about,
                link_login: res.records.link_login,
                link_privacy: res.records.link_privacy,
                link_terms_conds: res.records.link_terms_conds,
                site_iconb64: '',
                site_logob64: '',
                site_logo_alt: '',
                site_logo_alt2: '',
            })
            setSiteIcon(res.records.site_icon)
            setSiteLogo(res.records.site_logo)
            setSiteLogoAlt(res.records.site_logo_alt)
            setSiteLogoAlt2(res.records.site_logo_alt2)

        }, (err) => { })
    }, [langType])

    const updateGeneralData = async (e) => {
        let formData = new FormData(document.getElementById('updateGeneralData'));
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(GENERAL_SECTION + `?lang_type=${langType}`, 'POST', formData, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateGeneralData').reset()
        setLangType(lang)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">General Section Settings</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateGeneralData" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                                <div className="bg-white p-3 rounded-10" id="englishbox" role="tabpanel">
                                    <div className="row mb-3">
                                        <div className="offset-md-3 col-md-7">
                                            <h6 className="text-blue">General *</h6>
                                            <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Site Title:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="site_title" spellCheck="false" defaultValue={record.site_title} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Tagline:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="site_tagline" spellCheck="false" defaultValue={record.site_tagline} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Bottom Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="site_bottom" defaultValue={record.site_bottom} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <h6 className="text-blue">Text Link *</h6>
                                            <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text Home:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_home" defaultValue={record.link_home} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text About Us:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_about" defaultValue={record.link_about} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text Contact Us:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_contact" defaultValue={record.link_contact} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text Advertise:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_advertise" defaultValue={record.link_advertise} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text Login:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_login" defaultValue={record.link_login} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text Privacy Policy:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_privacy" defaultValue={record.link_privacy} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Text Terms & Conditions:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="link_terms_conds" defaultValue={record.link_terms_conds} onChange={handleInputChange} required />
                                        </div>
                                    </div>

                                    <div className="row py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <h6 className="text-blue">Icon & Logo *</h6>
                                            <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="offset-md-3 col-md-7">
                                            <div className="row row-cols-2 row-cols-lg-4 g-4 text-start">
                                                <div className="col">
                                                    <div className="d-block">
                                                        <label htmlFor="site_icon" className="image-uploading d-inline-block">
                                                            <img src={!record.site_iconb64 ? ASSET_ENDPOINT + '/' + siteIcon : record.site_iconb64} alt="site_icon" onError={noimageHandle}/>
                                                        </label>
                                                        <input type="file" accept="image/*" className="d-none" name="site_icon" title="Upload Image" id="site_icon" onChange={(e) => handleInputChange(e,'site_icon')} />
                                                        <div className="invalid-feedback">Image Required.</div>
                                                    </div>
                                                    <label htmlFor="site_icon" className="btn btn-sm btn-info minw-120 text-nowrap mt-2">Upload Icon</label>
                                                </div>
                                                <div className="col">
                                                    <div className="d-block">
                                                        <label htmlFor="site_logo" className="image-uploading d-inline-block">
                                                            <img src={!record.site_logob64 ? ASSET_ENDPOINT + '/' + siteLogo : record.site_logob64} alt="site_logo" onError={noimageHandle}/>
                                                        </label>
                                                        <input type="file" accept="image/*" className="d-none" name="site_logo" title="Upload Image" id="site_logo" onChange={(e) => handleInputChange(e,'site_logo')} />
                                                        <div className="invalid-feedback">Icon Image Required.</div>
                                                    </div>
                                                    <label htmlFor="site_logo" className="btn btn-sm btn-info minw-120 text-nowrap mt-2">Upload Logo</label>
                                                </div>
                                                <div className="col">
                                                    <div className="d-block">
                                                        <label htmlFor="site_logo_alt" className="image-uploading d-inline-block">
                                                        <img src={!record.site_logo_altb64 ? ASSET_ENDPOINT + '/' + siteLogoAlt : record.site_logo_altb64} alt="site_logo" onError={noimageHandle}/>
                                                        </label>
                                                        <input type="file" accept="image/*" className="d-none" name="site_logo_alt" title="Upload Image" id="site_logo_alt" onChange={(e) => handleInputChange(e,'site_logo_alt')} />
                                                        <div className="invalid-feedback">Icon Image Required.</div>
                                                    </div>
                                                    <label htmlFor="site_logo_alt" className="btn btn-sm btn-info minw-120 text-nowrap mt-2">Upload Logo Alt</label>
                                                </div>
                                                <div className="col">
                                                    <div className="d-block">
                                                        <label htmlFor="site_logo_alt2" className="image-uploading d-inline-block">
                                                            <img src={!record.site_logo_alt2b64 ? ASSET_ENDPOINT + '/' + siteLogoAlt2 : record.site_logo_alt2b64} alt="site_logo_alt2" onError={noimageHandle}/>
                                                        </label>
                                                        <input type="file" accept="image/*" className="d-none" name="site_logo_alt2" title="Upload Image" id="site_logo_alt2" onChange={(e) => handleInputChange(e,'site_logo_alt2')} />
                                                        <div className="invalid-feedback">Icon Image Required.</div>
                                                    </div>
                                                    <label htmlFor="site_logo_alt2" className="btn btn-sm btn-info text-nowrap mt-2">Upload Logo Alt Dark</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3 py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateGeneralData(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                        :
                                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default GeneralSection;
