import React, { useEffect, useState } from 'react';
import { toastNotify, validateForm, noimageHandle, blobToBase64, loadingData, intlTel_phone, validatePhone } from "../../components/Helper";
import { fetchData, COMPANY_CATEGORY_LIST, MY_PROFILE, UPDATE_ADMIN_PROFILE, ASSET_ENDPOINT } from "../../components/Service";
import ComboSelect from "../../components/ComboSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function MyProfile(){
    window.document.title = "My Profile";
    const [tinyloader, setTinyloader] = useState(false);
    const [intlTel, setIntlTel] = useState()
    const [adminData, setAdminData] = useState({
        staff_name: '',
        staff_phone: '',
        staff_email: '',
        staff_birthdate: new Date(),
        staff_profile_photo: '',
    })
    const [profile, setProfile] = useState({
        company_name: '',
        company_address: '',
        company_about_me: '',
        company_contact_person: '',
        company_email: '',
        company_phone: '',
        company_profile_photo: '',
        profile_photo: '',
    })
    const [userType, setUserType] = useState('')
    const [img, setImg] = useState('')
    const [categoryList, setCategoryList] = useState()
    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0]);
        setImg(imgb64)
    }

    const companyCategoryList = () =>{
        fetchData(COMPANY_CATEGORY_LIST, 'GET', '', true, false, (res) => {
            if (res.status) {
                setCategoryList(res.records)
            }
        }, (err) => {});
    }

    const profileData = () => {
        fetchData(MY_PROFILE, 'GET', '', true, false, (res) => {
            if (res.status) {
                setProfile(res.records.company_data)
                setProfile(prevState => ({
                    ...prevState,
                    company_category: res.records.company_category.toString(),
                }))
                setIntlTel(intlTel_phone('#phone', res.records.company_data.company_phone))
                document.querySelector(".iti").classList.add("w-100")
            }
        }, (err) => {});
    }
    const adminProfileData = () => {
        fetchData(UPDATE_ADMIN_PROFILE, 'GET', '', true, false, (res) => {
            if (res.status) {
                setAdminData(prevState => ({
                    ...prevState,
                    staff_name: res.records.staff_name,
                    staff_phone: res.records.staff_phone,
                    staff_email: res.records.staff_email,
                    staff_birthdate: new Date(res.records.staff_birthdate),
                    staff_profile_photo: res.records.staff_profile_photo,
                }))
                setIntlTel(intlTel_phone('#phone', res.records.staff_phone))
                document.querySelector(".iti").classList.add("w-100")
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }

    useEffect(() => {
        loadingData(true)
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            loadingData(false)
            setUserType(user.userType)
        }
        if(user && user.userType === 'admin'){
            adminProfileData()
        }else{
            companyCategoryList()
            profileData()
        }        
    }, [])

    const updateProfile = (e) => {
        let form = document.getElementById('myprofileForm')
        let data = new FormData(form);
        data.append('company_phone', intlTel.getNumber())
        if(validateForm(e) && validatePhone(intlTel, '#phone')) {
            setTinyloader(true);
            fetchData(MY_PROFILE, 'POST', data, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    localStorageUpdate(userType, data.get('company_name'), data.get('company_profile_photo'))
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        };
        
    }

    const updateAdminProfileData = (e) => {
        let form = document.getElementById('adminprofileForm')
        let data = new FormData(form);
        data.append('staff_phone', intlTel.getNumber())
        data.append('staff_birthdate', adminData.staff_birthdate.toISOString())
        if(validateForm(e) && validatePhone(intlTel, '#phone')) {
            setTinyloader(true);
            fetchData(UPDATE_ADMIN_PROFILE, 'POST', data, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    localStorageUpdate(userType, data.get('staff_name'), data.get('staff_profile_photo'))
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        };
    }

    const localStorageUpdate = async (user_type, ele1, ele2) => {
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user_type === 'admin'){
            await localStorage.setItem( 'tw-records', JSON.stringify({...user, staff_name: ele1, staff_profile_photo: ele2}))
        }else{
            await localStorage.setItem('tw-records', JSON.stringify({...user, company_name: ele1, company_profile_photo: ele2}))
        }
    }

    return(
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between d-md-none">
                    <div className="col mb-2">
                        <h4 className="page-heading">My Profile</h4>
                    </div>
                </div>
                { userType && userType === 'admin' ? 
                <form className="row needs-validation" id="adminprofileForm" noValidate>
                    <div className="col-sm-12">
                        <div className="row mx-0 bg-white rounded-15 p-4 shadow-sm">
                            <div className='col-sm-12 col-lg-6 my-2'>
                                <label htmlFor="staff_name" className="form-label small text-blue fw-medium mb-1">Full Name<strong className="text-danger">*</strong></label>
                                <input type="text" className="form-control" id='staff_name' name="staff_name" placeholder="Full Name" spellCheck={false} defaultValue={adminData.staff_name} required />
                            </div>
                            <div className="col-sm-12"></div>
                            <div className='col-sm-12 col-lg-6 my-2'>
                                <label htmlFor="phone" className="form-label small text-blue fw-medium mb-1">Phone Number<strong className="text-danger">*</strong></label>
                                <input type="tel" className="form-control" id='phone' data-intl-tel-input-id="0" name="staff_phone" defaultValue={adminData.staff_phone} required />
                            </div>
                            <div className="col-sm-12"></div>
                            <div className='col-sm-12 col-lg-6 my-2'>
                                <label htmlFor="staff_birthdate" className="form-label small text-blue fw-medium mb-1">Birthdate<strong className="text-danger">*</strong></label>
                                <DatePicker
                                    selected={adminData.staff_birthdate ? adminData.staff_birthdate : new Date()}
                                    onChange={(date) => setAdminData(prevState => ({
                                        ...prevState,
                                        staff_birthdate: date,
                                    }))}
                                    className="form-control"
                                    maxDate={new Date()}
                                    dateFormat="MMM dd, yyyy"
                                    id='staff_birthdate'
                                    closeOnScroll={true}
                                    showPopperArrow={false}
                                    onChangeRaw={(e) => e.preventDefault()}
                                />
                            </div>
                            <div className="col-sm-12"></div>
                            <div className='col-sm-12 col-lg-6 my-2'>
                                <label htmlFor="staff_email" className="form-label small text-blue fw-medium mb-1">Email<strong className="text-danger">*</strong></label>
                                <input type="text" className="form-control" id='staff_email' name="staff_email" placeholder="Email" spellCheck={false} defaultValue={adminData.staff_email} required />
                            </div>
                            <div className='col-sm-12 my-2'>
                                <div className="d-flex flex-wrap align-items-center">
                                    <input type="file" accept="image/*" className="d-none" name="staff_profile_photo" title="Upload Image" onChange={async (e) => imageSet(e)} id="staff_profile_photo" />
                                    <img src={!img ? ASSET_ENDPOINT + '/' + adminData.staff_profile_photo : img} height={100} className="me-2" alt="Profile" onError={noimageHandle} />
                                    <label htmlFor="staff_profile_photo" className="btn btn-sm btn-info text-nowrap my-2">Upload Profile Photo</label>
                                </div>
                            </div>
                            <div className='col-sm-12 my-2 pt-3'>
                                <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium" onClick={(e) => updateAdminProfileData(e)} disabled={tinyloader}>
                                    {
                                        !tinyloader ? 'Submit'
                                        :
                                        <div className="spinner-border spinner-border-sm ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form> : '' }

                { userType && userType === 'seller' ? 

                <form className="row needs-validation" id="myprofileForm" noValidate>
                    <div className="col-sm-12">
                        <div className="row mx-0 bg-white rounded-15 p-4 shadow-sm">                            
                            <div className="col-sm-6 my-2">
                                    <label htmlFor="company_name" className="form-label small text-blue fw-medium mb-1">Name<strong className="text-danger">*</strong></label>
                                <input type="text" className="form-control" id='company_name' placeholder="Name" spellCheck={false} defaultValue={profile.company_name} required name="company_name" />
                            </div>
                            <div className="col-sm-6 my-2">
                                <label htmlFor="company_category" className="form-label  small text-blue fw-medium mb-1">Services Categories<strong className="text-danger">*</strong></label>
                                <ComboSelect 
                                    name="company_category" 
                                    options={categoryList ? categoryList : ''}
                                    selected={categoryList ? profile.company_category : ''}
                                    id="company_category"
                                    placeholder="Select"
                                    required="requied"
                                    type="multiple"
                                    key="1"
                                />
                            </div>
                            <div className="col-sm-6 my-2">
                                <label htmlFor="company_email" className="form-label  small text-blue fw-medium mb-1">Email<strong className="text-danger">*</strong></label>
                                <input type="text" className="form-control" id='company_email' placeholder="Email" name="company_email" spellCheck={false} defaultValue={profile.company_email} required />
                            </div>
                            <div className="col-sm-6 my-2">
                                <label htmlFor="phone" className="form-label  small text-blue fw-medium mb-1">Phone<strong className="text-danger">*</strong></label>
                                <input type="tel" id='phone' data-intl-tel-input-id="0" className="form-control" name="company_phone" defaultValue={profile.company_phone} required />
                            </div>
                            <div className="col-sm-12 my-2">
                                <label htmlFor="company_address" className="form-label  small text-blue fw-medium mb-1">Address<strong className="text-danger">*</strong></label>
                                <textarea className="form-control" id='company_address' rows={4} placeholder="Address" name="company_address" spellCheck={false} defaultValue={profile.company_address} required></textarea>
                            </div>
                            <div className="col-sm-6 my-2">
                                <label htmlFor="company_contact_person" className="form-label  small text-blue fw-medium mb-1">Contact Person<strong className="text-danger">*</strong></label>
                                <input type="text" className="form-control" id='company_contact_person' placeholder="Contact Person" name="company_contact_person" spellCheck={false} defaultValue={profile.company_contact_person} required />
                            </div>
                            <div className="col-sm-6 my-2">
                                <label htmlFor="company_about_me" className="form-label  small text-blue fw-medium mb-1">About Us</label>
                                <input type="text" className="form-control"  id='company_about_me' placeholder="About Us" name="company_about_me" defaultValue={profile.company_about_me} />
                            </div>
                            <div className="col-sm-6 my-2">
                                <div className="d-flex flex-wrap align-items-center">
                                    <input type="file" accept="image/*" className="d-none" name="profile_photo" title="Upload Image"  onChange={async (e) => imageSet(e)} id="profile_photo" />
                                    <img src={!img ? ASSET_ENDPOINT + '/' + profile.company_profile_photo : img} height={100} className="me-2" alt="Profile" onError={noimageHandle} />
                                    <label htmlFor="profile_photo" className="btn btn-sm btn-info text-nowrap my-2">
                                        Upload Profile Photo
                                    </label>
                                </div>
                            </div>

                            <div className="col-sm-12 text-center text-sm-start my-2 pt-3">
                                <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium" onClick={(e) => updateProfile(e)} disabled={tinyloader}>
                                    {
                                        !tinyloader ? 'Submit'
                                        :
                                        <div className="spinner-border spinner-border-sm ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form> : '' }
            </div>
        </section>
    )
}

export default MyProfile