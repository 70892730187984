import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, noimageHandle, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, COMPANY_LIST, COMPANY_VERIFY, ASSET_ENDPOINT } from "../../components/Service";

function CompanyVerification() {
    window.document.title = "Company Verification";
    const [updatetable, setUpdatetable] = useState(true);
    const [tinyloader, setTinyloader] = useState(false);

    const [formfield, setFormfield] = useState({
        company_id: "",
        company_name: "",
        company_email: "",
        company_address: "",
        company_contact: "",
        company_image:""
    })

    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: COMPANY_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        f_type:'not_confirm'
    })

    useEffect(() => {
        
        loadingData(true)
        tableSpinner(true, 'companyVerify')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'companyVerify')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("companyVerification").addEventListener('show.bs.offcanvas', function (e) {
            let vl = e.relatedTarget.dataset.dataz.split('||');
            setFormfield(prevState => ({ /*Set empty state*/
                ...prevState,
                company_id: vl[0],
                company_name: vl[1],
                company_email: vl[2],
                company_address: vl[4],
                company_contact: vl[3],
                company_image: vl[5]
            }));
        });

        document.getElementById("companyVerification").addEventListener('hidden.bs.offcanvas', function (e) {
            setFormfield(prevState => ({ /*Set empty state*/
                ...prevState,
                company_id: "",
                company_name: "",
                company_email: "",
                company_address: "",
                company_contact: "",
                company_image: ""
            }))
        })

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }


    const companyVerify = (id) => {
        setTinyloader(true);
        setUpdatetable(true);
        fetchData(COMPANY_VERIFY + '/' + id, 'POST', {is_confirmed: 1}, true, false, (res) => {
            setTinyloader(false);
            if (res.status) {
                toastNotify('success', res.message)
                document.querySelector('#companyVerification [data-bs-dismiss="offcanvas"]').click();
                setUpdatetable(false);
                localStorage.setItem('isnotify', 'true'); /*Update Notifications*/
            } else {
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.message)
                }
            }
        }, (err) => { });

    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Company Verification</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            <li>
                                                <button type="button" title="5" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(5, 'perpage')}>5</button>
                                            </li>
                                            <li>
                                                <button type="button" title="10" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(10, 'perpage')}>10</button>
                                            </li>
                                            <li>
                                                <button type="button" title="15" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(15, 'perpage')}>15</button>
                                            </li>
                                            <li>
                                                <button type="button" title="20" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(20, 'perpage')}>20</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-auto">&nbsp;</div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="companyVerify">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th data-orderby="asc" data-sortby="company_email" onClick={(e) => dtActions(e, 'sortby')}>Email</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_phone" onClick={(e) => dtActions(e, 'sortby')}>Phone</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_address" onClick={(e) => dtActions(e, 'sortby')}>Address</th>
                                                <th width="180" data-orderby="asc" data-sortby="is_verified" onClick={(e) => dtActions(e, 'sortby')}>Phone Verified?</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.company_name}</td>
                                                    <td>{ele.company_email}</td>
                                                    <td>{ele.company_phone}</td>
                                                    <td>{ele.company_address}</td>
                                                    <td>{ele.is_verified?'Verified':'Not Verified'}</td>                                               
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm btn-primary px-3 fs-6" data-bs-toggle="offcanvas" data-bs-target="#companyVerification" title="Action" 
                                                            data-dataz={ele.company_id + '||' + ele.company_name + '||' + ele.company_email + '||' + ele.company_phone + '||' + ele.company_address + '||' + ele.company_verification_document}>
                                                            Action
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={6}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="offcanvas offcanvas-end" tabIndex="-1" id="companyVerification" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">Verify Company</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={ASSET_ENDPOINT + '/' + formfield.company_image} alt="ads_image" className="me-3" onError={noimageHandle} />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Registration ID<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="company_id" defaultValue={formfield.company_id} disabled/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control w-100" name="company_name" value={formfield.company_name} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company Email<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control w-100" name="company_email" value={formfield.company_email} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company Address<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control w-100" name="company_address" defaultValue={formfield.company_address} disabled/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company Contact<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="company_contact" value={formfield.company_contact} disabled />
                    </label>
                </div>
                <div className="offcanvas-footer text-center text-sm-start border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-success rounded-pill minw-140 fw-medium me-3" onClick={(e) => companyVerify(formfield.company_id)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Confirm'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Cancel">Cancel</button>
                </div>
            </div>
        </>
    )
}

export default CompanyVerification
