import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, tableSpinner, validateForm } from "../../components/Helper";
import { fetchData, ORDER_REVIEW, UPDATE_ORDER_REVIEW, DELETE_ORDER_REVIEW } from "../../components/Service";

function OrderReviewConfirmation() {
    window.document.title = "Order Review Confirmation";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [formfield, setFormfield] = useState('')

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: ORDER_REVIEW,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        f_type: 'confirm',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        tableSpinner(true, 'propertiesTable')
        Datatable(dt, (result) => {
            tableSpinner(false, 'propertiesTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("updateOrderReviews").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            setFormfield('')
        });
        

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const editOrderStatus = (elements) => {
        setFormfield(prevState => ({
            ...prevState,
            ...elements
        }))
    }


    /*Order Review Approved Functionality*/ 
    const setOrderStatus = (e) => {
        let formdata = new FormData(document.getElementById('updateOrderReviews'));
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.review_id !== null || formfield.review_id !== '') {
                fetchData(UPDATE_ORDER_REVIEW + '/' + formfield.review_id, 'POST', { 
                    is_accepted: "1",
                    review_message: formdata.get('review_message'), 
                    review_stars: formdata.get('review_stars') 
                }, true, false, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#updateOrderReviews [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                        localStorage.setItem('isnotify', 'true'); /*Update Notifications*/
                    } else {
                        toastNotify('danger', res.message)
                    }
                    setTinyloader(false);
                }, (err) => { });
            }
        }
    }
 
    const StarRating = (e, id) => {
        document.getElementById(id).style.width = Number(e.target.value * 20) + '%'
    }

    /* Delet Order Review Functionality */
    const deleteOrderStatus = (getid) => {
        setUpdatetable(true);
        if (getid !== null || getid !== '') {
            fetchData(DELETE_ORDER_REVIEW + '/' + getid, 'POST', '', true, false, (res) => {
                if (res.status) {
                    toastNotify('success', res.message)
                    document.querySelector('#updateOrderReviews [data-bs-dismiss="offcanvas"]').click();
                    setUpdatetable(false);
                } else {
                    toastNotify('danger', res.message)
                }
            }, (err) => { });
        }
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Order Review Confirmation</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="propertiesTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th data-orderby="asc" data-sortby="order_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Order Code
                                                </th>
                                                <th data-orderby="asc" data-sortby="product_name" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Product Name
                                                </th>
                                                <th data-orderby="asc" data-sortby="review_message" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Review Message
                                                </th>                                                
                                                <th width="200" data-orderby="asc" data-sortby="review_stars" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Review Stars
                                                </th>
                                                <th width="150" className="text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.order_code}</td>
                                                    <td>{ele.product_name}</td>
                                                    <td>{ele.review_message}</td>
                                                    <td>
                                                        <div className="star-rating">
                                                            <span style={{width:Number(ele.review_stars * 20)+'%'}}></span>
                                                        </div>                                                        
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#updateOrderReviews" onClick={() => editOrderStatus(ele)}>Action</button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={5}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="updateOrderReviews" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">Update Review</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Order ID<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="order_code" defaultValue={formfield.order_code} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="product_name" defaultValue={formfield.product_name} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Review Message<strong className="text-danger">*</strong></div>
                        <textarea className="form-control" name="review_message" rows={6} defaultValue={formfield.review_message} required></textarea>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Review Stars<strong className="text-danger">*</strong></div>
                        <input type="range" className="w-100" name="review_stars" id="review_stars" onChange={(e) => StarRating(e, 'sr2')} defaultValue={formfield.review_stars} min={1} max="5" step="1" />
                        <div className="star-rating"><span id="sr2" style={{ width: (formfield.review_stars*20)+'%' }}></span></div>
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light text-center text-sm-start p-3 p-sm-4">
                    <button type="button" className="btn btn-success rounded-pill minw-140 fw-medium me-2" onClick={(e) => setOrderStatus(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Approve'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-danger rounded-pill px-4 me-2" onClick={() => deleteOrderStatus(formfield.review_id)}>Delete</button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
        </>
    )
}

export default OrderReviewConfirmation