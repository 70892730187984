import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { fetchFrontData, FOOTER_SECTION, SITE_INFO, ASSET_ENDPOINT } from "./Service";
import { noimageHandle } from "./Helper";

function FrontendFooter() {

    const[langType, setLangType] = useState(localStorage.getItem('lang_type'))
    const [data, setData] = useState({
        link_about: "",
        link_advertise: "",
        link_contact: "",
        link_home: "",
        link_login: "",
        link_privacy: "",
        link_terms_conds: "",
        site_bottom: "",
        site_icon: "",
        site_logo: "",
        site_logo_alt: "",
        site_logo_alt2: "",
        site_tagline: "",
        site_title: "",

        site_clock: "",
        site_email: "",
        site_footnote: "",
        site_location: "",
        site_map: "",
        site_phone: "",
        url_facebook: "",
        url_instagram: "",
        url_twitter: "",
        
    })

    const footerData = () => {

        fetchFrontData(FOOTER_SECTION, 'GET', '', (res) => {
            if(res.status){
                setData(prevState => ({
                    ...prevState,
                    site_clock: res.records.site_clock,
                    site_email: res.records.site_email,
                    site_footnote: res.records.site_footnote,
                    site_location: res.records.site_location,
                    site_map: res.records.site_map,
                    site_phone: res.records.site_phone,
                    url_facebook: res.records.url_facebook,
                    url_instagram: res.records.url_instagram,
                    url_twitter: res.records.url_twitter,
                }))

                
                
            }
        }, (err) => {})

        fetchFrontData(SITE_INFO, 'GET', '', (res) => {
            if(res.status){
                setData(prevState => ({
                    ...prevState,
                    link_about: res.records.link_about,
                    link_advertise: res.records.link_advertise,
                    link_contact: res.records.link_contact,
                    link_home: res.records.link_home,
                    link_login: res.records.link_login,
                    link_privacy: res.records.link_privacy,
                    link_terms_conds: res.records.link_terms_conds,
                    site_bottom: res.records.site_bottom,
                    site_icon: res.records.site_icon,
                    site_logo: res.records.site_logo,
                    site_logo_alt: res.records.site_logo_alt,
                    site_logo_alt2: res.records.site_logo_alt2,
                    site_tagline: res.records.site_tagline,
                    site_title: res.records.site_title,
                }))
            }
        }, (err) => {})
    }

    useEffect(() => {
        
        footerData()
        document.querySelectorAll("li.languageChange").forEach((e)=>{
            e.addEventListener('click', () => {
                setTimeout(() => {
                    footerData()
                    setLangType(localStorage.getItem('lang_type') ? localStorage.getItem('lang_type') : 'en')
                }, 50)
            })
        });


    }, [])
    
    return (
        <>
            <footer className={`${langType === 'ur' || langType === 'ar' ? ' direction-rtl' : ''}`} style={{background: '#004f7f url(../images/footer-bg.png)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-3 mb-5">
                            <NavLink to="/" className="footer-logo">
                                <img src="images/footer_logo.png" className="w-100 rounded" alt={ASSET_ENDPOINT + data.site_logo_alt} />
                            </NavLink>
                            <div className="footer-para" dangerouslySetInnerHTML={{__html: data.site_footnote}}></div>
                            
                            <div className="footer-social">
                                <a href={data.url_facebook} title="Facebook">
                                    <svg className="icon" role="img">
                                        <use href="#icon_facebook" />
                                    </svg>
                                </a>
                                <a href={data.url_twitter} title="Twitter">
                                    <svg className="icon" role="img">
                                        <use href="#icon_twitter" />
                                    </svg>
                                </a>
                                <a href={data.url_instagram} title="Instagram">
                                    <svg className="icon" role="img">
                                        <use href="#icon_instagram" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 ps-lg-4 mb-5">
                            <h3>Quick Link</h3>
                            <ul className="footer-links">
                                {/* <li><NavLink className="nav-link p-0" to="/">{data.link_home}</NavLink></li> */}
                                <li><NavLink className="nav-link p-0" to="about-us">{data.link_about}</NavLink></li>
                                <li><NavLink className="nav-link p-0" to="contact-us">{data.link_contact}</NavLink></li>
                                <li><NavLink className="nav-link p-0" to="privacy">{data.link_privacy}</NavLink></li>
                                <li><NavLink className="nav-link p-0" to="terms">{data.link_terms_conds}</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-5">
                            <h3>Contact Us</h3>
                            <ul className="footer-links">
                                <li>
                                    <a href={`mailto:${data.site_email}`}>
                                        <svg className="icon me-2" role="img">
                                            <use href="#icon_mail" />
                                        </svg>
                                        {data.site_email}
                                    </a>
                                </li>
                                <li>
                                    <svg className="icon me-2" role="img">
                                        <use href="#icon_clock" />
                                    </svg>
                                    {data.site_clock}
                                </li>
                                <li>
                                    <a href={`tel:${data.site_phone}`}>
                                        <svg className="icon me-2" role="img">
                                            <use href="#icon_call" />
                                        </svg>
                                        {data.site_phone}
                                    </a>
                                </li>
                                <li>
                                    <a href={data.site_map}>
                                        <svg className="icon me-2" role="img">
                                            <use href="#icon_location" />
                                        </svg>
                                        {data.site_location}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-5">
                            <h3>Payment Method</h3>
                            <img src="images/visa.png" className="h-30 mt-2 me-2" alt='visa' onError={noimageHandle} />
                            <img src="images/mastercard.png" className="h-30 mt-2" alt='mastercard' onError={noimageHandle} />
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    {data.site_bottom}
                </div>
            </footer>

        </>
    )
}
export default FrontendFooter;