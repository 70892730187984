import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage(){
    window.document.title = "404 Error | Page not found!";
    const navigate = useNavigate();
    useEffect(()=>{
        
    });
    
    return(
        <>
            <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center delay-half-second">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 text-center">
                            <h1 className="display-1 fw-bold text-danger">404 Error</h1>
                            <h2>Page Not Found!</h2>
                            <hr className="maxw-180 mx-auto"/>
                            <div className="fs-4 py-3 mb-3 text-secondary">
                                <div>This link is broken or unidentified...</div>
                                <div>Please go to home page...</div>
                            </div>
                            <button type="button" className="btn btn-lg btn-primary px-4 fw-medium" onClick={() => navigate('/')}>
                                Go to Home
                            </button>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    )
}
export default ErrorPage