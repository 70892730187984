import React, { useState, useEffect } from "react";
import { toastNotify, validateForm, noimageHandle, blobToBase64, loadingData } from "../../../components/Helper";
import { fetchData, ASSET_ENDPOINT, FEATURE_HOME } from "../../../components/Service";

function Feature() {
    window.document.title = "Feature Section";

    const [langType, setLangType] = useState('en');
    const [data, setData] = useState('');
    const [tinyloader, setTinyloader] = useState(false);
    const [img, setImg] = useState()

    const handleInputChange = async (e, type) => {

        let imgb64 = await blobToBase64(e.target.files[0])
        setImg(prevState => ({
            ...prevState,
            [type]: imgb64
        }))
         
    }

    useEffect(() => {
        loadingData(true)
        fetchData(FEATURE_HOME + `?lang_type=${langType}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            setData(res.records) 
        }, (err) => {})
    }, [langType])

    const updateFeatureData = async (e) => {
        let formData = new FormData(document.getElementById('updateFeatureSection'));
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(FEATURE_HOME +`?lang_type=${langType}`, 'POST', formData, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateFeatureSection').reset()
        setLangType(lang)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Feature Section</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateFeatureSection" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                             
                                <div className="bg-white p-3 p-sm-4 rounded-10" id="englishbox" role="tabpanel">
                                    <div className="row my-3">
                                        <label className="col-md-3 text-md-end col-form-label">Main Title:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="main_title" id="main_title" spellCheck="false" defaultValue={ data[0] && data[0].feature_main_title ? data[0].feature_main_title : '' } required />
                                        </div>
                                    </div>

                                    <div className="row g-4 row-cols-1 row-cols-md-2" dir={langType !== 'en' ? 'rtl' : ''}>
                                    { data ? data.map((item, key) => {
                                        return (<div className="col">
                                            <div className="bg-blue-light-15 rounded-10 p-3 p-xl-4">
                                                <label className="image-uploading d-flex align-items-center mb-3">
                                                    <img src={img && img[`feature_image_${key + 1}`] ? img[`feature_image_${key + 1}`] : ASSET_ENDPOINT + '/' + item.feature_image} alt="Avatar" className="bg-white" onError={noimageHandle} />
                                                    <button type="button" className="btn btn-sm mx-3 btn-primary text-nowrap">Background Image/Icon #{key + 1}</button>
                                                    <input type="file" accept="image/*" name={`feature_image_${key + 1}`} id={`feature_image_${key + 1}`} title="Upload Image" onChange={(e) => handleInputChange(e,`feature_image_${key + 1}`)} />
                                                    <div className="invalid-feedback">Required to upload image.</div>
                                                </label>
                                                <div className="mb-3">
                                                    <label className="mb-1">Title #{key + 1}:<strong className="text-danger">*</strong></label>
                                                    <input type="text" className="form-control" name={`feature_title_${key + 1}`} id={`feature_title_${key + 1}`} defaultValue={item.cms__feature_title} />
                                                </div>
                                                <div className="mb-0">a
                                                    <label className="mb-1">Body #{key + 1}:<strong className="text-danger">*</strong></label>
                                                    <textarea className="form-control" rows="4" name={`feature_body_${key + 1}`} id={`feature_body_${key + 1}`} defaultValue={item.cms__feature_body}></textarea>
                                                </div>
                                            </div>
                                        </div>)
                                    }) : '' }
                                    </div>
                                    <div className="row mt-3 py-3">
                                        <div className="col-md-12 text-center">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateFeatureData(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                    :
                                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Feature;
