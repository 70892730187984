import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom";
import "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Routing from './components/Routing';
import Icons from './components/Icons';
import reportWebVitals from './reportWebVitals';
ReactDOM.render(
    <React.StrictMode>
        <Router >
            <Routing />
        </Router>
        <Icons />
    </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();