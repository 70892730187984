import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, ConfirmationModal, tableSpinner, loadingData, intlTel_phone, validatePhone, setNumber } from "../../components/Helper";
import { fetchData, STAFF_LIST, CREATE_STAFF, UPDATE_STAFF, DELETE_STAFF, ASSET_ENDPOINT } from "../../components/Service";

function Staff() {
    window.document.title = "Staff Data";
    const [updatetable, setUpdatetable] = useState(true);
    const [tinyloader, setTinyloader] = useState(false);
    const [intlTel, setIntlTel] = useState()
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Staff")
    const [formfield, setFormfield] = useState({
        staff_id: "",
        staff_uid: "",
        staff_name: "",
        staff_phone: "",
        staff_birthdate: "",
        role: "",
        staff_profile_photo: "/",
        is_active: 1,
        staff_imageb64: ""
    })


    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: STAFF_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc'
    })

    useEffect(() => {
        loadingData(true)
        setIntlTel(intlTel_phone('#phone')) 
        tableSpinner(true, 'staffTable')
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            tableSpinner(false, 'staffTable')
        });

        document.getElementById("addNewStaff").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            if(document.getElementById("phone-intl-error")){
                document.getElementById("phone-intl-error").remove()
            }
            document.getElementById("phone").classList.remove('is-invalid')
            setOffcanvastitle("Add New Staff");
            setFormfield({ /*Set empty state*/
                staff_id: "",
                role: "",
                is_active: 1,
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/


    const addStaffMethod = async (e) => {
        let formdata = new FormData(document.getElementById('addNewStaff'));
        formdata.append('staff_phone', intlTel.getNumber())
        let staffid = document.querySelector('#addNewStaff [name="staff_id"]').value;
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            setTinyloader(true);
            setUpdatetable(true);
            if (staffid === null || staffid === '') {
                fetchData(CREATE_STAFF, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#addNewStaff [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        toastNotify('danger', res.message)
                    }
                    setTinyloader(false);
                }, (err) => { });
            } else {
                fetchData(UPDATE_STAFF + '/' + staffid, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);

                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('#addNewStaff [data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    }
                    else {
                        if(Object.keys(res.errors).length>0){
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    document.querySelector(`[name="${v}"]`).value = "";
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        }else{
                            toastNotify('danger', res.message)
                        }
                    }
                    
                }, (err) => { });
            }
        }
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            staff_imageb64: imgb64
        }))
    }

    const editForm = (getid) => {
        loadingData(true);
        setOffcanvastitle("Update Staff");
        fetchData(UPDATE_STAFF + '/' + getid, 'GET', '', true, false, (res) => {
            if (res.status) {
                new Offcanvas(document.getElementById('addNewStaff')).show();
                setFormfield(prevState => ({
                    ...prevState,
                    ...res.records
                }))
                setNumber(intlTel)
                loadingData(false);
            }
            else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }

    const deleteRecords = (id) => {
        setUpdatetable(true);
        fetchData(DELETE_STAFF + '/' + id, 'GET', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Staff Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false" />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            <li>
                                                <button type="button" title="5" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(5, 'perpage')}>5</button>
                                            </li>
                                            <li>
                                                <button type="button" title="10" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(10, 'perpage')}>10</button>
                                            </li>
                                            <li>
                                                <button type="button" title="15" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(15, 'perpage')}>15</button>
                                            </li>
                                            <li>
                                                <button type="button" title="20" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(20, 'perpage')}>20</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addNewStaff">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="staffTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th width="80">Photo</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="staff_uid" onClick={(e) => dtActions(e, 'sortby')}>Username</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="staff_name" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="staff_phone" onClick={(e) => dtActions(e, 'sortby')}>Phone</th>
                                                <th data-orderby="asc" data-sortby="staff_birthdate" onClick={(e) => dtActions(e, 'sortby')}>Birthdate</th>
                                                <th data-orderby="asc" data-sortby="role" onClick={(e) => dtActions(e, 'sortby')}>Role</th>
                                                <th data-orderby="asc" data-sortby="is_active" width="130" onClick={(e) => dtActions(e, 'sortby')}>Status</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>
                                                        <img src={ASSET_ENDPOINT + ele.staff_profile_photo} alt={"adsimage"} className="wh-40 rounded-pill shadow-sm objfit-cover bg-light" onError={noimageHandle} />
                                                    </td>
                                                    <td>{ele.staff_uid}</td>
                                                    <td>{ele.staff_name}</td>
                                                    <td>{ele.staff_phone}</td>
                                                    <td>{ele.staff_birthdate}</td>
                                                    <td>
                                                        {
                                                            ele.role === 'adm' ? 'Admin' : ele.role === 'fin' ? 'Finance' : 'Staff'
                                                        }
                                                    </td>
                                                    <td>
                                                        {ele.is_active === 1 ? 'Active' :'Disabled'}
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => editForm(ele.staff_id)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, staff_id: ele.staff_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                                :
                                                records === 0 ?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addNewStaff" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <input type="text" className="d-none" name="staff_id" defaultValue={formfield.staff_id} />
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.staff_imageb64 ? ASSET_ENDPOINT + '/' + formfield.staff_profile_photo : formfield.staff_imageb64} alt="ads_image" className="me-3" onError={noimageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap">Upload Image</button>
                        <input type="file" accept="image/*" className="form-field" name="staff_profile_photo" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState,
                            staff_imageb64: imageSet(e),
                        }))} required={offcanvastitle === "Add New Staff" ? true : false} />
                        <div className="invalid-feedback">
                            Required to upload image.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Username<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control form-field" name="staff_uid" defaultValue={formfield.staff_uid} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, staff_uid: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">
                            Password<strong className="text-danger">{offcanvastitle === "Add New Staff" ? '*' : null}</strong>
                        </div>
                        <input type="password" className="form-control form-field" name="staff_password" defaultValue={""} minLength="8" required={offcanvastitle === "Add New Staff" ? true : false} />
                        <div className="invalid-feedback">
                            The password field must be at least 8 characters.
                        </div>
                        {offcanvastitle !== "Add New Staff" ? <div className="text-success small">Leave it blank to keep the old password.</div> : null}
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Full Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control form-field" name="staff_name" defaultValue={formfield.staff_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, staff_name: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Phone<strong className="text-danger">*</strong></div>
                        <input type="tel" className="form-control form-field" id="phone" name="staff_phone" data-intl-tel-input-id="0" defaultValue={formfield.staff_phone} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, staff_phone: e.target.value
                        }))} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Birth Date<strong className="text-danger">*</strong></div>
                        <input type="date" className="form-control form-field" name="staff_birthdate" defaultValue={formfield.staff_birthdate} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, staff_birthdate: e.target.value
                        }))} max="2022-12-01" required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Role<strong className="text-danger">*</strong></div>
                        <select className="form-select" name="role" value={
                            formfield.role==="adm" ? "adm" : (formfield.role==="fin"? "fin": "stf")
                        } onChange={(e) => setFormfield(prevState => ({
                            ...prevState, role: e.target.value
                        }))} required>
                            <option value="adm">Admin</option>
                            <option value="fin">Finance</option>
                            <option value="stf">Staff</option>
                        </select>
                    </label>
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Status</div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="is_active" type="checkbox" defaultValue={formfield.is_active} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_active: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_active === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Active</div>
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => addStaffMethod(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.staff_id)} />
        </>
    )
}

export default Staff