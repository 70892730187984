import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, LANGUAGE_STRING, LANGUAGE_STRING_ADD, LANGUAGE_STRING_UPDATE } from "../../components/Service";

function LanguageString() {
    window.document.title = "Language String";
    const [updatetable, setUpdatetable] = useState(true);
    const [tinyloader, setTinyloader] = useState(false);
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Language String")
    const [formfield, setFormfield] = useState({
        updater_key: "",
        key: "",
        english: "",
        arabic: "",
        urdu: ""
    })


    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: LANGUAGE_STRING,
        search: '',
        perpage: '10',
        columns: 'code,key,words',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'langTable')
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            tableSpinner(false, 'langTable')
        });

        document.getElementById("addLangString").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Language String");
            document.getElementById('key').readOnly = false
            setFormfield({ /*Set empty state*/
                updater_key: "",
                key: "",
                english: "",
                arabic: "",
                urdu: ""
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const addLangStringMethod = async (e) => {
        let formdata = new FormData(document.getElementById('addLangString'));
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.updater_key === "" || formfield.updater_key === null) {
                fetchData(LANGUAGE_STRING_ADD, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#addLangString [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        toastNotify('danger', res.message)
                    }
                    setTinyloader(false);
                }, (err) => { });
            } else {
                fetchData(LANGUAGE_STRING_UPDATE + '/' + formfield.key, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('#addLangString [data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    }
                    else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                let setkey = Object.keys(ele)
                                document.querySelector(`[name="${setkey}"]`).value = "";
                                toastNotify('danger', ele[setkey][0])
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }
        }
    }

    const editForm = (getid) => {
        loadingData(true);
        document.getElementById('key').readOnly = true
        setOffcanvastitle("Update Language String");
        setFormfield(prevState => ({
            ...prevState,
            updater_key: getid
        }))

        fetchData(LANGUAGE_STRING_UPDATE + '/' + getid, 'GET', '', true, false, (res) => {
            setFormfield(prevState => ({
                ...prevState,
                key: getid
            }))
            if (res.status) {
                new Offcanvas(document.getElementById('addLangString')).show();
                res.records.forEach((elem)=>{
                    if(elem.code === 'en'){
                        setFormfield(prevState => ({
                            ...prevState,
                            english: elem.words
                        }))
                    }
                    if (elem.code === 'ar') {
                        setFormfield(prevState => ({
                            ...prevState,
                            arabic: elem.words
                        }))
                    }
                    if (elem.code === 'ur') {
                        setFormfield(prevState => ({
                            ...prevState,
                            urdu: elem.words
                        }))
                    }
                })                
                loadingData(false);
            }
            else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Language String</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false" />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addLangString">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="langTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th width="80" data-orderby="asc" data-sortby="key" onClick={(e) => dtActions(e, 'sortby')}>Key</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="en_words" onClick={(e) => dtActions(e, 'sortby')}>English</th>
                                                <th className="minw-200 text-end" data-orderby="asc" data-sortby="ar_words" onClick={(e) => dtActions(e, 'sortby')}>Arabic</th>
                                                <th className="minw-200 text-end" data-orderby="asc" data-sortby="ur_words" onClick={(e) => dtActions(e, 'sortby')}>Urdu</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.key}</td>
                                                    <td>
                                                        <div className="maxw-200 text-intwolines">
                                                            {ele.en_words}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="maxw-200 text-intwolines" dir="rtl">
                                                            {ele.ar_words}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="maxw-200 text-intwolines" dir="rtl">
                                                            {ele.ur_words}
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => editForm(ele.key)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                                :
                                                records === 0 ?
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                                No Record Found
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addLangString" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <input type="text" className="d-none" name="id" defaultValue={formfield.updater_key} />
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Key<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" id="key" name="key" spellCheck="false" defaultValue={formfield.key} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, key: e.target.value
                        }))} autoComplete="off" required />
                        <div className="invalid-feedback">
                            The key field is required.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">In English</div>
                        <textarea className="form-control" name="english" rows={4} defaultValue={formfield.english} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, english: e.target.value
                        }))}></textarea>
                    </label>
                    <label className="w-100 mb-3 ">
                        <div className="mb-1 fw-medium text-blue small">In Arabic</div>
                        <textarea className="form-control" name="arabic" spellCheck="false" rows={4} defaultValue={formfield.arabic} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, arabic: e.target.value
                        }))} dir="rtl"></textarea>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">In Urdu</div>
                        <textarea className="form-control" name="urdu" spellCheck="false" rows={4} defaultValue={formfield.urdu} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, urdu: e.target.value
                        }))} dir="rtl"></textarea>
                    </label>
                </div>
                <div className="offcanvas-footer border-top text-center text-sm-start bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => addLangStringMethod(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
        </>
    )
}

export default LanguageString