import React, { useEffect, useState } from "react";

function TopHeader(props){

    const [title, setTitle] = useState('')

    const pageName = () => {
        document.querySelectorAll("ul.footer-links li").forEach((e)=>{
            if(e.querySelector('a.active')){
                setTitle(e.querySelector('a.active').innerText)
            }
        });
        document.querySelectorAll("#navbarSupportedContent ul li").forEach((e)=>{
            if(e.querySelector('a.active')){
                setTitle(e.querySelector('a.active').innerText)
            }
        });
    }

    useEffect(() => {
        document.querySelectorAll("li.languageChange").forEach((e)=>{
            e.addEventListener('click', () => {
                setTitle('')
                setTimeout(() => {
                    if(props.reload){
                        props.refresh(localStorage.getItem('lang_type'))
                    }
                    pageName()
                }, 400)
            })
        });

        setTimeout(() => {
            pageName()
        }, 400)

        

    }, [props])
    return (
        <section className="pageheading-bar">
            <img src="images/aboutus-banner.jpg" className="opacity-50" alt="banner" />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopHeader