import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, ConfirmationModal, loadingData, tableSpinner } from "../../components/Helper";
import { fetchData, PROPERTY_LIST, PROPERTY_CREATE, PROPERTY_UPDATE, PROPERTY_DELETE } from "../../components/Service";

function CategoryProperties() {
    window.document.title = "Properties";
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Category Properties")
    const [formfield, setFormfield] = useState({
        props_updater: "",
        props_code: "props_",
        english_name: "",
        arabic_name: "",
        urdu_name: "",
        props_input_type: "",
        props_options: "",
    })

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: PROPERTY_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc'
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'propertiesTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'propertiesTable');
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });
        document.getElementById("addupdateCategoryProps").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Category Properties");
            setFormfield({ // Set empty state
                props_updater: "",
                props_code: "props_",
                english_name: "",
                arabic_name: "",
                urdu_name: "",
                props_input_type: "",
                props_options: "",
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const adsFunction = async (e) => {
        let formdata = new FormData(document.getElementById('addupdateCategoryProps'));
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.props_updater === null || formfield.props_updater===''){
                fetchData(PROPERTY_CREATE, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#addupdateCategoryProps [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }else{
                fetchData(PROPERTY_UPDATE + '/' + formfield.props_updater, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('#addupdateCategoryProps [data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                    setTinyloader(false);
                }, (err) => { });
            }
        }
    }

    const editForm = (getcode) => {
        loadingData(true);
        fetchData(PROPERTY_UPDATE + '/' + getcode, 'GET', '', true, false, (res) => {
            loadingData(false);
            if (res.status) {
                let langSetEng = [];
                let langSetArb = [];
                let langSetUrd = [];
                if(res.records.language_data.length>0){
                    res.records.language_data.map((a)=>{
                        if (a.code === 'en'){
                            langSetEng.push(a.words);
                        }
                        if (a.code === 'ar') {
                            langSetArb.push(a.words);
                        }
                        if (a.code === 'ur') {
                            langSetUrd.push(a.words);
                        }
                    })
                }

                setFormfield(prevState => ({
                    ...prevState,
                    props_updater: res.records.props_code,
                    props_code: res.records.props_code,
                    props_input_type: res.records.props_input_type,
                    english_name: langSetEng,
                    arabic_name: langSetArb, 
                    urdu_name: langSetUrd,
                    props_options: res.records.props_options
                }))
                new Offcanvas(document.getElementById('addupdateCategoryProps')).show();
                setOffcanvastitle("Update Category Properties");
            } else {
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.message)
                }
            }
        }, (err) => { });
    }

    const deleteRecords = (id) =>{
        setUpdatetable(true);
        fetchData(PROPERTY_DELETE+'/'+id, 'GET', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Properties</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            <li>
                                                <button type="button" title="5" className="dropdown-item rounded-20 text-blue py-2"
                                                    onClick={() => dtActions(5, 'perpage')}>5</button>
                                            </li>
                                            <li>
                                                <button type="button" title="10" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(10, 'perpage')}>10</button>
                                            </li>
                                            <li>
                                                <button type="button" title="15" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(15, 'perpage')}>15</button>
                                            </li>
                                            <li>
                                                <button type="button" title="20" className="dropdown-item rounded-20 text-blue py-2" 
                                                    onClick={() => dtActions(20, 'perpage')}>20</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#addupdateCategoryProps">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="propertiesTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th width="250" data-orderby="asc" data-sortby="props_code" onClick={(e) => dtActions(e, 'sortby')}>Code</th>
                                                <th data-orderby="asc" data-sortby="props_code" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th width="140" data-orderby="asc" data-sortby="props_input_type" onClick={(e) => dtActions(e, 'sortby')}>Input</th>
                                                <th data-orderby="asc" data-sortby="props_options" onClick={(e) => dtActions(e, 'sortby')}>Options</th>
                                                
                                                <th width="140">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.props_code}</td>
                                                    <td>{ele.title}</td>
                                                    <td>{ele.props_input_type}</td>
                                                    <td>{ele.props_options!==null?(ele.props_options).join(', '):null}</td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => editForm(ele.props_code)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" data-bs-toggle="modal" data-bs-target="#confModal" title="Delete" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, props_code: ele.props_code
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addupdateCategoryProps" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Props Code<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="props_code" placeholder="props_" defaultValue={formfield.props_code} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, props_code: e.target.value
                        }))} autoComplete="off" spellCheck="false" pattern="props_[a-zA-Z0-9_]+" required/>
                        <div className="invalid-feedback">
                            Code must be started with <strong>"props_"</strong> and should not be any space.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Props Name (English)<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="english_name" placeholder="" defaultValue={formfield.english_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, english_name: e.target.value
                        }))} autoComplete="off" required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Props Name (Arabic)</div>
                        <input type="text" className="form-control form-field" name="arabic_name" placeholder="" defaultValue={formfield.arabic_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, arabic_name: e.target.value
                        }))} autoComplete="off" dir="rtl"/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Props Name (Urdu)</div>
                        <input type="text" className="form-control" name="urdu_name" placeholder="" defaultValue={formfield.urdu_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, urdu_name: e.target.value
                        }))} autoComplete="off" dir="rtl" />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Input Type</div>
                        <select className="form-select" name="props_input_type" value={formfield.props_input_type} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, props_input_type: e.target.value
                        }))}>
                            <option value="text">Text</option>
                            <option value="number">Number</option>
                            <option value="select">Select</option>
                            <option value="date">Date</option>
                        </select>
                    </label>
                    {formfield.props_input_type==='select' && (
                        <label className="w-100 mb-3">
                            <div className="mb-1 fw-medium text-blue small">Props Options</div>
                            <textarea className="form-control" name="props_options" spellCheck={false} rows={3} defaultValue={formfield.props_options} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, props_options: e.target.value
                            }))} placeholder="props_option_1, props_option_2, etc..." required></textarea>
                            <ol className="small ps-3 py-1 opacity-75">
                                <li>Separate options with comma.</li>
                                <li>Use Language String feature if the options are in multiple languages</li>
                            </ol>
                        </label>)}
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => adsFunction(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>
            
            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.props_code)} />
        </>
    )
}

export default CategoryProperties