import React, { useState, useEffect } from "react";
import { toastNotify, validateForm, loadingData } from "../../../components/Helper";
import { fetchData, INSTAGRAM_HOME } from "../../../components/Service";

function Instagram() {
    window.document.title = "Instagram Section";

    const [langType, setLangType] = useState('en');
    const [data, setData] = useState('');
    const [tinyloader, setTinyloader] = useState(false);

    const handleInputChange = async (e) => { 
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        })) 
    }

    useEffect(() => {
        loadingData(true)
        fetchData(INSTAGRAM_HOME +`?lang_type=${langType}`, 'GET', '', true, false, (res) => {
            loadingData(false)
            setData(res.records)
        }, (err) => {})
    }, [langType])

    const updateInstagramData = async (e) => {
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(INSTAGRAM_HOME +`?lang_type=${langType}`, 'POST', data, true, false, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateInstagramSection').reset()
        setLangType(lang)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Instagram Section</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateInstagramSection" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                               
                                <div className="bg-white p-3 rounded-10" id="englishbox" role="tabpanel">
                                    <div className="row my-3">
                                        <label className="col-md-3 text-md-end col-form-label">Title:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="instagram_title" id="instagram_title" spellCheck="false" defaultValue={data.instagram_title} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Subtitle:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <textarea className="form-control" rows="6" name="instagram_subtitle" id="instagram_subtitle" defaultValue={data.instagram_subtitle} required></textarea>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Token:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7">
                                            <input type="text" className="form-control" name="instagram_token" id="instagram_token" defaultValue={data.instagram_token} onChange={handleInputChange} required />
                                            <div className="fst-italic small opacity-75 fw-medium mt-1">This should be renewed every 60 days, via developers.facebook.com</div>
                                        </div>
                                    </div>
                                    
                                    <div className="row mt-3 py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateInstagramData(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                    :
                                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Instagram;
