import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { toastNotify, validateForm, noimageHandle, blobToBase64, loadingData } from "../../../components/Helper";
import { fetchData, ASSET_ENDPOINT, UPDATE_ADVERTISE } from "../../../components/Service";

function Advertise() {
    window.document.title = "Advertise Page Settings";

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [langType, setLangType] = useState('en');
    const [tinyloader, setTinyloader] = useState(false); 
    const [record, setRecord] = useState({
        ads_imageb64 : ''
    },'');

    const handleInputChange = async (e, type) => {
        if (type === 'ads_image') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setRecord(prevState => ({
                ...prevState,
                ads_imageb64: imgb64
            }));
        }  
        setRecord(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
       
    }

    useEffect(() => {
        loadingData(true)
        fetchData(UPDATE_ADVERTISE +`?lang_type=${langType}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            if(res.status){
                setRecord(res.records)
            }
            const parser = new DOMParser();
            const doc = parser.parseFromString(res.records.cms__ads_text, 'text/html');
            const contentBlock = htmlToDraft(doc.body.innerText);
            const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock));
            setEditorState(editorState)

        }, (err) => { })
    }, [langType])

    const updateAdvertiseData = async (e) => {
        let formData = new FormData(document.getElementById('updateAdvertiseData'));
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(UPDATE_ADVERTISE +`?lang_type=${langType}`, 'POST',formData, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateAdvertiseData').reset()
        setLangType(lang)
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Advertise Page Settings</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateAdvertiseData" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                              
                                <div className="bg-white p-3 rounded-10" id="englishbox" role="tabpanel">
                                    <div className="row my-3">
                                        <label className="col-md-3 text-md-end col-form-label">Title Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_title" id="ads_title" spellCheck="false" defaultValue={record.cms__ads_title} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Name Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_name" id="ads_name" spellCheck="false" defaultValue={record.cms__ads_name} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Email Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_email" id="ads_email" spellCheck="false" defaultValue={record.cms__ads_email} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Message Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_message" id="ads_message" spellCheck="false" defaultValue={record.cms__ads_message} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Submit Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_submit" id="ads_submit" spellCheck="false" defaultValue={record.cms__ads_submit} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">&nbsp;</label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <textarea className="form-control d-none" name="ads_text" id="ads_text" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} readOnly required></textarea>
                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={(e) => onEditorStateChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Success Alert Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_success" id="ads_success" defaultValue={record.cms__ads_success} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-md-3 text-md-end col-form-label">Error Alert Text:<strong className="text-danger">*</strong></label>
                                        <div className="col-md-7" dir={langType !== 'en' ? 'rtl' : ''}>
                                            <input type="text" className="form-control" name="ads_error" id="ads_error" defaultValue={record.cms__ads_error} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className="row py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <h6 className="text-blue">Ilustration *</h6>
                                            <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="offset-md-3 col-md-7">
                                            <div className="row g-4 text-start">
                                                <div className="col">
                                                    <div className="d-block">
                                                        <label htmlFor="ads_image" className="image-uploading d-inline-block">
                                                            <img src={!record.ads_imageb64 ? ASSET_ENDPOINT + '/' + record.ads_image : record.ads_imageb64} alt="ads_image" onError={noimageHandle}/>
                                                        </label>
                                                        <input type="file" accept="image/*" className="d-none" name="ads_image" title="Upload Image" id="ads_image" onChange={(e) => handleInputChange(e,'ads_image')} />
                                                        <div className="invalid-feedback">Image Required.</div>
                                                    </div>
                                                    <label htmlFor="ads_image" className="btn btn-sm btn-info minw-120 text-nowrap mt-2">Upload Ilustration</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3 py-3">
                                        <div className="offset-md-3 col-md-7">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateAdvertiseData(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                    :
                                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Advertise;
