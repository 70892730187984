import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { toastNotify, validateForm, noimageHandle, blobToBase64, loadingData } from "../../components/Helper";
import { fetchData, ASSET_ENDPOINT, UPDATE_ABOUT_US } from "../../components/Service";

function AboutUs() {
    window.document.title = "About Us";

    const [langType, setLangType] = useState('en');
    const [data, setData] = useState('');
    const [tinyloader, setTinyloader] = useState(false);

    const [aboutEditorState, setAboutEditorState] = useState(EditorState.createEmpty());
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [missionEditorState, setMissionEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        loadingData(true)
        fetchData(UPDATE_ABOUT_US +`?lang_type=${langType}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            if (res.status) {
                setData(res.records)
            }
            const parserAbout = new DOMParser();
            const aboutDoc = parserAbout.parseFromString(res.records.cms__about_us_body_1, 'text/html');
            const aboutContentBlock = htmlToDraft(aboutDoc.body.innerText);
            const aboutContentState = ContentState.createFromBlockArray(aboutContentBlock);
            const aboutEditorStatez = EditorState.createWithContent(aboutContentState);
            setAboutEditorState(aboutEditorStatez)

            const parser = new DOMParser();
            const doc = parser.parseFromString(res.records.cms__about_us_body_2, 'text/html');
            const contentBlock = htmlToDraft(doc.body.innerText);
            const contentState = ContentState.createFromBlockArray(contentBlock);
            const editorStatez = EditorState.createWithContent(contentState);
            setEditorState(editorStatez)

            const missionParser = new DOMParser();
            const missionDoc = missionParser.parseFromString(res.records.cms__about_us_body_3, 'text/html');
            const missionContentBlock = htmlToDraft(missionDoc.body.innerText);
            const missionContentState = ContentState.createFromBlockArray(missionContentBlock);
            const missionEditorStatez = EditorState.createWithContent(missionContentState);
            setMissionEditorState(missionEditorStatez)

        }, (err) => { })

    }, [langType])

    const handleInputChange = async (e, type) => {

        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        if (type === 'about') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setData(prevState => ({
                ...prevState,
                about_us_imageb64: imgb64
            }))
        } if (type === 'vision') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setData(prevState => ({
                ...prevState,
                vision_imageb64: imgb64
            }))
        } if (type === 'mission') {
            const imgb64 = await blobToBase64(e.target.files[0])
            setData(prevState => ({
                ...prevState,
                mission_imageb64: imgb64
            }))
        }
    }

    const onAboutEditorStateChange = (aboutEditorState) => {
        setAboutEditorState(aboutEditorState)
    }
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }
    const onMissionEditorStateChange = (missionEditorState) => {
        setMissionEditorState(missionEditorState)
    }
    const updateAboutUsDataInfo = async (e) => {
        let formData = new FormData(document.getElementById('updateAboutUsData'));
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(UPDATE_ABOUT_US +`?lang_type=${langType}`, 'POST', formData, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateAboutUsData').reset()
        setLangType(lang)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">About Us Page in {langType === 'en' ? 'English' : langType === 'ar' ? 'Arabic' : 'Urdu'}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateAboutUsData" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>

                                <div className="bg-white p-3 rounded-10" role="tabpanel">
                                    <div className="row mb-3">
                                        <div className="offset-md-12 col-md-12">
                                            <h6 className="text-blue">About Us Page in {langType === 'en' ? 'English' : langType === 'ar' ? 'Arabic' : 'Urdu'} *</h6>
                                            <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                        </div>
                                    </div>
                                    {data ? <>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="col-md-3 col-form-label">About Us</label>
                                            <div className="col-md-12">
                                                <label title="Change Image">
                                                    <input type="file" accept="image/*" className="d-none" name="about_us_image" title="About Us Image" onChange={(e) => handleInputChange(e, 'about')} id="about_us_image"/>
                                                    <img src={!data.about_us_imageb64 ? ASSET_ENDPOINT + '/' + data.about_us_image_1 : data.about_us_imageb64} alt="Big Size" height="330px" width="500px" onError={noimageHandle} />

                                                </label>

                                            </div>
                                        </div>
                                        <div className="col-md-8 mt-4">
                                            <label className="col-md-3 col-form-label">Title About Us:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="cms_about_us_title" id="cms_about_us_title" defaultValue={data.cms__about_us_title_1} onChange={(e) =>handleInputChange(e)} required />
                                            </div>

                                            <label className="col-md-3 col-form-label">Headings About Us:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="cms_about_us_headings" id="cms_about_us_headings" defaultValue={data.cms__about_us_headings_1} onChange={(e) =>handleInputChange(e)} required />
                                            </div>

                                            <label className="col-md-3 col-form-label">Description About Us:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <textarea className="form-control d-none" name="cms_about_us_body" id='cms_about_us_body' rows={20} value={draftToHtml(convertToRaw(aboutEditorState.getCurrentContent()))} readOnly></textarea>
                                                <Editor
                                                    editorState={aboutEditorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName bg-white"
                                                    onEditorStateChange={(e) => onAboutEditorStateChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="col-md-3 col-form-label">Our Vision</label>
                                            <div className="col-md-12">
                                                <label title="Change Image">
                                                    <input type="file" accept="image/*" className="d-none" name="our_vision_image" title="Vision Image" onChange={(e) => handleInputChange(e, 'vision')} id="our_vision_image" />
                                                    <img src={!data.vision_imageb64 ? ASSET_ENDPOINT + '/' + data.about_us_image_2 : data.vision_imageb64} alt="Big Size" height="330px" width="500px" onError={noimageHandle} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-8 mt-4">
                                            <label className="col-md-3 col-form-label">Title Our Vision:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="cms_our_vision_title" id="cms_our_vision_title" defaultValue={data.cms__about_us_title_2} onChange={(e) =>handleInputChange(e)} required />
                                            </div>

                                            <label className="col-md-3 col-form-label">Headings Our Vision:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12">
                                                <input type="text" className="form-control" name="cms_our_vision_headings" id="cms_our_vision_headings" defaultValue={data.cms__about_us_headings_2} onChange={(e) =>handleInputChange(e)} required />
                                            </div>

                                            <label className="col-md-3 col-form-label">Description Vision:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <textarea className="form-control d-none" name="cms_our_vision_body" id='cms_our_vision_body' rows={10} value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} readOnly></textarea>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => onEditorStateChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="col-md-3 col-form-label">Our Mission</label>
                                            <div className="col-md-12">
                                                <label title="Change Image">
                                                    <input type="file" accept="image/*" className="d-none" name="our_mission_image" title="About Us Image" onChange={(e) => handleInputChange(e, 'mission')} id="our_mission_image" />
                                                    <img src={!data.mission_imageb64 ? ASSET_ENDPOINT + '/' + data.about_us_image_3 : data.mission_imageb64} alt="Big Size" height="330px" width="500px" onError={noimageHandle} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-8 mt-4">
                                            <label className="col-md-3 col-form-label">Title Our Mission:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="cms_our_mission_title" id="cms_our_mission_title" defaultValue={data.cms__about_us_title_3} onChange={(e) =>handleInputChange(e)} required />
                                            </div>

                                            <label className="col-md-3 col-form-label">Headings Our Mission:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <input type="text" className="form-control" name="cms_our_mission_headings" id="cms_our_mission_headings" defaultValue={data.cms__about_us_headings_3} onChange={(e) =>handleInputChange(e)} required />
                                            </div>

                                            <label className="col-md-3 col-form-label">Description Mission:<strong className="text-danger">*</strong></label>
                                            <div className="col-md-12">
                                                <textarea className="form-control d-none" name="cms_our_mission_body" id='cms_our_mission_body' rows={10} value={draftToHtml(convertToRaw(missionEditorState.getCurrentContent()))} readOnly></textarea>
                                                <Editor
                                                    editorState={missionEditorState}
                                                    toolbarClassName="toolbarMissionClassName"
                                                    wrapperClassName="wrapperMissionClassName"
                                                    editorClassName="editorMissionClassName"
                                                    onEditorStateChange={(e) => onMissionEditorStateChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                      </> :  <div className="spinner-border spinner-border-sm mx-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        }
                                    <div className="row">
                                        <div className="offset-md-3 col-md-12">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateAboutUsDataInfo(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                        :
                                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                }
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;