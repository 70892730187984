import React, { useState, useEffect } from "react";
import {Offcanvas} from "bootstrap";
import DateRangeFilter from '../../components/Filter';
import "react-datepicker/dist/react-datepicker.css";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, ConfirmationModal, loadingData, tableSpinner, intlTel_phone, validatePhone, setNumber } from "../../components/Helper";
import { fetchData, COMPANY_LIST, COMPANY_CATEGORY_LIST, CREATE_COMPANY, UPDATE_COMPANY, DELETE_COMPANY, ASSET_ENDPOINT, NEW_COMPANY_EXPORT, ACTIVE_COMPANY_EXPORT } from "../../components/Service";
import ComboSelect from "../../components/ComboSelect";
import { useLocation } from "react-router";


function CompanyData() {
    window.document.title = "Company Data";
    const location = useLocation()
    const [dateRange, setDateRange] = useState(['', ''])
    const [startDate, endDate] = dateRange
    const [updatetable, setUpdatetable] = useState(true);
    const [tinyloader, setTinyloader] = useState(false);
    const [intlTel, setIntlTel] = useState()

    const [offcanvastitle, setOffcanvastitle] = useState("Add New Company")
    const [formfield, setFormfield] = useState({
        company_id: "",
        company_name: "",
        company_password: "",
        company_email: "",
        company_phone: "",
        company_address: "",
        company_contact_person: "",
        company_category: "",
        company_category_list: "",
        company_category_selected: "",
        company_verification_document: "/",
        company_registration_id: "",
        is_active: 1,
        is_verified: 1,
        company_imageb64: "",
    });

    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: COMPANY_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        tableSpinner(true, 'companyTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'companyTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("addNewCompany").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            if(document.getElementById("phone-intl-error")){
                document.getElementById("phone-intl-error").remove()
            }
            if(document.getElementById("company_contact_person-intl-error")){
                document.getElementById("company_contact_person-intl-error").remove()
            }
            document.getElementById("phone").classList.remove('is-invalid')
            document.getElementById("company_contact_person").classList.remove('is-invalid')
            setOffcanvastitle("Add New Company");
            setFormfield(prevState=>({ /*Set empty state*/
                ...prevState,
                company_id: "",
                company_name: "",
                company_password: "",
                company_email: "",
                company_address: "",
                company_phone: "",
                company_verification_document: "/",
                company_category: "",
                company_category_selected: "",
                company_registration_id: "",
                company_contact_person: "",
                is_active: 1,
                is_verified: 1,
                company_imageb64: ""
            }))
        });
        
        companyCategoryList()
        setIntlTel(intlTel_phone('#phone')) 

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response)=> {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/


    const getDateRange = (startDate, endDate) => {
        setDateRange([startDate, endDate])
    }


    const companyCategoryList = () =>{
        
        loadingData(true)
        fetchData(COMPANY_CATEGORY_LIST, 'GET', '', true, true, (res) => {
            loadingData(false)
            if (res.status) {
                setFormfield(prevState => ({
                    ...prevState,
                    company_category_list: res.records
                }))
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }


    const addCompanyMethod = async (e) => {
        let formdata = new FormData(document.getElementById('addNewCompany')); 
        formdata.append('company_phone', intlTel.getNumber())
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.company_id === null || formfield.company_id === '') {
                fetchData(CREATE_COMPANY, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#addNewCompany [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => {});
            }else{
                fetchData(UPDATE_COMPANY + '/' + formfield.company_id, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('#addNewCompany [data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }
        }
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            company_imageb64: imgb64
        }))
    }

    const editForm = (getid) => {
        loadingData(true);
        setOffcanvastitle("Update Company");
        fetchData(UPDATE_COMPANY+'/'+getid, 'GET', '' , true, false, (res) => {
            loadingData(false);
            if (res.status) {
                new Offcanvas(document.getElementById('addNewCompany')).show();
                setFormfield(prevState => ({
                    ...prevState, 
                    ...res.records.company_data,
                    company_category_selected: res.records.company_category.toString()
                }))
                setNumber(intlTel)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }

    const deleteRecords = (id) => {
        setUpdatetable(true);
        fetchData(DELETE_COMPANY + '/' + id, 'GET', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }

    const exportCompany = (company) =>{
        if(company === 'New'){
            fetchData(`${NEW_COMPANY_EXPORT}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, false, (res) => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Report Company Details.xlsx`);
                document.body.appendChild(link);
                link.click();
            }, (err) => {}, "blob");
        }else if(company === 'Active'){
            fetchData(`${ACTIVE_COMPANY_EXPORT}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, false, (res) => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Report Active Company.xlsx`);
                document.body.appendChild(link);
                link.click();
            }, (err) => {}, "blob");
        }
    }

    

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Company Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto d-flex">
                                        {location.state ?
                                        <div className="input-group input-group-pill flex-nowrap">
                                            <div className="input-group-text">
                                                <svg className="icon tscale-1p3" role="img"><use href="#icon_calendar" /></svg>
                                            </div>
                                            <DateRangeFilter 
                                                daterange=""
                                                className="form-control"
                                                maxDate={new Date()}
                                                opens="left"
                                                placeholder="Select Date Range"
                                                name='d_daterange'
                                                disableUpdateInput={false}
                                                getDateRange={getDateRange}
                                            />
                                            <button type="button" className="btn btn-primary px-3 text-nowrap" onClick={() => exportCompany(location.state)}>
                                                <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_download" /></svg> 
                                                <span className="d-none d-md-inline">Export {location.state} Company</span>
                                            </button>
                                        </div>
                                        :''}
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap mx-2" data-bs-toggle="offcanvas" data-bs-target="#addNewCompany">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="companyTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_phone" onClick={(e) => dtActions(e, 'sortby')}>Phone</th>
                                                <th data-orderby="asc" data-sortby="company_email" onClick={(e) => dtActions(e, 'sortby')}>Email</th>
                                                <th data-orderby="asc" data-sortby="is_active" width="130" onClick={(e) => dtActions(e, 'sortby')}>Subscription</th>
                                                <th data-orderby="asc" data-sortby="is_active" width="130" onClick={(e) => dtActions(e, 'sortby')}>Status</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.company_name}</td>
                                                    <td>{ele.company_phone}</td>
                                                    <td>{ele.company_email}</td>
                                                    <td>
                                                        { 
                                                            ele.subscription_status && ele.subscription_status.subscription_status && ele.subscription_status.subscription_status === 1  ? 'Active' : 'Expired'
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                        ele.is_active=== 0 ?'Disabled' : 'Active'
                                                        }
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => editForm(ele.company_id)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, company_id: ele.company_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addNewCompany" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.company_imageb64 ? ASSET_ENDPOINT + '/' + formfield.company_verification_document : formfield.company_imageb64} alt="ads_image" className="me-3" onError={noimageHandle} />
                        <button type="button" className="btn btn-sm btn-primary text-nowrap">Upload Verification Document</button>
                        <input type="file" accept=".pdf,.jpg,.jpeg,.png" name="verification_document" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState,
                            company_imageb64 : imageSet(e),
                        }))} required={offcanvastitle==="Add New Company"?true:false} />
                        <div className="invalid-feedback">
                            Required to upload image.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="company_name" defaultValue={formfield.company_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, company_name: e.target.value
                        }))} autoComplete="off" required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Email<strong className="text-danger">*</strong></div>
                        <input type="email" className="form-control" name="company_email" defaultValue={formfield.company_email} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, company_email: e.target.value
                        }))} autoComplete="off" required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">
                            Password<strong className="text-danger">{offcanvastitle === "Add New Company"? '*': null}</strong>
                        </div>
                        <input type="password" className="form-control" name="company_password" defaultValue={""} minLength="8" required={offcanvastitle === "Add New Company" ? true : false} />
                        <div className="invalid-feedback">
                            The password field must be at least 8 characters.
                        </div>
                        {offcanvastitle !== "Add New Company" ? <div className="text-success small">Leave it blank to keep the old password.</div> : null}
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Phone<strong className="text-danger">*</strong></div>
                        <input type="tel" className="form-control" id="phone" autoComplete="off" name="company_phone" data-intl-tel-input-id="0" defaultValue={formfield.company_phone} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, company_phone: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company Category<strong className="text-danger">*</strong></div>
                        <ComboSelect 
                            name="company_category" 
                            options={formfield.company_category_list}
                            selected={formfield.company_category_selected}
                            id="mselect"
                            placeholder="Select"
                            required="requied"
                            type="multiple"
                            key="1"
                        />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Registration ID<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control w-100" name="company_registration_id" defaultValue={formfield.company_registration_id} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, company_registration_id: e.target.value
                        }))} required/>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Address<strong className="text-danger">*</strong></div>
                        <textarea className="form-control" name="company_address" id="company_address" spellCheck={false} rows={3} defaultValue={formfield.company_address} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, company_address: e.target.value
                        }))} required></textarea>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Contact Person<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="company_contact_person" id="company_contact_person" defaultValue={formfield.company_contact_person} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, company_contact_person: e.target.value
                        }))} required />
                    </label>
                    
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Status</div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input minh-22 minw-40 me-2" name="is_active" type="checkbox" defaultValue={formfield.is_active} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_active: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_active === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Active</div>
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input minh-22 minw-40 me-2" name="is_verified" type="checkbox" defaultValue={formfield.is_verified} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_verified: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_verified === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Verified</div>
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => addCompanyMethod(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Cancel">Cancel</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.company_id)} />
        </>
    )
}

export default CompanyData