import { useEffect } from 'react';
import $ from 'jquery';

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

function DateRangeFilter(props){
    useEffect(() => {
        $(`input[name="${props.name}"]`).daterangepicker({
            autoApply: true,
            autoUpdateInput: props.disableUpdateInput === false ? false : true,
            maxDate: props.maxDate ? props.maxDate : '',
            opens: props.opens ? props.opens : 'left',
            startDate: props.startDate ? props.startDate : new Date(),
            endDate: props.endDate ? props.endDate : new Date(),
            locale: {
                format: 'MMM DD, YYYY'
            }
        })
        $(`input[name="${props.name}"]`).on('apply.daterangepicker', function(ev, picker) {
            $(`input[name="${props.name}"]`).val(picker.startDate.format('MMM DD, YYYY')+' - '+picker.endDate.format('MMM DD, YYYY'))
            props.getDateRange(picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'))
        });
    }, [props])

    return (
        <input type="text" name={props.name} defaultValue={props.daterange} placeholder={props.placeholder} className={props.className}/>
    )
}

export default DateRangeFilter