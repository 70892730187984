import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import DateRangeFilter from '../../components/Filter';
import "react-datepicker/dist/react-datepicker.css";
import { Datatable, dtControllers } from "../../components/Datatable";
import { loadingData } from "../../components/Helper";
import { EXPORT_TRANSACTION_LIST, fetchData, REVENUE_LIST } from "../../components/Service";

function SubscriptionTransaction() {
    window.document.title = "Subscription Transaction";
    const [records, setRecords] = useState("");
    const [tablespinner, setTablespinner] = useState(true)
    const [dateRange, setDateRange] = useState(['', ''])
    const [startDate, endDate] = dateRange
    const location = useLocation()

    const [dt, setDt] = useState({
        fetchurl: REVENUE_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        startdate: '',
        enddate: '',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        loadingData(true)
        setTablespinner(true)
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            setTablespinner(false)
        });

    }, [dt])


    const getDateRange = (startDate, endDate) => {
        setDateRange([startDate, endDate])
        setDt(prevState => ({
            ...prevState,
            startdate: startDate,
            enddate: endDate,
        }))
    }

    const exportData = () =>{
        loadingData(true)
        fetchData(`${EXPORT_TRANSACTION_LIST}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, false, (res) => {
            loadingData(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report Subscriptions.xlsx`);
            document.body.appendChild(link);
            link.click();
        }, (err) => {}, "blob");
    }

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Subscription Transaction</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row g-2 align-items-start">
                                <div className="col col-md-auto">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                

                                <div className="col-md-auto ms-auto">
                                    <div className="input-group input-group-pill flex-nowrap">
                                        <div className="input-group-text">
                                            <svg className="icon tscale-1p3" role="img"><use href="#icon_calendar" /></svg>
                                        </div>
                                        <DateRangeFilter
                                            daterange=""
                                            className="form-control text-center"
                                            placeholder="Choose Date Range"
                                            maxDate={new Date()}
                                            opens="left"
                                            name='d_daterange'
                                            disableUpdateInput={false}
                                            getDateRange={getDateRange}
                                        />
                                        {location.state &&
                                        <button type="button" onClick={exportData} className="btn btn-primary px-3 text-nowrap">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_download" /></svg>
                                            <span className="d-none d-md-inline">Export</span>
                                        </button> }
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner" data-tablespinner={tablespinner}>
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th data-orderby="asc" data-sortby="date" onClick={(e) => dtActions(e, 'sortby')}>DATE</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="title" onClick={(e) => dtActions(e, 'sortby')}>Subscription Menu</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="company_name" onClick={(e) => dtActions(e, 'sortby')}>Company</th>
                                                <th data-orderby="asc" data-sortby="subscription_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Subscription Code
                                                </th>
                                                <th data-orderby="asc" data-sortby="transaction_code" onClick={(e) => dtActions(e, 'sortby')}>
                                                    Transaction Code
                                                </th>
                                                <th data-orderby="asc" data-sortby="expired_at" onClick={(e) => dtActions(e, 'sortby')} width="220">
                                                    Expired At
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.date).toDateString()}</td>
                                                    <td>{ele.title}</td>
                                                    <td>{ele.company_name}</td>
                                                    <td>{ele.subscription_code}</td>
                                                    <td>{ele.transaction_code}</td>
                                                    <td className="text-nowrap">
                                                        {new Date(ele.expired_at).toDateString()}
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SubscriptionTransaction
