import React, { useEffect, useState } from "react";
import { fetchFrontData, ABOUT, ASSET_ENDPOINT } from "../../components/Service";
import { noimageHandle, scrollbarSetToTop } from "../../components/Helper";
import TopHeader from "../../components/TopHeader";

function ABOUT_US() {
    const[langType, setLangType] = useState(localStorage.getItem('lang_type'))
    const[data, setData] = useState({
        about_us_image_1: '',
        about_us_image_2: '',
        about_us_image_3: '',
        cms__about_us_body_1: '',
        cms__about_us_body_2: '',
        cms__about_us_body_3:'',
        cms__about_us_headings_1: '',
        cms__about_us_headings_2: '',
        cms__about_us_headings_3: '',
        cms__about_us_title_1: '',
        cms__about_us_title_2: '',
        cms__about_us_title_3: '',
    })

    useEffect(() => {
        scrollbarSetToTop()
        fetchFrontData(ABOUT, 'GET', '', (res) => {
            if(res.status){
                setData({
                    about_us_image_1: res.records.about_us_image_1,
                    about_us_image_2: res.records.about_us_image_2,
                    about_us_image_3: res.records.about_us_image_3,
                    cms__about_us_body_1: res.records.cms__about_us_body_1,
                    cms__about_us_body_2: res.records.cms__about_us_body_2,
                    cms__about_us_body_3:res.records.cms__about_us_body_3,
                    cms__about_us_headings_1: res.records.cms__about_us_headings_1,
                    cms__about_us_headings_2: res.records.cms__about_us_headings_2,
                    cms__about_us_headings_3: res.records.cms__about_us_headings_3,
                    cms__about_us_title_1: res.records.cms__about_us_title_1,
                    cms__about_us_title_2: res.records.cms__about_us_title_2,
                    cms__about_us_title_3: res.records.cms__about_us_title_3,
                })
            }

        }, (err) => {})

    }, [langType])

    return(
        <>
            <TopHeader refresh={setLangType} reload={true} />
            <section className={`container py-5 py-md-5 ${langType === 'ur' || langType === 'ar' ? ' direction-rtl' : ''}`}>
                <div className="container">
                    <div className="row g-4 py-4">
                        <div className="col-md-5">
                            <img className="w-100 rounded-3" src={ASSET_ENDPOINT + data.about_us_image_1} alt="about 1" onError={noimageHandle} />
                        </div>
                        <div className="col-md-7 ps-md-4 align-self-center">
                            <p className="m-0 fw-medium" style={{color: "#18AFE6"}}>{data.cms__about_us_title_1}</p>
                            <h3 className="mt-0 fw-bold title">{data.cms__about_us_headings_1}</h3>
                            <div dangerouslySetInnerHTML={{__html: data.cms__about_us_body_1}}></div>
                        </div>
                    </div>
                    <div className="row g-4 py-4">
                        <div className="col-md-5 order-md-1 ps-md-4">
                            <img className="w-100 rounded-3" src={ASSET_ENDPOINT + data.about_us_image_2} alt="about 2" onError={noimageHandle} />
                        </div>
                        <div className="col-md-7 align-self-center">
                            <p className="m-0 fw-medium" style={{color: "#18AFE6"}}>{data.cms__about_us_title_2}</p>
                            <h3 className="mt-0 fw-bold title">{data.cms__about_us_headings_2}</h3>
                            <div dangerouslySetInnerHTML={{__html: data.cms__about_us_body_2}}></div>
                        </div>
                    </div>
                    <div className="row g-4 py-4">
                        <div className="col-md-5">
                            <img className="w-100 rounded-3" src={ASSET_ENDPOINT + data.about_us_image_3} alt="about 3" onError={noimageHandle} />
                        </div>
                        <div className="col-md-7 ps-md-4 align-self-center">
                            <p className="m-0 fw-medium" style={{color: "#18AFE6"}}>{data.cms__about_us_title_3}</p>
                            <h3 className="mt-0 fw-bold title">{data.cms__about_us_headings_3}</h3>
                            <div dangerouslySetInnerHTML={{__html: data.cms__about_us_body_3}}></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )   
}
export default ABOUT_US