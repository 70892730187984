import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { fetchData, DASHBOARD_STATISTICS } from "../../components/Service";
import { loadingData } from "../../components/Helper";

function Dashboard() {
    window.document.title = "Dashboard";
    const navigate = useNavigate()
    const [data, setData] = useState('');
    const [filter, setFilter] = useState('');
    const [userType, setUserType] = useState('admin')
    
    useEffect (() =>{
        loadingData(true)
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            setUserType(user.userType)
        }
        fetchData(DASHBOARD_STATISTICS+`?${filter[0]}=${filter[0]}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            setData(res.records)
        }, (err) => {})
    },[filter])

    return(
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="">Dashboard</h4>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-auto mb-3">
                            <h6 className="m-0 text-blue">{filter? filter[1] : "All"} Report</h6>
                        </div>
                        <div className="col-auto ms-auto mb-3">
                            <div className="input-group input-group-pill dropdown">
                                <label className="input-group-text" for="filtertenderstatus">
                                    <svg className="icon me-1" role="img"><use href="#icon_filter"></use></svg>
                                    Report Of
                                </label>
                                <button type="button" className="btn btn-pageof minw-140 bg-white float-end dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">   
                                    {filter? filter[1] : "All"}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto">
                                    <li>
                                        <button type="button" title="All" className="dropdown-item rounded-20 text-blue py-2"
                                        onClick = {(e) => setFilter(['all','All'])}>All</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Day" className="dropdown-item rounded-20 text-blue py-2" 
                                        onClick = {(e) => setFilter(['thisDay', 'This Day'])}>This Day</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Week" className="dropdown-item rounded-20 text-blue py-2" 
                                            onClick = {(e) => setFilter(['thisWeek', 'This Week'])}>This Week</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Month" className="dropdown-item rounded-20 text-blue py-2" 
                                        onClick = {(e) => setFilter(['thisMonth', 'This Month'])}>This Month</button>
                                    </li>
                                    <li>
                                        <button type="button" title="This Year" className="dropdown-item rounded-20 text-blue py-2" 
                                        onClick = {(e) => setFilter(['thisYear', 'This Year'])}>This Year</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-2 g-sm-4">
                        <div className="col" onClick={()=>navigate(`/${userType}/report/transaction-list`)}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-info" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-info-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-info">
                                        <svg className="icon fs-2" role="img"><use href="#icon_payment"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">{data.num_trans ? data.num_trans : 0}</h3>
                                    <p className="m-0 text-muted">Number of Transactions</p>
                                </div>
                            </div>
                        </div>
                        <div className="col" onClick={()=>navigate(`/${userType}/report/transaction-list`)}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-warning" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-warning-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-warning">
                                        <svg className="icon fs-2" role="img"><use href="#icon_dollar"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">QAR {data.value_trans ? data.value_trans : '0.00'}</h3>
                                    <p className="m-0 text-muted">Value of Transactions</p>
                                </div>
                            </div>
                        </div>
                        <div className="col" onClick={()=>navigate(`/${userType}/subscription/transaction`,{ state: 'transaction' })}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-danger" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-danger-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-danger">
                                        <svg className="icon fs-2" role="img"><use href="#icon_loyalty"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">QAR {data.subscription ? data.subscription : '0.00'}</h3>
                                    <p className="m-0 text-muted">Subscription Revenue</p>
                                </div>
                            </div>
                        </div>
                        <div className="col" onClick={()=>navigate(`/${userType}/report/product-sales-list`)}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-primary" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-primary-light-15 l1">
                                    <div className="d-flex align-items-center justify-content-center text-primary">
                                        <svg className="icon fs-2" role="img"><use href="#icon_dollar"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">QAR {data.product_sales ? data.product_sales : '0.00'}</h3>
                                    <p className="m-0 text-muted">Total Products Sales</p>
                                </div>
                            </div>
                        </div>
                        { userType === 'admin' ? <>
                            <div className="col" onClick={()=>navigate('/admin/report/new-user-list')}>
                                <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-secondary" role="button">
                                    <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-secondary-light-15 l1">
                                        <div className="d-flex align-items-center justify-content-center text-secondary">
                                            <svg className="icon fs-2" role="img"><use href="#icon_user"></use></svg>
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <h3 className="mb-1">{data.new_user ? data.new_user : 0}</h3>
                                        <p className="m-0 text-muted">New User</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col" onClick={()=>navigate('/admin/report/active-user-list')}>
                                <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-success" role="button">
                                    <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-success-light-15 l1">
                                        <div className="d-flex align-items-center justify-content-center text-success">
                                            <svg className="icon fs-2" role="img"><use href="#icon_users"></use></svg>
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <h3 className="mb-1">{data.active_user ? data.active_user : 0}</h3>
                                        <p className="m-0 text-muted">Active User</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col" onClick={()=>navigate('/admin/report/newcompany-list')}>
                                <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-orange" role="button">
                                    <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-orange-light-15 l1">
                                        <div className="d-flex align-items-center justify-content-center text-orange">
                                            <svg className="icon fs-2" role="img"><use href="#icon_company"></use></svg>
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <h3 className="mb-1">{data.new_company ? data.new_company : 0}</h3>
                                        <p className="m-0 text-muted">New Company</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col" onClick={()=>navigate('/admin/report/active-company-list')}>
                                <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-pink" role="button">
                                    <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-pink-light-15 l1">
                                        <div className="d-flex align-items-center justify-content-center text-pink">
                                            <svg className="icon fs-2" role="img"><use href="#icon_company"></use></svg>
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <h3 className="mb-1">{data.active_company ? data.active_company : 0}</h3>
                                        <p className="m-0 text-muted">Active Company</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col" onClick={()=>navigate('/admin/report/total-product-list')}>
                                <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-primary" role="button">
                                    <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-primary-light-15 l1">
                                        <div className="d-flex align-items-center justify-content-center text-primary">
                                            <svg className="icon fs-3" role="img"><use href="#icon_cube"></use></svg>
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <h3 className="mb-1">{data.total_product ? data.total_product : 0}</h3>
                                        <p className="m-0 text-muted">New Product</p>
                                    </div>
                                </div>
                            </div>
                        </> : '' }
                        <div className="col" onClick={()=>navigate(`/${userType}/report/total-product-list`, {state:'Product'})}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-info" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-info-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-info">
                                        <svg className="icon fs-3" role="img"><use href="#icon_cube"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">{data.total_product ? data.total_product : 0}</h3>
                                    <p className="m-0 text-muted">Total Product</p>
                                </div>
                            </div>
                        </div>
                        <div className="col" onClick={()=>navigate(`/${userType}/report/total-tender-list`)}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-warning" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-warning-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-warning">
                                        <svg className="icon fs-2" role="img"><use href="#icon_layers"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">{data.new_tender ? data.new_tender : 0}</h3>
                                    <p className="m-0 text-muted">New Tender</p>
                                </div>
                            </div>
                        </div>
                        <div className="col" onClick={()=>navigate(`/${userType}/report/running-tender-list`)}>
                            <div className="bg-white d-flex align-items-center h-100 p-3 p-md-4 shadow-sm rounded-15 border-start border-4 border-secondary" role="button">
                                <div className="ratio ratio-1x1 mmw-70 rounded-pill bg-secondary-light-15">
                                    <div className="d-flex align-items-center justify-content-center text-secondary">
                                        <svg className="icon fs-2" role="img"><use href="#icon_archive"></use></svg>
                                    </div>
                                </div>
                                <div className="ps-3">
                                    <h3 className="mb-1">{data.running_tender ? data.running_tender  : 0}</h3>
                                    <p className="m-0 text-muted">Running Tender</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )   
}
export default Dashboard