import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toastNotify, validateForm} from '../../components/Helper';
import { fetchData, FORGOT_PASSWORD } from '../../components/Service';

function ForgotPassword(){
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(() => {
   
    }, [])

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }
    const loginSubmission = (e) => {
        let requiredField = new FormData(document.getElementById('resetForm'));
        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(FORGOT_PASSWORD, 'POST', requiredField, false, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    navigate('/login')
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        };
    }

    return(
        <>
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                        <div className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm">
                            <div className="text-center">
                                <img src="images/tawreed-logo.svg" alt="Tawreed - Building Connections" className="w-100 maxw-280" width="280" height="70.81" />
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <h4 className="text-center my-3">Forgot Password</h4>
                            <form className="bg-white rounded-15 p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="resetForm" noValidate>
                            <label className="input-group input-group-lg input-group-rounded mb-3">
                                <span className="input-group-text">
                                    <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_user" />
                                    </svg>
                                </span>
                                <input type="email" className="form-control" name="email" placeholder="Enter your email address" required />
                            </label>
                            <label className="mb-3 "> 
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id='adminType' defaultChecked name="userType" value="admin" onKeyUp={changeHandler}/>
                                    <label className="form-check-label" htmlFor="adminType">Admin</label>
                                </div>

                                <div className="form-check">
                                    <input type="radio" className="form-check-input" id='userType' name="userType" value="seller" onKeyUp={changeHandler}/>
                                    <label className="form-check-label" htmlFor="userType">Seller</label>
                                </div>
                                
                                <div className="invalid-feedback ps-70">
                                    This is required field.
                                </div>
                            </label>
                            <button type="button" className="btn btn-primary btn-lg w-100 rounded-pill mb-3" onClick={(e) => loginSubmission(e)} disabled={tinyloader}>
                                {
                                    !tinyloader ? 'Submit'
                                    :
                                    <div className="spinner-border spinner-border-sm ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </button>
                            </form>
                            <div className="d-flex justify-content-between">
                                <div className="my-2">
                                    Go to <Link to="/login" className="fw-medium" title="Login">Login</Link>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default ForgotPassword