import { toastNotify, scrollbarSetToTop } from "./Helper";
import { fetchData } from "./Service";
export const Datatable = (dt, callback) => {
    let query_string = `${dt.pagenumber}&per_page=${dt.perpage}`;
    if (dt.search) {
        query_string = `${query_string}&search=${dt.search}`;
    }
    if (dt.sortcolumn && dt.sortorder) {
        query_string = `${query_string}&sort=${dt.sortcolumn}&order_by=${dt.sortorder}`;
    }
    if (dt.columns) {
        query_string = `${query_string}&columns=${dt.columns}`;
    }
    if (dt.f_type) {
        query_string = `${query_string}&f_type=${dt.f_type}`;
    }
    if (dt.list_type) {
        query_string = `${query_string}&list_type=${dt.list_type}`;
    }
    if (dt.pay_status_type) {
        query_string = `${query_string}&pay_status_type=${dt.pay_status_type}`;
    }
    if (dt.pay_method_type) {
        query_string = `${query_string}&pay_method_type=${dt.pay_method_type}`;
    }
    if (dt.startdate) {
        query_string = `${query_string}&startdate=${dt.startdate}`;
    }
    if (dt.enddate) {
        query_string = `${query_string}&enddate=${dt.enddate}`;
    }
    fetchData(dt.fetchurl + query_string, 'GET', '', true, false, (res) => {
        if (res.status) {
            callback(res.records)
        } else {
            toastNotify('danger', res.message)
            callback(false)
        }
    }, (err) => { });
}


let timeout = null;
export const dtControllers = (e, type, callback) => {
    if(type==='search'){
        let getsearch_value = e.target.value.replace(/\s\s+/g, ' ').trimLeft();
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            callback({search:getsearch_value, pagenumber:'?page='})
        }, 400);
    }
    if(type==='perpage'){
        callback({ perpage: e, pagenumber: '?page='})
    }
    if(type==='pagination'){
        callback({pagenumber: e})
        scrollbarSetToTop()
    }
    if (type === 'sortby') {
        let set_orderby = e.target.dataset.orderby;
        let set_sortby = e.target.dataset.sortby;
        document.querySelectorAll(e.target.parentElement.nodeName + '> th').forEach((ele) => {
            ele.classList.remove('active')
        });
        e.target.dataset.orderby = (set_orderby === 'asc' ? 'desc' : 'asc');
        e.target.classList.add('active');
        callback({ sortcolumn: set_sortby, sortorder: set_orderby })
    }
    if(type==='f_type'){
        callback({f_type: e.target.value})
    }
    if (type === 'list_type') {
        callback({ list_type: e.target.value })
    }
}