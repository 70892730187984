import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import DateRangeFilter from '../../components/Filter';
import "react-datepicker/dist/react-datepicker.css";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, ConfirmationModal, tableSpinner, loadingData, intlTel_phone, validatePhone, setNumber } from "../../components/Helper";
import { fetchData, USER_LIST, CREATE_USER, GET_UPDATE_USER, DELETE_USER, ASSET_ENDPOINT, NEW_USER_EXPORT, ACTIVE_USER_EXPORT } from "../../components/Service";
import { useLocation } from "react-router";

function User() {
    window.document.title = "User Data";
    const location = useLocation()
    const [updatetable, setUpdatetable] = useState(true);
    const [tinyloader, setTinyloader] = useState(false);
    const [dateRange, setDateRange] = useState(['', ''])
    const [intlTel, setIntlTel] = useState()
    const [startDate, endDate] = dateRange
    const [offcanvastitle, setOffcanvastitle] = useState("Add New User")
    const [formfield, setFormfield] = useState({
        user_id: "",
        user_name: "",
        user_email: "",
        user_phone: "",
        user_profile_photo: "/",
        is_confirmed: 1,
        is_active: 1,
        is_verified: 1,
        user_imageb64: ""
    })

    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: USER_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        setIntlTel(intlTel_phone('#phone')) 
        loadingData(true)
        tableSpinner(true, 'userlistTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'userlistTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("addupdateUser").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            if(document.getElementById("phone-intl-error")){
                document.getElementById("phone-intl-error").remove()
            }
            document.getElementById("phone").classList.remove('is-invalid')
            setOffcanvastitle("Add New User");
            setFormfield({ /*Set empty state*/
                user_id: "",
                user_profile_photo: "/",
                is_active: 1,
                is_confirmed: 1,
                user_imageb64: "",
                user_name: "",
                user_email: "",
                user_phone: "",
                is_verified: 1,
            })
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/

    const addupdateMethod = async (e) => {
        let formdata = new FormData(document.getElementById('addupdateUser'));
        formdata.append('user_phone', intlTel.getNumber())
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.user_id === null || formfield.user_id === '') {
                fetchData(CREATE_USER, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('#addupdateUser [data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v,i)=>{
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            } else {
                fetchData(GET_UPDATE_USER+'/'+formfield.user_id, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }
        }
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({
            ...prevState,
            user_imageb64: imgb64
        }))
    }

    const editForm = (rec) => {
        loadingData(true)
        setFormfield(rec)
        setOffcanvastitle("Update User");
        setNumber(intlTel, rec.user_phone)
        new Offcanvas(document.getElementById('addupdateUser')).show();
        loadingData(false)
    }

    const deleteRecords = (id) => {
        setUpdatetable(true);
        fetchData(DELETE_USER + '/' + id, 'GET', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click();
            if (res.status) {
                setUpdatetable(false);
                toastNotify('success', res.message)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    const getDateRange = (startDate, endDate) => {
        setDateRange([startDate, endDate])
    }

    const exportUser = (user) =>{
        if(user === 'New') {
            fetchData(`${NEW_USER_EXPORT}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, false, (res) => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Report Users Details.xlsx`);
                document.body.appendChild(link);
                link.click();
            }, (err) => {}, "blob");
        }else if(user === 'Active'){
            fetchData(`${ACTIVE_USER_EXPORT}?startdate=${startDate}&enddate=${endDate}` , 'GET', '', true, true, (res) => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Report Active Users.xlsx`);
                document.body.appendChild(link);
                link.click();
            }, (err) => {}, "blob");
        }
    }


    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">User Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false" />
                                </div>
                                <div className="col ms-auto d-flex flex-wrap">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto d-flex">
                                    {location.state ? 
                                        <div className="input-group input-group-pill flex-nowrap">
                                            <div className="input-group-text">
                                                <svg className="icon tscale-1p3" role="img"><use href="#icon_calendar" /></svg>
                                            </div>
                                            <DateRangeFilter 
                                                daterange=""
                                                className="form-control"
                                                maxDate={new Date()}
                                                opens="left"
                                                placeholder="Select Date Range"
                                                name='d_daterange'
                                                disableUpdateInput={false}
                                                getDateRange={getDateRange}
                                            />
                                            <button type="button" className="btn btn-primary px-3 text-nowrap" onClick = {()=>exportUser(location.state)}>
                                                <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_download" /></svg>
                                                <span className="d-none d-md-inline"> Export {location.state} User</span>
                                            </button>
                                        </div>
                                        :'' }
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap mx-2" data-bs-toggle="offcanvas" data-bs-target="#addupdateUser">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="table-spinner active" id="userlistTable">
                                <div className="table-responsive" >
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th className="minw-200" data-orderby="asc" data-sortby="user_name" onClick={(e) => dtActions(e, 'sortby')}>Name</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="user_phone" onClick={(e) => dtActions(e, 'sortby')}>Phone</th>
                                                <th data-orderby="asc" data-sortby="user_email" onClick={(e) => dtActions(e, 'sortby')}>Email</th>
                                                <th data-orderby="asc" data-sortby="is_confirmed" width="150" onClick={(e) => dtActions(e, 'sortby')}>Confiremd?</th>
                                                <th data-orderby="asc" data-sortby="is_active" width="150" onClick={(e) => dtActions(e, 'sortby')}>Status</th>
                                                <th width="140">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{ele.user_name}</td>
                                                    <td>{ele.user_phone}</td>
                                                    <td>{ele.user_email}</td>
                                                    <td>
                                                        {ele.is_confirmed === 1 ? 'Confirmed' : 'Pending'}
                                                    </td>
                                                    <td>
                                                        {ele.is_active === 1 ? 'Active' : 'Disabled'}
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => editForm(ele)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, user_id: ele.user_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                                :
                                                records === 0 ?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={8}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addupdateUser" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <input type="text" className="d-none" name="user_id" defaultValue={formfield.user_id} />
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.user_imageb64 ? ASSET_ENDPOINT + '/' + formfield.user_profile_photo : formfield.user_imageb64} alt="ads_image" className="me-3" onError={noimageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap">Upload Image</button>
                        <input type="file" accept="image/*" className="form-field" name="user_profile_photo" title="Upload Image" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState,
                            user_imageb64: imageSet(e),
                        }))} />
                        <div className="invalid-feedback">
                            Required to upload image.
                        </div>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Full Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="user_name" defaultValue={formfield.user_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, user_name: e.target.value
                        }))} required />
                    </label>

                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Email<strong className="text-danger">*</strong></div>
                        <input type="email" className="form-control" name="user_email" minLength="10" defaultValue={formfield.user_email} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, user_email: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">
                            Password<strong className="text-danger">{offcanvastitle === "Add New User" ? '*' : null}</strong>
                        </div>
                        <input type="password" className="form-control form-field" name="user_password" defaultValue={""} minLength="8" required={offcanvastitle === "Add New User" ? true : false} />
                        <div className="invalid-feedback">
                            The password field must be at least 8 characters.
                        </div>
                        {offcanvastitle !== "Add New User" ? <div className="text-success small">Leave it blank to keep the old password.</div> : null}
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Phone<strong className="text-danger">*</strong></div>
                        <input type="tel" className="form-control" id="phone" name="user_phone" data-intl-tel-input-id="0" defaultValue={formfield.user_phone} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, user_phone: e.target.value
                        }))} required />
                    </label>
                 
                
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Status</div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="is_active" type="checkbox" defaultValue={formfield.is_active} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_active: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_active === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Active</div>
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="is_confirmed" type="checkbox" defaultValue={formfield.is_confirmed} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_confirmed: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_confirmed === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Confirmed</div>
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="is_verified" type="checkbox" defaultValue={formfield.is_verified} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_verified: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_verified === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Verify</div>
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill px-4 me-2" onClick={(e) => addupdateMethod(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>

            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.user_id)} />
        </>
    )
}

export default User