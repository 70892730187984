import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { Tooltip } from "bootstrap";
import { toastNotify, noimageHandle } from '../components/Helper';
import { fetchData, LOGOUT, ASSET_ENDPOINT, DASHBOARD_NOTIFICATION, UNREAD_NOTIFICATION, MUTE_NOTIFICATION } from '../components/Service';

function HeaderBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [userinfo, setUserInfo] = useState(JSON.parse(localStorage.getItem("tw-records")));
    const [notification, setNotification] = useState('');
    const [userType, setUserType] = useState('admin')
    const [mute, setMute] = useState(false)
    
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            setUserType(user.userType)
        }
        
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })

        /*Start | Notification Check then show*/ 
        setTimeout(()=>{
            if(user && user.userType && user.userType === 'admin'){
                setInterval(() =>{
                    fetchData(DASHBOARD_NOTIFICATION, 'GET', '', true, false, (res) => {
                        setNotification(res.records);
                    }, (err) => {})
                }, 5000)  
            }else{
                sellerNotification()
            }
        },3000);

        if(user && user.userType && user.userType === 'seller'){
            fetchData(MUTE_NOTIFICATION, 'GET', '', true, false, (res) => {
                if(res.records && res.records.is_muted === 0){
                    setMute(false)
                }else{
                    setMute(true)
                }
            }, (err) => {})
        }
        /*End | Notification Check then show*/
        
        setInterval(()=>{
            setUserInfo(JSON.parse(localStorage.getItem("tw-records")));
        },4000);

    }, []);


    const sellerNotification = () => {
        fetchData(MUTE_NOTIFICATION, 'GET', '', true, false, (res) => {
            if(res.records && res.records.is_muted === 0){
                fetchData(UNREAD_NOTIFICATION, 'GET', '', true, false, (res) => {
                    setNotification(res.records);
                    setMute(false)
                }, (err) => {})
            }else{
                setMute(true)
            }
        }, (err) => {})
    }

    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, false, (res) => {
            if (res.status) {
                toastNotify('success', res.message)
                localStorage.removeItem("tw-records");
                navigate('/login')
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
    }

    const menuToggle = () => {
        document.body.classList.toggle('menu-active');
    }

     const muteNotification = (e) => {
        setMute(!(e.target.checked))
        fetchData(MUTE_NOTIFICATION, 'POST', '', true, false, (res) => {
            sellerNotification()
        }, (err) => {})
     }

    return (
        <>
            <nav className="admin-header">
                <div className="row flex-nowrap m-0 h-100 align-items-center">
                    <div className="col-auto d-block d-xl-none">
                        <div className="admin-notification" onClick={menuToggle} role="button">
                            <svg className="icon" role="img"><use href="#icon_menu"/></svg>
                        </div>
                    </div>
                    <div className="col-auto d-none d-md-inline-flex">
                        <span className="page-title" id="tw_pagetitle"></span>
                    </div>
                    <div className="col-auto ms-auto">
                        { userType && userType === 'admin' ? <>
                            <NavLink to="company/verification" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex" role="button" title={`${notification.unconf_company} Unconfirmed Company`} data-bs-original-title={`${notification.unconf_company} Unconfirmed Company`} data-bs-toggle="tooltip" data-bs-placement="bottom">
                                <svg className="icon"><use href="#icon_users" /></svg>
                                <span>
                                    {
                                        notification ? notification.unconf_company : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>
                                    }
                                </span>
                            </NavLink>
                            <NavLink to="tender/confirmation" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex" role="button" title={`${notification.unconf_tender} Unconfirmed Tender`} data-bs-original-title={`${notification.unconf_tender} Unconfirmed Tender`} data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom">
                                <svg className="icon tscale-0p8"><use href="#icon_layers" /></svg>
                                <span>
                                    {
                                        notification ? notification.unconf_tender : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>
                                    }
                                </span>
                            </NavLink>
                            <NavLink to="withdraw/confirmation" className="admin-notification me-2 me-md-3 d-none d-sm-inline-flex" role="button" title={`${notification.unconf_withdtaw} Unconfirmed Withdraw`} data-bs-original-title={`${notification.unconf_withdtaw} Unconfirmed Withdraw`} data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom">
                                <svg className="icon"><use href="#icon_dollar" /></svg>
                                <span>
                                    {
                                        notification ? notification.unconf_withdtaw : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>
                                    }
                                </span>
                            </NavLink>
                            <NavLink to="ads/contacted-ads" className="admin-notification me-2 me-md-3" role="button" title={`${notification.new_ads_offer} New Ads Offer`} data-bs-original-title={`${notification.new_ads_offer} New Ads Offer`} data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom">
                                <svg className="icon tscale-0p9"><use href="#icon_ads" /></svg>
                                <span>
                                    {
                                        notification ? notification.new_ads_offer : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>
                                    }
                                </span>
                            </NavLink>
                            <NavLink to="order-review/confirmation" className="admin-notification me-2 me-md-3" role="button" title={`${notification.uncof_ord_review} Unconfirmed Order Review`} data-bs-original-title={`${notification.uncof_ord_review} Unconfirmed Order Review`} data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom">
                                <svg className="icon tscale-0p8"><use href="#icon_archive" /></svg>
                                <span>
                                    {
                                        notification ? notification.uncof_ord_review : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>
                                    }
                                </span>
                            </NavLink>
                            <NavLink to="tender-review/confirmation" className="admin-notification me-2 me-md-3" role="button" title={`${notification.uncof_tender_review} Unconfirmed Tender Review`} data-bs-original-title={`${notification.uncof_tender_review} Unconfirmed Tender Review`} data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom">
                                <svg className="icon"><use href="#icon_suitcase" /></svg>
                                <span>
                                    {
                                        notification ? notification.uncof_tender_review : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>
                                    }
                                </span>
                            </NavLink>
                            </> : <>
                            <div className="d-inline-block me-2 me-md-3">
                                <div className="admin-notification" role="button" title='Notification' data-bs-toggle="dropdown">
                                    <svg className="icon" ><use href="#icon_bell"/></svg>
                                    { !mute ? <span>{notification ? notification.number : <div className="spinner-border spinner-border-xs align-baseline" role="status"></div>}</span> : '' }
                                </div>
                                <div className="dropdown-menu dropdown-menu-end border-0 p-0 overflow-hidden shadow rounded-15 minw-280">
                                    <div className="bg-blue text-center text-white fs-5 py-2 d-flex justify-content-between">
                                        <span className='ms-2'>New Notifications</span>
                                        <div className='form-check form-switch'>
                                            <input className="form-check-input" type="checkbox" checked={!mute} role="switch" onChange={(e) => muteNotification(e)} />
                                        </div>
                                    </div>
                                    <div className="overflow-auto maxh-300">
                                        { !mute && notification ? notification.result.map((item, key) => {
                                            return (
                                                <div className="d-flex px-3 py-2 border-bottom justify-content-between" key={key}>
                                                    <div className="pe-2">
                                                        <strong className="fw-medium text-blue">{item.notif_title}</strong>
                                                        <div className="fs-12 opacity-65 maxw-160 text-truncate">{item.notif_message}</div>
                                                    </div>
                                                    <div className="fs-12 opacity-65">{item.ago}</div>
                                                </div>
                                            )
                                        }) : '' }
                                    </div>
                                    <div className="w-100">
                                        <NavLink to="notification/list" className="bg-light fw-medium d-block text-center px-4 py-2 w-100">View All Notifications</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block me-2 me-md-3">
                                <div className="admin-notification" role="button" title='Chat' data-bs-toggle="dropdown">
                                    <svg className="icon"><use href="#icon_chat"/></svg>
                                </div>
                                <div className="dropdown-menu dropdown-menu-end border-0 p-0 overflow-hidden shadow rounded-15 minw-280">
                                    <div className="bg-blue text-center text-white fs-5 py-2 px-5">
                                        New Chat
                                    </div>
                                    <div className="overflow-auto maxh-300">
                                        <div className="d-flex px-3 py-2 border-bottom justify-content-between">
                                            <div className="pe-2">
                                                <strong className="fw-medium text-blue">Saturday Again</strong>
                                                <div className="fs-12 opacity-65">sature again</div>
                                            </div>
                                            <div className="fs-12 opacity-65">9 Hrs ago</div>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <span className="bg-light fw-medium d-block text-center px-4 py-2 w-100">Go to Chat</span>
                                        {/* <NavLink to="notification/list" className="bg-light fw-medium d-block text-center px-4 py-2 w-100">Go to Chat</NavLink> */}
                                    </div>
                                </div>
                            </div>
                        </> }
                        
                        <div className="dropdown d-inline-block align-middle">
                            <button type="button" className="admin-profile" data-bs-toggle="dropdown">
                                { userType && userType === 'admin' ? <>
                                <img src={userinfo!==null? ASSET_ENDPOINT + userinfo.staff_profile_photo: ''} onError={noimageHandle} alt="User" width="36" height="36" className="bg-white"/>
                                {userinfo.staff_name ? <div className="d-none d-lg-block mx-2 pe-1">{userinfo.staff_name}</div>:''}
                                </> : <>
                                <img src={userinfo!==null? ASSET_ENDPOINT + userinfo.company_profile_photo: ''} onError={noimageHandle} alt="User" width="36" height="36" className="bg-white"/>
                                {userinfo.company_name ? <div className="d-none d-lg-block mx-2 pe-1">{userinfo.company_name}</div>:''}
                                </> }
                                
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2">
                                <li>
                                    <Link to="dashboard" title="help" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/dashboard') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Help">
                                            <use href="#icon_dashboard" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="my-profile" title="Profile Settings" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/my-profile') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p4 opacity-75 me-3" role="img" aria-label="Help">
                                            <use href="#icon_usercog" />
                                        </svg>
                                        Profile Settings
                                    </Link>
                                </li>
                                { userType && userType === 'seller' ? <>
                                <li>
                                    <Link to="my-wallet-info" title="Bank Info" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/my-wallet-info') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p3 opacity-75 me-3" role="img" aria-label="Help">
                                            <use href="#icon_bank" />
                                        </svg>
                                        Bank Info
                                    </Link>
                                </li>
                                <li>
                                    <Link to="recent-work" title="Work" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/recent-work') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Help">
                                            <use href="#icon_work" />
                                        </svg>
                                        Work
                                    </Link>
                                </li>
                                </> : '' }
                                <li>
                                    <Link to="change-password" title="Change Password" className={`dropdown-item rounded-20 text-blue py-2 px-4 my-1 ${location.pathname.match('/change-password') ? 'active' : ''}`}>
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Help">
                                            <use href="#icon_lock" />
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider text-white opacity-25" />
                                </li>
                                <li>
                                    <button type="button" onClick={handleLogout} className="dropdown-item rounded-20 text-blue py-2 px-4" title="Logout">
                                        <svg className="icon tscale-1p2 opacity-75 me-3" role="img" aria-label="Setting">
                                            <use href="#icon_logout" />
                                        </svg>
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="overlay-onmobile" onClick={menuToggle}></div>
        </>
    )
}
export default HeaderBar;