import React, { useEffect, useState } from 'react';
import { loadingData, toastNotify, validateForm } from "../../components/Helper";
import { fetchData, UPDATE_BANK_INFO } from "../../components/Service";

function WalletInfo(){
    window.document.title = "Update Wallet Info";
    const [tinyloader, setTinyloader] = useState(false);
    const [walletInfo, setWalletInfo] = useState({
        bank_name: '',
        bank_code: '',
        bank_holder_account: '',
        bank_holder_name: '',
        bank_holder_email: '',
    })


    const updateWalletInfo = (e) => {
        let form = document.getElementById('mywalletForm')
        let data = new FormData(form);
        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(UPDATE_BANK_INFO, 'POST', data, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        };
        
    }

    const getWalletInfo = () => {
        loadingData(true)
        fetchData(UPDATE_BANK_INFO, 'GET', '', true, false, (res) => {
            loadingData(false)
            if (res.status) {
                setWalletInfo(res.records)
            }
        }, (err) => {});
    }

    useEffect(() => {
        getWalletInfo()
        
    }, [])


    return(
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between d-md-none">
                    <div className="col mb-2">
                        <h4 className="page-heading">Update Wallet Info</h4>
                    </div>
                </div>
                <form className="row mx-0 bg-white rounded-15 p-4 shadow-sm needs-validation" id="mywalletForm" noValidate>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 my-2'>
                        <label htmlFor="bank_name" className="form-label small text-blue fw-medium mb-1">
                            Bank Name<strong className="text-danger">*</strong>
                        </label>
                        <input type="text" className="form-control" id='bank_name' name="bank_name" placeholder="Bank Name" spellCheck={false} defaultValue={walletInfo.bank_name} required />
                    </div>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 my-2'>
                        <label htmlFor="bank_code" className="form-label small text-blue fw-medium mb-1">
                            Bank Coder<strong className="text-danger">*</strong>
                        </label>
                        <input type="text" className="form-control" id='bank_code' name="bank_code" placeholder="Bank Code" spellCheck={false} defaultValue={walletInfo.bank_code} required />
                    </div>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 my-2'>
                        <label htmlFor="bank_holder_account" className="form-label small text-blue fw-medium mb-1">
                            Bank Holder Account<strong className="text-danger">*</strong>
                        </label>
                        <input type="text" className="form-control" id='bank_holder_account' name="bank_holder_account" placeholder="Bank Holder Account" spellCheck={false} defaultValue={walletInfo.bank_holder_account} required />
                    </div>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 my-2'>
                        <label htmlFor="bank_holder_name" className="form-label small text-blue fw-medium mb-1">
                            Bank Holder Name<strong className="text-danger">*</strong>
                        </label>
                        <input type="text" className="form-control" id='bank_holder_name' name="bank_holder_name" placeholder="Bank Holder Name" spellCheck={false} defaultValue={walletInfo.bank_holder_name} required />
                    </div>
                    <div className='col-sm-12 col-md-8 col-lg-6 col-xl-5 my-2'>
                        <label htmlFor="bank_holder_email" className="form-label small text-blue fw-medium mb-1">
                            Bank Holder Email<strong className="text-danger">*</strong>
                        </label>
                        <input type="text" className="form-control" id='bank_holder_email' name="bank_holder_email" placeholder="Bank Holder Email" spellCheck={false} defaultValue={walletInfo.bank_holder_email} required />
                    </div>
                    <div className="col-sm-12 text-center text-sm-start my-2 pt-2">
                        <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium" onClick={(e) => updateWalletInfo(e)} disabled={tinyloader}>
                            {
                                !tinyloader ? 'Submit'
                                :
                                <div className="spinner-border spinner-border-sm ms-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default WalletInfo