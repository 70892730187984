import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Routes, Route } from "react-router-dom";
import { Context } from './Context';

import { LayoutOfAsideAndHeader, LayoutOfFrontend} from './LayoutOfAsideAndHeader';
import Login from '../modules/Auth/Login';
import ForgotPassword from '../modules/Auth/ForgotPassword';
import ResetPassword from '../modules/Auth/ResetPassword';
import Dashboard from '../modules/Dashboard/Dashboard';
import NotificationList from '../modules/Notification/List';
import CompanyData from '../modules/Company/CompanyData';
import CompanyVerification from '../modules/Company/CompanyVerification';
import SubscriptionData from '../modules/Subscription/SubscriptionData';
import SubscriptionTransaction from '../modules/Subscription/SubscriptionTransaction';
import User from '../modules/User/User';
import ContactedAds from '../modules/Ads/ContactedAds';
import Ads from '../modules/Ads/Ads';
import Product from '../modules/Product/Product';
import Category from '../modules/Category/Category';
import SubCategory from '../modules/Category/SubCategory';
import Properties from '../modules/Category/Properties';
import OrderList from '../modules/Order/OrderList';
import OrderReview from '../modules/Order/OrderReview';
import OrderReviewConfirmation from '../modules/Order/OrderReviewConfirmation';

import TenderList from '../modules/Tender/TenderList';
import Tenderconfirmation from '../modules/Tender/TenderConfirmation';
import TenderReviewList from '../modules/Tender/TenderReviewList';
import TenderReviewConfirmation from '../modules/Tender/TenderReviewConfirmation';

import Payment from '../modules/Payment/Payment';
import Wallet from '../modules/Payment/Wallet';
import WithdrawHistory from '../modules/Payment/WithdrawHistory';
import WithdrawConfirmation from '../modules/Payment/WithdrawConfirmation';

import GeneralSection from '../modules/CMS/General/GeneralSection';
import InformationSection from '../modules/CMS/General/InformationSection';

import SliderSection from '../modules/CMS/Home/SliderSection';
import DownloadSection from '../modules/CMS/Home/DownloadSection';
import Feature from '../modules/CMS/Home/Feature';
import Testimonial from '../modules/CMS/Home/Testimonial';
import Instagram from '../modules/CMS/Home/Instagram';

import PrivacyPolicy from '../modules/CMS/PrivacyPolicy';
import TermsConditions from '../modules/CMS/Menu/TermsConditions';
import ContactUs from '../modules/CMS/Menu/ContactUs';
import Advertise from '../modules/CMS/Menu/Advertise';

import Staff from '../modules/Staff/Staff';
import LanguageString from '../modules/LanguageString/LanguageString';
import ErrorPage from './ErrorPage';
import AboutUs from '../modules/CMS/AboutUs';
import ChangePassword from '../modules/Auth/ChangePassword';
import MyProfile from '../modules/Auth/MyProfile';
import WalletInfo from '../modules/Auth/WalletInfo';
import SellerSubscriptionData from '../modules/Subscription/SellerSubscriptionData';
import RecentWork from '../modules/Auth/RecentWork';
import Transaction from '../modules/Report/Transaction';
import TotalProductSales from '../modules/Report/TotalProductSales';
import NewUser from '../modules/Report/NewUser';
import ActiveUser from '../modules/Report/ActiveUser';
import NewCompany from '../modules/Report/NewCompany';
import ActiveCompany from '../modules/Report/ActiveCompany';
import TotalProduct from '../modules/Report/TotalProduct';
import TotalTender from '../modules/Report/TotalTender';
import RunningTender from '../modules/Report/RunninTender';
import WithdrawRequest from '../modules/Payment/WithdrawRequest';
import Home from '../modules/Front/Home';
import ABOUT_US from '../modules/Front/AboutUs';
import CONTACT_US from '../modules/Front/ContactUs';
import Advertisement from '../modules/Front/Advertise';
import Privacy from '../modules/Front/Privacy';
import Terms from '../modules/Front/TermCondition';


export default function Routing() {
    const location = useLocation();
    const [context, setContext] = useState('');

    useEffect(() => {
        setContext(JSON.parse(localStorage.getItem("tw-records")));
    }, [location.pathname]);
   
    return (
        <>
            <Context.Provider value={context} >
                <Routes>
                    <Route path="/" caseSensitive={false} element={<LayoutOfFrontend/>}>
                        <Route path="/" caseSensitive={false} element={<Home />} />
                        <Route path="/about-us" caseSensitive={false} element={<ABOUT_US />} />
                        <Route path="/contact-us" caseSensitive={false} element={<CONTACT_US />} />
                        <Route path="/advertise" caseSensitive={false} element={<Advertisement />} />
                        <Route path="/privacy" caseSensitive={false} element={<Privacy />} />
                        <Route path="/terms" caseSensitive={false} element={<Terms />} />
                    </Route>

                    <Route path="/login" caseSensitive={false} element={<Login />} />
                    <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                    <Route path="/reset-password" caseSensitive={false} element={<ResetPassword />} />

                    <Route path={context && context.userType} caseSensitive={false} element={<LayoutOfAsideAndHeader />}>
                        <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                        <Route path="notification/list" caseSensitive={false} element={<NotificationList />} />
                        <Route path="company/data" caseSensitive={false} element={<CompanyData />} />
                        <Route path="company/verification" caseSensitive={false} element={<CompanyVerification />} />
                        <Route path="subscription/data" caseSensitive={false} element={<SubscriptionData />} />
                        <Route path="subscription/transaction" caseSensitive={false} element={<SubscriptionTransaction />} />
                        <Route path="user/list" caseSensitive={false} element={<User />} />
                        <Route path="ads/contacted-ads" caseSensitive={false} element={<ContactedAds />} />
                        <Route path="ads/ads" caseSensitive={false} element={<Ads />} />
                        <Route path="product/list" caseSensitive={false} element={<Product />} />
                        <Route path="category/category" caseSensitive={false} element={<Category />} />
                        <Route path="category/sub-category" caseSensitive={false} element={<SubCategory />} />
                        <Route path="category/properties" caseSensitive={false} element={<Properties />} />

                        <Route path="order/list" caseSensitive={false} element={<OrderList />} />
                        <Route path="order-review/list" caseSensitive={false} element={<OrderReview />} />
                        <Route path="order-review/confirmation" caseSensitive={false} element={<OrderReviewConfirmation />} />

                        <Route path="tender/list" caseSensitive={false} element={<TenderList />} />
                        <Route path="tender/confirmation" caseSensitive={false} element={<Tenderconfirmation />} />
                        <Route path="tender-review/list" caseSensitive={false} element={<TenderReviewList />} />
                        <Route path="tender-review/confirmation" caseSensitive={false} element={<TenderReviewConfirmation />} />
                        
                        <Route path="payment/data" caseSensitive={false} element={<Payment />} />
                        <Route path="payment/wallet" caseSensitive={false} element={<Wallet />} />
                        <Route path="withdraw/history" caseSensitive={false} element={<WithdrawHistory />} />
                        <Route path="withdraw/confirmation" caseSensitive={false} element={<WithdrawConfirmation />} />
                        <Route path="withdraw/request" caseSensitive={false} element={<WithdrawRequest />} />

                        <Route path="cms/general" caseSensitive={false} element={<GeneralSection />} />
                        <Route path="cms/general/general" caseSensitive={false} element={<GeneralSection />} />
                        <Route path="cms/general/information" caseSensitive={false} element={<InformationSection />} />
                        
                        <Route path="cms/home/slider-section" caseSensitive={false} element={<SliderSection />} />
                        <Route path="cms/home/download-section" caseSensitive={false} element={<DownloadSection />} />
                        <Route path="cms/home/feature" caseSensitive={false} element={<Feature />} />
                        <Route path="cms/home/testimonial" caseSensitive={false} element={<Testimonial />} />
                        <Route path="cms/home/instagram" caseSensitive={false} element={<Instagram />} />

                        <Route path="cms/menu/about-us" caseSensitive={false} element={<AboutUs />} />
                        <Route path="cms/menu/privacy-policy" caseSensitive={false} element={<PrivacyPolicy />} />
                        <Route path="cms/menu/terms-conditions" caseSensitive={false} element={<TermsConditions />} />
                        <Route path="cms/menu/contact-us" caseSensitive={false} element={<ContactUs />} />
                        <Route path="cms/menu/advertise" caseSensitive={false} element={<Advertise />} />
                        
                        <Route path="staff/list" caseSensitive={false} element={<Staff />} />
                        <Route path="language-string" caseSensitive={false} element={<LanguageString />} />

                        <Route path="change-password" caseSensitive={false} element={<ChangePassword />} />
                        <Route path="my-profile" caseSensitive={false} element={<MyProfile />} />
                        <Route path="my-wallet-info" caseSensitive={false} element={<WalletInfo />} />
                        <Route path="transaction" caseSensitive={false} element={<SellerSubscriptionData />} />
                        <Route path="recent-work" caseSensitive={false} element={<RecentWork />} />

                        <Route path="report/transaction-list" caseSensitive={false} element={<Transaction />} />
                        <Route path="report/product-sales-list" caseSensitive={false} element={<TotalProductSales />} />
                        <Route path="report/new-user-list" caseSensitive={false} element={<NewUser />} />
                        <Route path="report/active-user-list" caseSensitive={false} element={<ActiveUser />} />
                        <Route path="report/newcompany-list" caseSensitive={false} element={<NewCompany />} />
                        <Route path="report/active-company-list" caseSensitive={false} element={<ActiveCompany />} />
                        <Route path="report/total-product-list" caseSensitive={false} element={<TotalProduct />} />
                        <Route path="report/total-tender-list" caseSensitive={false} element={<TotalTender />} />
                        <Route path="report/running-tender-list" caseSensitive={false} element={<RunningTender />} />

                    </Route>
    
                    <Route path="*" element={<ErrorPage />} />
                
                </Routes>
            </Context.Provider>
        </>
    )
}