import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, noimageHandle, blobToBase64, ConfirmationModal, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, GET_PRODUCT_LIST, CHECK_SUBSCRIPTION, COMPANY_LIST_ONLY, PRODUCT_CREATE, PRODUCT_UPDATE, PRODUCT_DELETE, ASSET_ENDPOINT } from "../../components/Service";
import ComboSelect from "../../components/ComboSelect";

function Ads() {
    window.document.title = "Product List";
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [updatetable, setUpdatetable] = useState(true);
    const [category, setCategory] = useState('')
    const [categoryName, setCategoryName] = useState('')
    const [offcanvastitle, setOffcanvastitle] = useState("Add New Product")
    const [userType, setUserType] = useState('admin')
    const [checkSubscriptionData, setCheckSubscriptionData] = useState(false);
    const [formfield, setFormfield] = useState({
        company_id: "",
        product_id: "",
        product_name: "",
        product_price: "",
        product_stock: "",
        product_condition: "",
        product_image: "/",
        product_imageb64: "",
        product_description: "",
        product_lat: 25.3548,
        product_lang: 51.1839,
        is_visible: 1,
        company_list_only: "",
        subcategory_list_only: "",
        subcategory_list_only_selected: "",
        subcategory_label_type: ""
    })

    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: GET_PRODUCT_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5, 10, 15, 20, 30]
    })

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            setUserType(user.userType)
        }
        getSeletectedSubCateg(user.company_id)
        if(user && user.userType === 'admin'){
            setCheckSubscriptionData(true)
            getData()
        }else{
            fetchData(CHECK_SUBSCRIPTION, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setCheckSubscriptionData(res.records.status)
                    if(res.records.status === 1){
                        getData()
                        getSeletectedSubCateg(user.company_id)
                    }
                }
            }, (err) => {});
        }


        document.getElementById("addupdateProduct").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setOffcanvastitle("Add New Product");
            setFormfield(prevState => ({ /*Set empty state*/
                ...prevState,
                company_id: "",
                product_id: "",
                product_name: "",
                product_price: "",
                product_stock: "",
                product_condition: "",
                product_image: "/",
                product_imageb64: "",
                product_description: "",
                is_visible: 1,
                product_lat: 25.3548,
                product_lang: 51.1839,
                subcategory_label_type: ""
            }))
        });

        document.getElementById("latlangModal").addEventListener('shown.bs.modal', function (e) {
            
        });
        

        if (formfield.company_list_only===''){
            companyListingShow();
        }

    }, [dt, updatetable, formfield.company_list_only,])

    const getData = () => {
        loadingData(true)
        tableSpinner(true, 'productTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'productTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });
    }
    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }



    /*End | Datatable Methods*/

    
    const companyListingShow = () => {
        fetchData(COMPANY_LIST_ONLY, 'GET', '', true, false, (res) => {
            if (res.status) {
                setFormfield(prevState => ({
                    ...prevState,
                    company_list_only: res.records,
                }))
            } else {
                toastNotify('danger', res.message)
            }
        })
    }

    const getSeletectedSubCateg = (e, callback) => {
        loadingData(true);
        setFormfield(prevState => ({
            ...prevState,
            subcategory_label_type: ""
        }))
        fetchData(COMPANY_LIST_ONLY + '?type=company_id&company_id=' + e, 'GET', '', true, true, (res) => {   
            loadingData(false);       
            let newCustomizeArray = Object.entries(res.records).map((ele, i)=>{
                return (ele[1]['subcategory_id']+':'+ele[1]['name'])
            }).join(',');
            if (res.status) {
                setFormfield(prevState => ({
                    ...prevState,
                    subcategory_list_only: Object.fromEntries(newCustomizeArray.split(',').map(i => i.split(':'))),
                    subcategory_list_only_selected: ""
                }))
                document.querySelector(`[data-mslect="subcategorySelect"] .select-dropdown`).innerHTML = '';
                [...document.querySelectorAll(`[data-mslect="subcategorySelect"] .dropdown-item`)].forEach(element => {
                    element.classList.remove('active');
                });
                setCategory(res.records.category_name)
                return callback()

            } else {
                toastNotify('danger', res.message)
            }  
        })
    }

    const getSeletectedSubCategHandler = (e, callback) => {
        loadingData(true);
        setFormfield(prevState => ({
            ...prevState,
            subcategory_label_type: ""
        }))
        fetchData(COMPANY_LIST_ONLY + '?type=subcategory_id&subcategory_id='+e, 'GET', '', true, true, (res) => {
            loadingData(false);
            if (res.status) {
                setFormfield(prevState => ({
                    ...prevState,
                    subcategory_label_type: res.records.property,
                }))
                setCategoryName(res.records.category_name)

                return callback();
            } else {
                toastNotify('danger', res.message)
            }
        })
    }


    const adsFunction = async (e) => {
        let formdata = new FormData(document.getElementById('addupdateProduct'));
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            if (formfield.product_id === null || formfield.product_id ===''){
                fetchData(PRODUCT_CREATE, 'POST', formdata, true, true, (res) => {
                    setTinyloader(false);

                    if (res.status) {
                        toastNotify('success', res.message)
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click();
                        setUpdatetable(false);
                    }
                    else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                }, (err) => { });
            }
            else{
                fetchData(PRODUCT_UPDATE + '/' + formfield.product_id, 'POST', formdata, true, true, (res) => {
                    if (res.status) {
                        toastNotify('success', res.message);
                        document.querySelector('[data-bs-dismiss="offcanvas"]').click()
                        setUpdatetable(false);
                    } else {
                        if (Object.keys(res.errors).length > 0) {
                            [res.errors].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        } else {
                            toastNotify('danger', res.message)
                        }
                    }
                    setTinyloader(false);
                }, (err) => { });
            }
        }
    }

    const editForm = async (getid) => {
        loadingData(true);
        fetchData(PRODUCT_UPDATE + '/' + getid, 'GET', '', true, false, (res) => {
            loadingData(false);
            if (res.status) {
                
                setFormfield(prevState => ({
                    ...prevState,
                    product_id: res.records.product_id,
                    product_image: res.records.product_image,
                    product_name: res.records.product_name,
                    product_price: res.records.product_price,
                    product_stock: res.records.product_stock,
                    product_condition: res.records.product_condition,
                    product_description: res.records.product_description,
                    company_id: res.records.company_id.toString(),
                    subcategory_id: res.records.subcategory_id.toString(),
                    product_lat: Number(res.records.product_location.lat),
                    product_lang: Number(res.records.product_location.lng),
                    is_visible : Number(res.records.is_visible)
                }))

                getSeletectedSubCateg(
                    res.records.company_id.toString(), function(){
                    setFormfield(prevState => ({
                        ...prevState,
                        subcategory_list_only_selected: res.records.subcategory_id.toString()
                    }));

                    getSeletectedSubCategHandler(res.records.subcategory_id.toString() ,function() {
                        setTimeout(() => {
                            Object.entries(res.records.product_props).map((v) => {
                                document.querySelector(`[name="${v[0]}"]`).value = v[1]
                            });
                        }, 0);
                    })
                }) /*Set Company_ID*/

                setOffcanvastitle("Update Product");
                new Offcanvas(document.getElementById('addupdateProduct')).show();
            }
            else {
                toastNotify('danger', res.message)
            }
        }, (err) => { });
        // setOffcanvastitle("Update Product");
        // setFormfield(rec)    
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0]);
        setFormfield(prevState => ({
            ...prevState,
            product_imageb64: imgb64
        }))
    }

    const deleteRecords = (id) =>{
        setUpdatetable(true);
        fetchData(PRODUCT_DELETE+'/'+id, 'GET', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                toastNotify('success', res.message)
                setUpdatetable(false);
                setFormfield(prevState => ({
                    ...prevState,
                    product_id: ""
                }))
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }


    /*Start | Google MAP */
    const { isLoaded } = useJsApiLoader({
        id: 'google_map',
        googleMapsApiKey: "AIzaSyBDHTbmCkeabgRvw-a2oLyCBuuhVRmyPCA"
    })
    const [map, setMap] = React.useState(null);
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();

        var myMarker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(formfield.product_lat, formfield.product_lang),
            draggable: true
        });

        window.google.maps.event.addListener(myMarker, 'dragend', function (evt) {
            setFormfield(prevState => ({
                ...prevState,
                product_lat: evt.latLng.lat().toFixed(3),
                product_lang: evt.latLng.lng().toFixed(3)
            }));
            document.querySelector('[name="product_lat"]').value = evt.latLng.lat().toFixed(3);
            document.querySelector('[name="product_lang"]').value = evt.latLng.lng().toFixed(3);
        });

        window.google.maps.event.addListener(myMarker, 'dragstart', function (evt) {
        });

        map.setCenter(myMarker.position);
        myMarker.setMap(map);
        // map.fitBounds(bounds);
        // setMap(map)

    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    /*Start | Google MAP */


    return (
        <>
            
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Product List</h4>
                        </div>
                    </div>
                    <div className="row">
                        { checkSubscriptionData ? 
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} />
                                </div>
                                <div className="col ms-auto d-flex flex-wrap">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                            {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="ms-auto d-flex">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 text-nowrap mx-2" data-bs-toggle="offcanvas" data-bs-target="#addupdateProduct" onClick={() => setCategoryName('')}>
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner active" id="productTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th width="80">Product Preview</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="product_name" onClick={(e) => dtActions(e, 'sortby')}>Product Name</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="product_price" onClick={(e) => dtActions(e, 'sortby')}>Product Price (QAR)</th>
                                                <th className="minw-160" data-orderby="asc" data-sortby="product_stock" width="130" onClick={(e) => dtActions(e, 'sortby')}>Product Stock</th>
                                                <th data-orderby="asc" data-sortby="product_condition" width="130" onClick={(e) => dtActions(e, 'sortby')}>Product Condition</th>
                                                <th data-orderby="asc" data-sortby="is_visible" width="130" onClick={(e) => dtActions(e, 'sortby')}>Visible?</th>
                                                <th width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>
                                                        <img src={ASSET_ENDPOINT + ele.product_image} alt={"adsimage"} className="w100-h50 rounded shadow-sm objfit-cover bg-light" onError={noimageHandle} />
                                                    </td>
                                                    <td>{ele.product_name}</td>
                                                    <td>{ele.product_price}</td>
                                                    <td>{ele.product_stock}</td>
                                                    <td>
                                                        {
                                                            ele.product_condition==='new'? <span className="text-success">New</span>:<span className="text-primary">Used</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {ele.is_visible?'Visible':'Hidden'}
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <button className="btn btn-sm text-success mx-1" title="Edit" onClick={() => editForm(ele.product_id)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm text-danger mx-1" data-bs-toggle="modal" data-bs-target="#confModal" title="Delete" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, product_id: ele.product_id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    </td>
                                                </tr>)
                                            })
                                            :
                                            records===0?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                        records && records !== '' ? records.links.map((ele, num) => {
                                            return (
                                                <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url===null?'disabled':''}`} key={num}>
                                                    <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                        {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                    </span>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        : 
                        <div className="col-sm-12" style={{'min-height':'calc(100vh - 160px)'}}>
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <div className="d-block text-center">
                                    <p>You need to extend your subscription first.</p>
                                    <button type="button" className="btn btn-primary rounded-pill px-4" onClick={()=>navigate(`/${userType}/transaction`)}>Subscribe First</button>
                                </div>
                            </div>
                            
                        </div>
                        }
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="addupdateProduct" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">{offcanvastitle}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="image-uploading d-flex align-items-center mb-3">
                        <img src={!formfield.product_imageb64 ? ASSET_ENDPOINT + '/' + formfield.product_image : formfield.product_imageb64} alt="product_image" className="me-3" onError={noimageHandle} />
                        <button type="button" className="btn btn-primary text-nowrap">Upload Product Image</button>
                        <input type="file" accept="image/*" className="form-field" name="product_image" title="Upload Product Image" onChange={async (e) => setFormfield(prevState => ({
                            ...prevState, 
                            product_image: imageSet(e),
                        }))} required={formfield.product_id === "" ? true : false}/>
                        <div className="invalid-feedback">
                            Required to upload image.
                        </div>
                    </label>
                    { userType && userType === 'admin' ? 
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Company<strong className="text-danger">*</strong></div>
                        <ComboSelect
                            name="company_id"
                            options={formfield.company_list_only}
                            selected={formfield.company_id}
                            method={getSeletectedSubCateg}
                            id="companyselect"
                            placeholder="Select"
                            required="requied"
                            type="single"
                            key="1"
                        />
                    </label>
                    : '' }
                    { categoryName ? 
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Category Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control form-field" name="category_name" disabled defaultValue={categoryName} />
                    </label> : ''}
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Subcategory<strong className="text-danger">*</strong></div>
                        <ComboSelect
                            name="subcategory_id"
                            options={formfield.subcategory_list_only}
                            selected={formfield.subcategory_list_only_selected}
                            method={getSeletectedSubCategHandler}
                            id="subcategorySelect"
                            placeholder="Select"
                            required="requied"
                            type="single"
                            key="1"
                        />
                    </label>

                    {
                        formfield.subcategory_label_type && formfield.subcategory_label_type.map((elem, ind)=>{
                            if (elem.props_input_type === 'text' || elem.props_input_type === 'number' || elem.props_input_type === 'date'){
                                return <label className="w-100 mb-3" key={ind}>
                                    <div className="mb-1 fw-medium text-blue small">{elem.label_name}</div>
                                    <input type={elem.props_input_type} defaultValue={""} className="form-control" name={elem.props_code} />
                                </label>
                            }
                            if (elem.props_input_type === 'select'){
                                return <label className="w-100 mb-3" key={ind}>
                                    <div className="mb-1 fw-medium text-blue small">{elem.label_name}</div>
                                    <select className="form-select" defaultValue={""} name={elem.props_code}>
                                    {
                                        elem.props_options !== null ? elem.props_options.map((inval, index)=>{
                                            return <option value={inval} key={index}>{inval}</option>
                                        }):''
                                    }
                                    </select>
                                </label>
                            }
                        })
                    }
                   
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Name<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control form-field" name="product_name" defaultValue={formfield.product_name} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, product_name: e.target.value
                        }))} required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Price (QAR)<strong className="text-danger">*</strong></div>
                        <input type="number" className="form-control form-field" name="product_price" defaultValue={formfield.product_price} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, product_price: e.target.value
                        }))} min="0" step="0.01" pattern="^\d+(?:\.\d{1,2})?$" required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Stock<strong className="text-danger">*</strong></div>
                        <input type="number" className="form-control form-field" name="product_stock" defaultValue={formfield.product_stock} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, product_stock: e.target.value
                        }))} min="0" required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Condition<strong className="text-danger">*</strong></div>
                        <select className="form-select" name="product_condition" value={formfield.product_condition} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, product_condition: e.target.value
                        }))} required>
                            <option value="" className="d-none"></option>
                            <option value="new">New</option>
                            <option value="used">Used</option>
                        </select>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Product Description<strong className="text-danger">*</strong></div>
                        <textarea className="form-control form-field" name="product_description" spellCheck={false} rows={3} defaultValue={formfield.product_description} onKeyUp={(e) => setFormfield(prevState => ({
                            ...prevState, product_description: e.target.value
                        }))} required></textarea>
                    </label>

                    <div className="w-100 d-flex mb-3">
                        <div className="me-2">
                            <div className="input-group input-group-sm1 mb-2">
                                <span className="input-group-text minw-96">Latitude</span>
                                <input type="text" className="form-control" name="product_lat" defaultValue={formfield.product_lat} onKeyUp={(e) => setFormfield(prevState => ({
                                    ...prevState, product_lat: e.target.value
                                }))}/>
                            </div>
                            <div className="input-group">
                                <span className="input-group-text minw-96">Langitude</span>
                                <input type="text" className="form-control" name="product_lang" defaultValue={formfield.product_lang} onKeyUp={(e) => setFormfield(prevState => ({
                                    ...prevState, product_lang: e.target.value
                                }))}/>
                            </div>
                        </div>
                        <button type="button" className="btn btn-secondary" data-bs-target="#latlangModal" data-bs-toggle="modal" title="Set (Latitude & Langitude)">
                            <svg className="icon tscale-1p5 pe-none"><use href="#icon_location"></use></svg>
                        </button>
                    </div>
                    <label>{category}</label>
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Status</div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input form-field minh-22 minw-40 me-2" name="is_visible" type="checkbox" defaultValue={formfield.is_visible} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, is_visible: e.target.checked ? 1 : 0
                            }))} checked={formfield.is_visible === 1 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Visible</div>
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => adsFunction(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
                </div>
            </form>

            <div className="modal fade show1 d-block1" id="latlangModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content shadow">
                        <div className="modal-header">
                            <h5 className="modal-title text-blue">Set Latitude & Langitude</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="ratio ratio-16x9">
                                {isLoaded ? (
                                <GoogleMap
                                    // mapContainerStyle=""
                                    center={{
                                        lat: Number(formfield.product_lat),
                                        lng: Number(formfield.product_lang)
                                    }}
                                    zoom={10}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}>
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <></>
                                </GoogleMap> ) : ''
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="me-auto">
                                <span className="text-blue">Latitude: {formfield.product_lat}</span>
                                <span className="px-3">|</span>
                                <span className="text-blue">Langitude: {formfield.product_lang}</span>
                            </div>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Update</button>
                        </div>
                    </div>
                </div>
            </div>

            
            <ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.product_id)} />
        </>
    )
}

export default Ads