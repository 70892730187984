import React, { useEffect, useState } from "react";
import { fetchFrontData, CONTACT, FOOTER_SECTION, ASSET_ENDPOINT } from "../../components/Service";
import { noimageHandle, scrollbarSetToTop } from "../../components/Helper";
import TopHeader from "../../components/TopHeader";

function CONTACT_US() {

    const[langType, setLangType] = useState(localStorage.getItem('lang_type'))
    const[data, setData] = useState({
        cms__contact_clock: '',
        cms__contact_description: '',
        cms__contact_email: '',
        cms__contact_header: '',
        cms__contact_location: '',
        cms__contact_phone: '',
        cms__contact_title: '',
        cms__contact_working: '',
        company_image: '',
    })

    useEffect(() => {
        scrollbarSetToTop()
        fetchFrontData(CONTACT, 'GET', '', (res) => {
            if(res.status){
                setData({
                    cms__contact_clock: res.records.cms__contact_clock,
                    cms__contact_description: res.records.cms__contact_description,
                    cms__contact_email: res.records.cms__contact_email,
                    cms__contact_header: res.records.cms__contact_header,
                    cms__contact_location: res.records.cms__contact_location,
                    cms__contact_phone: res.records.cms__contact_phone,
                    cms__contact_title: res.records.cms__contact_title,
                    cms__contact_working: res.records.cms__contact_working,
                    company_image: res.records.company_image,
                })
            }

        }, (err) => {})

        fetchFrontData(FOOTER_SECTION, 'GET', '', (res) => {
            if(res.status){
                setData(prevState => ({
                    ...prevState,
                    site_clock: res.records.site_clock,
                    site_email: res.records.site_email,
                    site_footnote: res.records.site_footnote,
                    site_location: res.records.site_location,
                    site_map: res.records.site_map,
                    site_phone: res.records.site_phone,
                    url_facebook: res.records.url_facebook,
                    url_instagram: res.records.url_instagram,
                    url_twitter: res.records.url_twitter,
                }))  
            }
        }, (err) => {})

    }, [langType])

    return(
        <>
            <TopHeader refresh={setLangType} reload={true} />
            <section className={`container py-5 ${langType === 'ur' || langType === 'ar' ? ' direction-rtl' : ''}`}>
                <div className="row g-4 py-lg-3">
                    <div className="col-lg-5">
                        <img src={ASSET_ENDPOINT + data.company_image} onError={noimageHandle} className="w-100 vh-75 rounded" alt="contact" />
                    </div>
                    <div className="col-lg-7 ps-lg-4">
                        <p className="m-0 fw-medium" style={{color: "#18AFE6"}}>{data.cms__contact_header}</p>
                        <h3 className="mt-0 fw-bold title">{data.cms__contact_title}</h3>
                        <div className="pb-3" dangerouslySetInnerHTML={{__html: data.cms__contact_description}}></div>
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <svg className="icon fs-1" style={{color: "#1761AE"}} role="img">
                                                <use href="#icon_mail" />
                                            </svg>
                                            <div className="ps-3">
                                                <h5 className="fw-bold">{data.cms__contact_email}</h5>
                                                <a href={`mailto:${data.url_twitter}`} className="text-decoration-none fw-medium fs-13" style={{color: "#0056b3"}}>{data.url_twitter}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <svg className="icon fs-1" style={{color: "#1761AE"}} role="img">
                                                <use href="#icon_call" />
                                            </svg>
                                            <div className="ps-3">
                                                <h5 className="fw-bold">{data.cms__contact_phone}</h5>
                                                <a href={`tel:${data.site_phone}`} className="text-decoration-none fw-medium fs-13" style={{color: "#0056b3"}}>{data.site_phone}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <svg className="icon fs-1" style={{color: "#1761AE"}} role="img">
                                                <use href="#icon_location" />
                                            </svg>
                                            <div className="ps-3">
                                                <h5 className="fw-bold">{data.cms__contact_location}</h5>
                                                <a href={data.site_location} className="text-decoration-none fw-medium fs-13" style={{color: "#0056b3"}}>{data.site_location}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card h-100 text-center">
                                    <div className="card-body">
                                        <svg className="icon fs-1" style={{color: "#1761AE"}} role="img">
                                            <use href="#icon_clock" />
                                        </svg>
                                        <h5 className="fw-bold">{data.cms__contact_clock}</h5>
                                        <hr className="w-50 mx-auto opacity-10" />
                                        <div dangerouslySetInnerHTML={{__html: data.cms__contact_working}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )   
}
export default CONTACT_US