import React, { useState, useEffect } from "react";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, tableSpinner, loadingData } from "../../components/Helper";
import { fetchData, NOTIFICATION_LIST, ADD_NOTIFICATION, READ_NOTIFICATION } from "../../components/Service";

function NotificationList() {
    window.document.title = "Notification List";
    const [updatetable, setUpdatetable] = useState(true);
    const [tinyloader, setTinyloader] = useState(false);
    const [userType, setUserType] = useState('admin')

    const [formfield, setFormfield] = useState({
        title: "",
        message: "",
        show_user: 0,
        show_company: 0
    });

    /*Start | Datatable Methods*/
    const [records, setRecords] = useState("");
    const [dt, setDt] = useState({
        fetchurl: NOTIFICATION_LIST,
        search: '',
        perpage: '10',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        pagelength: [5,10,15,20,30]
    })

    useEffect(() => {
        loadingData(true)
        let user = JSON.parse(localStorage.getItem('tw-records'))
        if(user && user.userType){
            setUserType(user.userType)
        }
        tableSpinner(true, 'notificationTable')
        Datatable(dt, (result) => {
            loadingData(false)
            tableSpinner(false, 'notificationTable')
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
        });

        document.getElementById("sendNotificationForm").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset()
            e.target.classList.remove('was-validated');
            setFormfield(prevState => ({ /*Set empty state*/
                ...prevState,
                title: "",
                message: "",
                show_user: 0,
                show_company: 0
            }))
        });

    }, [dt, updatetable])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }
    /*End | Datatable Methods*/


    const sendNotificationMethod = async (e) => {
        if (validateForm(e)) {
            setTinyloader(true);
            setUpdatetable(true);
            fetchData(ADD_NOTIFICATION, 'POST', formfield, true, false, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    document.querySelector('[data-bs-dismiss="offcanvas"]').click();
                    setUpdatetable(false);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const readNotification = (e, id) => {
        e.target.classList.toggle('text-truncate');
        if(userType && userType === 'seller'){
            fetchData(READ_NOTIFICATION+ id, 'GET', '', true, false, (res) => {}, (err) => {});
            localStorage.setItem('isnotify', 'false');
        }
    }
    
    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Notification List</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false" />
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    { userType && userType === 'admin' ? 
                                    <div className="ms-auto">
                                        <button type="button" className="btn btn-primary rounded-pill px-3 ms-auto text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#sendNotificationForm">
                                            <svg className="icon tscale-1p1 me-2" role="img"><use href="#icon_send" /></svg> 
                                            Send <span className="d-none d-md-inline">Notification</span>
                                        </button>
                                    </div> : '' }
                                </div>
                            </div>
                            <div className="table-spinner active" id="notificationTable">
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead className="text-nowrap">
                                            <tr>
                                                { userType && userType === 'seller' ? 
                                                <th data-orderby="asc" data-sortby="created_at" onClick={(e) => dtActions(e, 'sortby')}>Date</th>
                                                : '' }
                                                <th className="minw-200" data-orderby="asc" data-sortby="title" onClick={(e) => dtActions(e, 'sortby')}>Title</th>
                                                <th className="minw-200" data-orderby="asc" data-sortby="message" onClick={(e) => dtActions(e, 'sortby')}>Message</th>
                                                { userType && userType === 'admin' ? <>
                                                <th data-orderby="asc" data-sortby="user_id" onClick={(e) => dtActions(e, 'sortby')}>Show User?</th>
                                                <th data-orderby="asc" data-sortby="company_id" onClick={(e) => dtActions(e, 'sortby')}>Show Company?</th>
                                                </> : '' }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num} onClick={(e) => readNotification(e,ele.id)}>
                                                    { userType && userType === 'seller' ? <td>{new Date(ele.created_at).toDateString()}</td> : '' }
                                                    <td>{ele.title}</td>
                                                    <td className="text-truncate maxw-320">{ele.message}</td>
                                                    { userType && userType === 'admin' ? <>
                                                    <td>{ele.user_id === 0 ? 'Yes' : 'No'} </td>
                                                    <td>{ele.company_id === 0 ? 'Yes' : 'No'}</td> </> : '' }
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={userType && userType === 'admin' ? 4 : 3}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={userType && userType === 'admin' ? 4 : 3}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="sendNotificationForm" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="text-blue m-0">Send New Notification</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Title<strong className="text-danger">*</strong></div>
                        <input type="text" className="form-control" name="title" id="title" defaultValue={formfield.title} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, title: e.target.value
                        }))} autoComplete="off" required />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="mb-1 fw-medium text-blue small">Notification Message<strong className="text-danger">*</strong> ({formfield.message.length}/1000)</div>
                        <textarea className="form-control" name="message" id="message" spellCheck={false} maxLength="1000" rows={8} defaultValue={formfield.message} onChange={(e) => setFormfield(prevState => ({
                            ...prevState, message: e.target.value
                        }))} required></textarea>
                    </label>
                    <div className="mb-3">
                        <div className="mb-1 fw-medium text-blue small">Recipient</div>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input minh-22 minw-40 me-2" name="show_user" type="checkbox" defaultValue={formfield.show_user} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, show_user: e.target.checked ? 0 : ''
                            }))} checked={formfield.show_user === 0 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Send to User</div>
                        </label>
                        <label className="form-check form-switch mb-2">
                            <input className="form-check-input minh-22 minw-40 me-2" name="show_company" type="checkbox" defaultValue={formfield.show_company} onChange={(e) => setFormfield(prevState => ({
                                ...prevState, show_company: e.target.checked ? 0 : ''
                            }))} checked={formfield.show_company === 0 ? true : false} />
                            <div className="align-middle d-inline-block lh-1">Send to Seller</div>
                        </label>
                    </div>
                </div>
                <div className="offcanvas-footer text-center text-sm-start border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => sendNotificationMethod(e)} disabled={tinyloader}>
                        {
                            !tinyloader ? 'Submit'
                            :
                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Cancel">Cancel</button>
                </div>
            </form>
        </>
    )
}

export default NotificationList