import React, { useState, useEffect } from "react";
import { Modal } from "bootstrap";
import { Datatable, dtControllers } from "../../components/Datatable";
import { toastNotify, validateForm, loadingData } from "../../components/Helper";
import "react-datepicker/dist/react-datepicker.css";
import { fetchData, CHECK_SUBSCRIPTION, SELLER_SUBSCRIPTION_LIST, ADD_SUBSCRIPTION_FOR_PAYMENT, REVENUE_LIST, SELLER_SUBSCRIPTION_FEILD_DATA, PAYMENT_VERIFY, UPDATE_BANK_INFO } from "../../components/Service";
import { useLocation, useNavigate, Link } from "react-router-dom";

function SellerSubscriptionData() {
    window.document.title = "Transaction Data";
    const [checkSubscriptionData, setCheckSubscriptionData] = useState({
        subscription_status: '',
        subscription_duration: '',
        expired_at: '',
        title: '',
    });
    const location = useLocation()
    const navigate = useNavigate()
    const [tinyloader, setTinyloader] = useState(false);
    const [walletInfo, setWalletInfo] = useState(false);
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [subscriptionList, setSubscriptionList] = useState('');
    const [formfield, setFormfield] = useState({
        subscriptionmenu_id: "",
        title: "",
        price: "",
        total: "",
        discount: "",
    });

    const [records, setRecords] = useState("");
    const [tablespinner, setTablespinner] = useState(true)

    const [dt, setDt] = useState({
        fetchurl: REVENUE_LIST,
        search: '',
        perpage: '5',
        pagenumber: '?page=1',
        sortcolumn: '',
        sortorder: 'desc',
        startdate: '',
        enddate: '',
        pagelength: [5, 10, 15, 20, 30]
    })

    const checkSubscription = () => {

        fetchData(UPDATE_BANK_INFO, 'GET', '', true, false, (res) => {
            loadingData(false)
            if (res.status) {
                setWalletInfo(res.records)
            } else {
                setWalletInfo(false)
            }
        }, (err) => {});

        fetchData(CHECK_SUBSCRIPTION, 'GET', '', true, true, (res) => {
            if (res.status) {
                setCheckSubscriptionData({
                    subscription_status: res.records.status,
                    subscription_duration: res.records.duration,
                    expired_at: res.records.expired_at,
                    title: res.records.title,
                })
            }
        }, (err) => {});
    }

    useEffect(() => {
        loadingData(true)
        if(location.search && location.search.includes('transaction_id')){
            updateSubscription(location.search)
            checkSubscription()
            navigate('/seller/transaction')
        }
        checkSubscription()
        setTablespinner(true)
        Datatable(dt, (result) => {
            if (result.data.length > 0) {
                setRecords(result)
            } else {
                setRecords(0)
            }
            loadingData(false)
            setTablespinner(false)
        });

        document.getElementById("subscriptionForm").addEventListener('show.bs.offcanvas', function (e) {
            sellerSubscriptionList()
        });

        document.getElementById("subscriptionForm").addEventListener('hidden.bs.offcanvas', function (e) {
            e.target.reset();
            e.target.classList.remove('was-validated');
            setFormfield({
                subscriptionmenu_id: "",
                title: "",
                price: "",
                discount: "",
            })
        });

    }, [dt, location.search, navigate])

    const dtActions = (e, type) => {
        dtControllers(e, type, (get_response) => {
            setDt(prevState => ({
                ...prevState,
                ...get_response
            }))
        })
    }



    const sellerSubscriptionList = () =>{
        fetchData(SELLER_SUBSCRIPTION_LIST, 'GET', '', true, false, (res) => {
            if (res.status) {
                setSubscriptionList(res.records)
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }
    
    const autofillData = (e) => {
        fetchData(SELLER_SUBSCRIPTION_FEILD_DATA+ e.target.value, 'GET', '', true, false, (res) => {
            if (res.status) {
                setFormfield({
                    subscriptionmenu_id: res.records.subscriptionmenu_id,
                    title: res.records.title,
                    price: res.records.price,
                    total: res.records.total,
                    discount: res.records.discount,
                })
            } else {
                toastNotify('danger', res.message)
            }
        }, (err) => {});
    }

    const addSubscriptionForPayment = (e) => {
        if(validateForm(e)) {
            loadingData(true);
            setTinyloader(true);
            fetchData(ADD_SUBSCRIPTION_FOR_PAYMENT, 'POST', formfield, true, false, (res) => {
                setTinyloader(false);
                loadingData(false);
                if (res.status) {
                    setFormfield(prevState => ({
                        ...prevState,
                        email: res.records.email,
                        payment_id: res.records.payment_id,
                        checkout_url: res.records.checkout_url
                    }))
                    setCheckoutUrl(res.records.checkout_url)
                    new Modal(document.getElementById('paymentFormModal')).show();
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        };
    }


    const gotoPayment = () => {
        document.querySelector('#subscriptionForm [data-bs-dismiss="offcanvas"]').click();
        document.querySelector('#paymentFormModal [data-bs-dismiss="modal"]').click();
        window.open(checkoutUrl, '_self')
    }

    const updateSubscription = (data) => {

        setTinyloader(true);
        fetchData(PAYMENT_VERIFY+ data, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            if (res.status) {
                toastNotify('success', res.message)

                Datatable(dt, (result) => {
                    if (result.data.length > 0) {
                        setRecords(result)
                    } else {
                        setRecords(0)
                    }
                    loadingData(false)
                    setTablespinner(false)
                });

            } else {
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.message)
                }
            }
        }, (err) => {});
    }

    // const getDateRange = (startDate, endDate) => {
    //     setDt(prevState => ({
    //         ...prevState,
    //         startdate: startDate,
    //         enddate: endDate,
    //     }))
    // }
    
    /*End | Datatable Methods*/

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Transaction Data</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mb-3">
                            <div className="d-inline-flex flex-wrap bg-white px-2 py-1 rounded">
                                { checkSubscriptionData.subscription_duration ? 
                                 <>
                                 <div className="d-flex px-3 py-1 me-2 my-1">
                                     <strong>Your last Subscription:</strong> 
                                 </div>
                                 <div className="d-flex bg-secondary-light-15 px-3 py-1 me-2 my-1 rounded-pill">
                                     <span className="me-2">Subscription Type:</span> 
                                     <span className="fw-bold">{checkSubscriptionData.title}</span>
                                 </div>
                                 <div className="d-flex bg-secondary-light-15 px-3 py-1 me-2 my-1 rounded-pill">
                                     <span className="me-2">Subscription Status:</span> 
                                     <span className="fw-bold">{checkSubscriptionData.subscription_status === 0 ? 'Expired' : 'Active'}</span>
                                 </div>
                                 <div className="d-flex bg-secondary-light-15 px-3 py-1 me-2 my-1 rounded-pill">
                                     <span className="me-2">Subscription Duration:</span> 
                                     <span className="fw-bold">{checkSubscriptionData.subscription_duration}</span>
                                 </div>
                                 <div className="d-flex bg-secondary-light-15 px-3 py-1 me-2 my-1 rounded-pill">
                                     <span className="me-2">Expired At:</span>
                                     <span className="fw-bold">{new Date(checkSubscriptionData.expired_at).toDateString()}</span>
                                 </div>
                                 </>
                                : 
                                <div className="d-flex px-3 py-1 me-2 my-1">
                                    { walletInfo ? <strong>You need to extend your subscription.</strong> : <strong>You need to add your Bank information first.</strong> }
                                </div> }
                                
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
                                    <input type="search" className="form-control form-search minw-280" placeholder="Search" onInput={(e) => dtActions(e, 'search')} spellCheck="false"/>
                                </div>
                                <div className="col ms-auto d-flex">
                                    <div className="me-2 dropdown w-auto rounded-pill d-inline-flex">
                                        <button type="button" className="btn btn-pageof minw-130 bg-white rounded-pill dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                                            <span>
                                                {records.from ? records.from : '0'}-{records.to ? records.to : '0'}
                                            </span> of
                                            <span> {records.total ? records.total : '0'}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-15 px-2 minw-auto text-center">
                                            {
                                                dt && dt.pagelength.map((ele, num) => {
                                                    return (<li role="row" key={num}>
                                                        <button type="button" title={ele} className="dropdown-item rounded-20 text-blue py-2"
                                                            onClick={() => dtActions(ele, 'perpage')}>{ele}</button>
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>

                                    {/* <div className="ms-auto minw-280">
                                        <div className="input-group input-group-pill flex-nowrap">
                                            <div className="input-group-text">
                                                <svg className="icon tscale-1p3" role="img"><use href="#icon_calendar" /></svg>
                                            </div>
                                            <DateRangeFilter 
                                                daterange=""
                                                className="form-control"
                                                maxDate={new Date()}
                                                opens="left"
                                                name='d_daterange'
                                                placeholder="Select Date Range"
                                                disableUpdateInput={false}
                                                getDateRange={getDateRange}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="ms-auto">
                                        { walletInfo ? 
                                        <button type="button" className="btn btn-primary w-100 rounded-pill text-nowrap" data-bs-toggle="offcanvas" data-bs-target="#subscriptionForm">
                                            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> Extends Subscription
                                        </button> : 
                                        <Link to="/seller/my-wallet-info" title="Bank Info" className="btn btn-primary w-100 rounded-pill text-nowrap">
                                            <svg className="icon tscale-1p3 opacity-75 me-3" role="img" aria-label="Help">
                                                <use href="#icon_bank" />
                                            </svg>
                                            Bank Info
                                        </Link> }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="table-spinner" data-tablespinner={tablespinner}>
                                <div className="table-responsive">
                                    <table className="table-common">
                                        <thead>
                                            <tr>
                                                <th data-orderby="asc" data-sortby="date" onClick={(e) => dtActions(e, 'sortby')}>DATE</th>
                                                <th data-orderby="asc" data-sortby="title" onClick={(e) => dtActions(e, 'sortby')}>SUBSCRIPTION MENU</th>
                                                <th data-orderby="asc" data-sortby="subscription_code" onClick={(e) => dtActions(e, 'sortby')}>SUBSCRIPTION CODE</th>
                                                <th data-orderby="asc" data-sortby="transaction_code" onClick={(e) => dtActions(e, 'sortby')}>TRANSACTION CODE</th>
                                                <th data-orderby="asc" data-sortby="expired_at" onClick={(e) => dtActions(e, 'sortby')}>EXPIRED AT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records && records !== '' ? records.data.map((ele, num) => {
                                                return (<tr role="row" key={num}>
                                                    <td>{new Date(ele.date).toDateString()}</td>
                                                    <td>{ele.title}</td>
                                                    <td>{ele.subscription_code}</td>
                                                    <td>{ele.transaction_code}</td>
                                                    <td>{new Date(ele.expired_at).toDateString()}</td>
                                                </tr>)
                                            })
                                            :
                                            records === 0 ?
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="minh-40 d-flex align-items-center justify-content-center text-danger">
                                                            No Record Found
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={7}><div className="minh-40 d-flex align-items-center">Loading...</div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-center justify-content-md-end">
                                        {
                                            records && records !== '' ? records.links.map((ele, num) => {
                                                return (
                                                    <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                                                        <span className="page-link" role={"button"} onClick={() => dtActions(ele.url, 'pagination')}>
                                                            {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            :
                                            null
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <form className="offcanvas offcanvas-end needs-validation" tabIndex="-1" id="subscriptionForm" data-bs-backdrop="true" data-bs-keyboard="false" noValidate>
                <div className="offcanvas-header border-bottom bg-light px-sm-4">
                    <h5 className="m-0 text-blue">EXTEND SUBSCRIPTION</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-3 p-sm-4">
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Subscription Menu<strong className="text-danger">*</strong></div>
                        <select className="form-control" name="subscription_menu" onChange={(e) => autofillData(e)} required >
                            { formfield.discount ? '' : <option></option> }
                            { subscriptionList ? subscriptionList.map((item, key) => {
                                return(<option key={key} value={item.subscriptionmenu_id}>{item.title}</option>)
                            }) : ''}

                        </select>
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Price (QAR)</div>
                        <input type="number" className="form-control" name="price" min="0" step=".01" defaultValue={formfield.price} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Discount (%)</div>
                        <input type="number" className="form-control" name="discount" min="0" step=".01" defaultValue={formfield.discount} disabled />
                    </label>
                    <label className="w-100 mb-3">
                        <div className="form-label small text-blue fw-medium mb-1">Total</div>
                        <input type="number" className="form-control" name="discount" min="0" step=".01" defaultValue={formfield.total} disabled />
                    </label>
                </div>
                <div className="offcanvas-footer border-top bg-light p-3 px-sm-4">
                    <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={(e) => addSubscriptionForPayment(e)} disabled={tinyloader}>
                    {
                        !tinyloader ? 'Submit'
                        :
                        <div className="spinner-border spinner-border-sm ms-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                    </button>
                    <button type="button" className="btn btn-outline-danger rounded-pill px-4" data-bs-dismiss="offcanvas" aria-label="Close">Cancel</button>
                </div>
            </form>

            {/* Start | Payment Modal */}
            <div className="modal fade" tabIndex="-1" id="paymentFormModal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content rounded-10 shadow-lg overflow-hidden">
                        <div className="modal-header px-sm-4 bg-light">
                            <h4 className="m-0 text-blue">Subscription Menu</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <p className="opacity-75">
                                Please check your order once again before proceed to payment gateway.
                            </p>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="py-2 border"> Payment Code </td>
                                            <td className="py-2 border"> { formfield.payment_id ? formfield.payment_id : '' } </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 border"> Email </td>
                                            <td className="py-2 border"> { formfield.email ? formfield.email : '' } </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="py-2 border"> Subscription Menu </td>
                                            <td className="py-2 border"> { formfield.title ? formfield.title : '' } </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 border"> Subscription Price </td>
                                            <td className="py-2 border"> { formfield.price ? formfield.price : 0 } QAR </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 border"> Subscription Discount </td>
                                            <td className="py-2 border"> { formfield.discount ? formfield.discount : '' } % </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-between alert alert-success fw-bold fs-5">
                                <span>Total:</span>
                                <span> {formfield.total ? formfield.total : 0 } QAR </span> 
                            </div>
                            <div className="text-center pt-1">
                                <button className="btn btn-primary rounded-pill minw-140 fw-medium me-3" onClick={gotoPayment}> Submit </button>
                                <button type="button" data-bs-dismiss="modal" className="btn btn-outline-danger rounded-pill px-4" title='Cancel'>Cancel</button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SellerSubscriptionData
