import React, { useEffect, useState } from 'react';
import { toastNotify, validateForm, loadingData } from '../../components/Helper';
import { fetchData, CHANGE_PASSWORD } from '../../components/Service';

function ChangePassword(){
    window.document.title = "Change Password";
    const [tinyloader, setTinyloader] = useState(false);

    const changePassword = (e) => {
        let form = document.getElementById('changepasswordForm')
        let data = new FormData(form);
        if(validateForm(e)) {
            setTinyloader(true);
            fetchData(CHANGE_PASSWORD, 'POST', data, true, true, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message)
                    form.classList.remove('was-validated')
                    form.reset()
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => {});
        };
        
    }


    useEffect(() => {
        loadingData(true)
        setInterval(() => {
            loadingData(false)
        }, 1000)
    }, [])

    return(
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between d-md-none">
                    <div className="col mb-2">
                        <h4 className="page-heading">Change Password</h4>
                    </div>
                </div>

                <form className="row needs-validation" id="changepasswordForm" noValidate>
                    <div className="col-sm-12">
                        <div className="row mx-0 bg-white rounded-15 p-4 shadow-sm">
                            <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 my-2">
                                <label htmlFor="current_password" className="form-label small text-blue fw-medium mb-1">
                                    Current Password<strong className="text-danger">*</strong>
                                </label>
                                <label className="input-group">
                                    <span className="input-group-text">
                                        <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" className="form-control" name="current_password" placeholder="Current Password" autoComplete="Current Password" spellCheck={false} defaultValue={""} required />
                                </label> 
                            </div>
                            <div className="col-sm-12"></div>
                            <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 my-2">
                                <label htmlFor="new_password" className="form-label small text-blue fw-medium mb-1">
                                    New Password<strong className="text-danger">*</strong>
                                </label>
                                <label className="input-group">
                                    <span className="input-group-text">
                                        <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" className="form-control" name="new_password" placeholder="New Password" autoComplete="New Password" spellCheck={false} defaultValue={""} required />
                                </label>
                            </div>
                            <div className="col-sm-12"></div>
                            <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 my-2">
                                <label htmlFor="confirm_password" className="form-label small text-blue fw-medium mb-1">
                                    New Confirm Password<strong className="text-danger">*</strong>
                                </label>
                                <label className="input-group">
                                    <span className="input-group-text">
                                        <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" className="form-control" name="confirm_password" placeholder="New Confirm Password" autoComplete="New Confirm Password" spellCheck={false} defaultValue={""} required />
                                </label>
                            </div>
                            <div className="col-sm-12 my-2 pt-2">
                                <button type="button" className="btn btn-primary rounded-pill minw-140 fw-medium" onClick={(e) => changePassword(e)} disabled={tinyloader}>
                                    {
                                        !tinyloader ? 'Submit'
                                        :
                                        <div className="spinner-border spinner-border-sm ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default ChangePassword