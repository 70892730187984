export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT

export const LOGIN = "/login"
export const LOGOUT = "/logout"

export const NOTIFICATION_LIST = "/notification-list"
export const ADD_NOTIFICATION = "/add-notification"

export const DASHBOARD_STATISTICS = "/dashboard_statistics"
export const DASHBOARD_NOTIFICATION = "/dashboard_notification"

export const COMPANY_LIST = "/company-list"
export const COMPANY_LIST_ONLY = "/company-list-only"
export const CREATE_COMPANY = "/create_company"
export const UPDATE_COMPANY = "/update_company"
export const DELETE_COMPANY = "/delete_company"
export const COMPANY_VERIFY = "/update_company_status"

export const COMPANY_CATEGORY_LIST = "/category-list-only"
export const USER_LIST = "/user-list"
export const CREATE_USER = "/create_user"
export const GET_UPDATE_USER = "/get-update-user-profile"
export const DELETE_USER = "/delete_user"

export const SUBSCRIPTION_LIST = "/subscription-list"
export const CREATE_SUBSCRIPTION = "/create-subscription"
export const UPDATE_SUBSCRIPTION = "/update-subscription"
export const DELETE_SUBSCRIPTION = "/delete-subscription"
export const SUBSCRIPTION_TRANSACTION_LIST = "/subscription-transaction-list"

export const ADS_LIST = "/ads-list"
export const ADS_CONTACTED_LIST = "/ads-contacted-list"
export const CREATE_ADS = "/create-ads"
export const UPDATE_ADS = "/update-ads"
export const UPDATE_CONTACTED_ADS = "/update-ads-contact"
export const DELETE_ADS = "/delete-ads"
export const DELETE_CONTACT_ADS = "/delete-ads-contact"

export const PRODUCT_LIST = "/product-list"
export const GET_PRODUCT_LIST = "/get-product-list"
export const PRODUCT_CREATE = "/product-create"
export const PRODUCT_DELETE = "/product-delete"
export const PRODUCT_UPDATE = "/get-update-product"

export const CATEGORY_LIST = "/category-list"
export const CATEGORY_CREATE = "/category-create"
export const CATEGORY_DELETE = "/category-delete"
export const CATEGORY_UPDATE = "/get-update-category"
export const SUBCATEGORY_CREATE = "/subcategory-create"
export const SUBCATEGORY_UPDATE = "/get-update-subcategory"
export const SUBCATEGORY_LIST = "/subcategory-list"
export const SUBCATEGORY_DELETE = "/subcategory-delete"

export const PROPERTY_LIST = "/property-list"
export const PROPERTY_CREATE = "/property-create"
export const PROPERTY_UPDATE = "/get-update-property"
export const PROPERTY_DELETE = "/property-delete"
export const PROPERTY_LIST_ONLY = "/property-list-only"
export const PROPERTY_BY_CATEGORY_ID = "/props-by-category-id"

export const ORDER_LIST = "/admin-order-list"
export const ORDER_REVIEW = "/order-review"
export const CHANGE_ORDER_STATUS = "/change-order-status"
export const UPDATE_ORDER_REVIEW = "/update-order-review"
export const DELETE_ORDER_REVIEW = "/delete-order-review"

export const TENDER_LIST = "/admin-tender-list"
export const TENDER_REVIEW = "/tender-review"
export const TENDER_UPDATE = "/tender-update"
export const CONFIRM_TENDER_AND_REVIEW = "/tender-change-status"

export const PAYMENT_LIST = "/payment-list"
export const WALLET_LIST = "/wallet-list"
export const WITHDRAW_LIST = "/withdraw-list"
export const DELETE_WITHDRAW = "/delete-request-withdraw"
export const WITHDRAW_CONFIRMATION = "/confirm-withdraw"

export const GENERAL_SECTION = "/general-section"
export const INFORMATION_SECTION = "/information-section"
export const UPDATE_PRIVACY_POLICY = "/update-privacy-policy"
export const UPDATE_ABOUT_US = "/update-about-us"
export const UPDATE_TERMS_CONDS = "/update-terms-conds"
export const UPDATE_CONTACT_US = "/update-contact-us"
export const UPDATE_ADVERTISE = "/update-advertise"
export const INSTAGRAM_HOME = "/instagram-home"
export const TESTIMONIAL_HOME = "/testimonial-home"
export const FEATURE_HOME = "/feature-home"
export const DOWNLOAD_HOME = "/download-home"
export const SLIDER_HOME = "/slider-home"


export const STAFF_LIST = "/staff-list"
export const CREATE_STAFF = "/create_staff"
export const UPDATE_STAFF = "/update_staff"
export const DELETE_STAFF = "/delete_staff"
export const LANGUAGE_STRING = "/language-string-list"
export const LANGUAGE_STRING_ADD = "/language-string-add"
export const LANGUAGE_STRING_UPDATE = "/update-language-string"

export const CHANGE_PASSWORD = "/change-password"
export const MY_PROFILE = "/update_company_profile"
export const UPDATE_ADMIN_PROFILE = "/update_staff_profile"
export const UPDATE_BANK_INFO = "/update-bank-info"
export const CHECK_SUBSCRIPTION = "/check-subscription"
export const SELLER_SUBSCRIPTION_LIST = "/extend-subscription"
export const SELLER_SUBSCRIPTION_FEILD_DATA = "/extend-subscription-list-data/"
export const PAYMENT_VERIFY = "/payment-verify"
export const RECENT_COMPANY_WORK = "/recent-company-work"
export const RECENT_COMPANY_WORK_DELETE = "/recent-company-work-delete"
export const ADD_SUBSCRIPTION_FOR_PAYMENT = "/add-subscription-for-payment"

export const TRANSACTION_LIST_EXPORT = "/transaction-list-export"
export const TRANSACTION_LIST = "/transaction-list"
export const EXPORT_TRANSACTION_LIST = "/revenue-list-export"
export const REVENUE_LIST = "/revenue-list"
export const SALES_PRODUCT_EXPORT = "/product-sales-list-export"
export const SALES_PRODUCT_LIST = "/product-sales-list"
export const NEW_USER_EXPORT = "/newuser-list-export"
export const NEW_USER_LIST = "/newuser-list"
export const ACTIVE_USER_EXPORT = "/activeuser-list-export"
export const ACTIVE_USER_LIST = "/activeuser-list"
export const NEW_COMPANY_EXPORT = "/newcompany-list-export"
export const NEW_COMPANY_LIST = "/newcompany-list"
export const ACTIVE_COMPANY_EXPORT = "/activecompany-list-export"
export const ACTIVE_COMPANY_LIST = "/activecompany-list"
export const TOTAL_PRODUCT_EXPORT = "/totalproduct-list-export"
export const TOTAL_PRODUCT_LIST = "/totalproduct-list"
export const TOTAL_TENDER_EXPORT = "/total_tender_list_export"
export const TOTAL_TENDER_LIST = "/total_tender_list"
export const RUNNING_TENDER_EXPORT = "/running_tender_list_export"
export const RUNNING_TENDER_LIST = "/running_tender_list"
export const REQUEST_WITHDRAW_LIST = "/request-withdraw-list"
export const UPDATE_REQUEST_WITHDRAW_LIST = "/update-request-withdraw/"
export const ADD_REQUEST_WITHDRAW_LIST = "/add-request-withdraw"
export const READ_NOTIFICATION = "/read-notification/"
export const UNREAD_NOTIFICATION = "/unread-notification"
export const MUTE_NOTIFICATION = "/get-update-notification-setting"
export const FORGOT_PASSWORD = "/forgot-password"
export const RESET_PASSWORD = "/reset-password"
export const ADVERTISE = "/advertise"
export const CONTACT = "/contact-us"
export const ABOUT = "/about-us"
export const PLACING_ADS = "/placing-ads"
export const SITE_INFO = "/site-info"
export const FOOTER_SECTION = "/footer-section"
export const SLIDER = "/slider"
export const DOWNLOAD = "/download"
export const FEATURE = "/feature"
export const TESTIMONIAL = "/testimonial"
export const INSTAGRAM = "/instagram"
export const TERMS = "/terms"
export const PRIVACY = "/privacy"


export const fetchData = async (url, method, data, token, process, res, err, processimg=false) => {
    let headers = {
        'Accept': 'application/json',
    }
    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }
    if (token) {
        let TOKEN = '';
        let gettoken = JSON.parse(localStorage.getItem("tw-records")).accessToken;
        if (url === 'EMAILVERIFY'){
            TOKEN = gettoken ? gettoken : ''
        }else{
            TOKEN = gettoken ? gettoken : ''
        }
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }
    let request = {
        'method': method,
        'headers': headers,
    }
    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }

    let USERTYPE = '/admin' 
    let LANGSTRING = '/en';
    if(url === '/login' || url === '/forgot-password' || url === "/reset-password"){
        USERTYPE = data.get('userType') === 'admin' ? '/admin' : '/seller';
    }else{
        USERTYPE = JSON.parse(localStorage.getItem("tw-records")).userType === 'seller' ? '/seller' : '/admin';
    }
  
    await fetch(`${ENDPOINT}${USERTYPE}${LANGSTRING}${url}`, request).then((response) => processimg === "text" ? response.text() : (processimg === "blob"? response.blob(): response.json() )).then((json) => {
        if (json.message === "Unauthenticated.") {
            localStorage.removeItem("tw-records");
        } else {
            res(json)
        }
    })
    .catch((error) => { });
}

export const fetchFrontData = async (url, method, data, res, err, loader = true) => {
    if(loader){ document.getElementById('loader').classList.remove('active') }
    let request = {
        'method': method,
        'headers': { 'Accept': 'application/json', 'contentType': false, 'processData': false },
    }
    if (data) { request = { ...request, 'body': data } }
    if(method === 'GET'){
        url += localStorage.getItem('lang_type') ? '?lang_type='+ localStorage.getItem('lang_type') : '?lang_type=en'
    }
    await fetch(`${ENDPOINT}/admin/en${url}`, request).then((response) => response.json()).then((json) => {
        setTimeout(() => { document.getElementById('loader').classList.add('active') }, 500)
        res(json)
    }).catch((error) => { document.getElementById('loader').classList.add('active') });
}