import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { fetchFrontData, SITE_INFO, ASSET_ENDPOINT } from "./Service";
import { noimageHandle } from "./Helper";

function FrontendHeader() {

    const[logUser] = useState(JSON.parse(localStorage.getItem('tw-records')))
    const[langType, setLangType] = useState(localStorage.getItem('lang_type'))
    const[reload, setReload] = useState('1')
    const [data, setData] = useState({
        link_about: "",
        link_advertise: "",
        link_contact: "",
        link_home: "",
        link_login: "",
        link_privacy: "",
        link_terms_conds: "",
        site_bottom: "",
        site_icon: "",
        site_logo: "",
        site_logo_alt: "",
        site_logo_alt2: "",
        site_tagline: "",
        site_title: "",
        
    })

    const languageChange = (lang_type = '') => {

        localStorage.setItem('lang_type', lang_type)
        setReload(reload + 1)
        setTimeout(() => {
            setLangType(lang_type)
        }, 50)
    }

    useEffect(() => {
        fetchFrontData(SITE_INFO, 'GET', '', (res) => {
            if(res.status){
                setData({
                    link_about: res.records.link_about,
                    link_advertise: res.records.link_advertise,
                    link_contact: res.records.link_contact,
                    link_home: res.records.link_home,
                    link_login: res.records.link_login,
                    link_privacy: res.records.link_privacy,
                    link_terms_conds: res.records.link_terms_conds,
                    site_bottom: res.records.site_bottom,
                    site_icon: res.records.site_icon,
                    site_logo: res.records.site_logo,
                    site_logo_alt: res.records.site_logo_alt,
                    site_logo_alt2: res.records.site_logo_alt2,
                    site_tagline: res.records.site_tagline,
                    site_title: res.records.site_title,
                })
                window.document.title = res.records.site_title;
                if(res.records.site_logo){
                    document.querySelector("link[rel*='icon']").href = res.records.site_logo;
                }
            }

        }, (err) => {})
    }, [reload])
    
    return (
        <>
            <style>
                {`

                .loader {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0px;
                    top: 0px;
                    background: rgba(23, 97, 174, 1);
                    z-index: 1200;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-transition: 300ms;
                    transition: 300ms;
                }
                
                .loader.active {
                    visibility: hidden;
                    opacity: 0;
                    pointer-events: none;
                }
                
                .loader .loader-zoomer {
                    width: 100px;
                    height: 100px;
                    -webkit-animation: scaller 2s linear infinite;
                            animation: scaller 2s linear infinite;
                }

                @-webkit-keyframes scaller {
                    50% {
                    -webkit-transform: scale(0.4);
                            transform: scale(0.4);
                    }
                    100% {
                    -webkit-transform: scale(1);
                            transform: scale(1);
                    }
                }
                
                @keyframes scaller {
                    50% {
                    -webkit-transform: scale(0.4);
                            transform: scale(0.4);
                    }
                    100% {
                    -webkit-transform: scale(1);
                            transform: scale(1);
                    }
                }

                .loader div{
                    height: 300px;
                    width: 300px;
                    text-align: center;
                    padding-top: 100px;
                    border-radius: 50%;
                    border: 2px solid white;
                }

            `}
            </style>
            <div className="loader active" id="loader">
                <div className=''>
                    <img className="loader-zoomer" src="/images/tawreed_loader.png" alt="" />
                </div>
            </div>
            <nav className={`navbar navbar-expand-lg navbar-light ${langType === 'ur' || langType === 'ar' ? ' direction-rtl' : ''}`}>
                <div className="container">
                    <NavLink className="navbar-brand" to='/'>
                        <img src={ASSET_ENDPOINT + data.site_logo} onError={noimageHandle} className="w-100 rounded" alt={ASSET_ENDPOINT + data.site_logo_alt} />
                    </NavLink>
                    <button className="navbar-toggler px-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">{data.link_home}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="about-us">{data.link_about}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="contact-us">{data.link_contact}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="advertise">{data.link_advertise}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={logUser && logUser.userType ? logUser.userType+ '/dashboard' : 'login'}>{data.link_login}</NavLink>
                            </li>
                            
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <svg className="icon tscale-1p1" role="img">
                                            <use href="#icon_globe" />
                                        </svg>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <li className={`dropdown-item languageChange ${langType === 'en' ? 'active' : ''}`} onClick={() => languageChange('en')}>English</li>
                                    <li className={`dropdown-item languageChange my-1 ${langType === 'ar' ? 'active' : ''}`} onClick={() => languageChange('ar')}>Arabic</li>
                                    <li className={`dropdown-item languageChange ${langType === 'ur' ? 'active' : ''}`} onClick={() => languageChange('ur')}>Urdu</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div> 
            </nav>

        </>
    )
}
export default FrontendHeader;