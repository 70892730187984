import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { toastNotify, validateForm, loadingData } from "../../../components/Helper";
import { fetchData, UPDATE_TERMS_CONDS } from "../../../components/Service";

function TermsConditions() {
    window.document.title = "Terms Conditions Page Settings"; 
    const [langType, setLangType] = useState('en');
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        loadingData(true)
        fetchData(UPDATE_TERMS_CONDS + `?lang_type=${langType}`, 'GET', '', true, true, (res) => {
            loadingData(false)
            if (res.status) {
                setData(res.records.words)
                const parser = new DOMParser();
                const doc = parser.parseFromString(res.records.words, 'text/html');
                const contentBlock = htmlToDraft(doc.body.innerText);
                const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock));
                setEditorState(editorState)
            }

        }, (err) => { })
    }, [langType])

    const updateTermConds = async (e) => {
        let formData = document.getElementById('cms__terms_conds').value;
        if (validateForm(e)) {
            setTinyloader(true);
            fetchData(UPDATE_TERMS_CONDS+`?lang_type=${langType}`, 'POST',{cms__terms_conds:formData}, true, false, (res) => {
                setTinyloader(false);
                if (res.status) {
                    toastNotify('success', res.message);
                } else {
                    if (Object.keys(res.errors).length > 0) {
                        [res.errors].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    } else {
                        toastNotify('danger', res.message)
                    }
                }
            }, (err) => { });
        }
    }

    const setLang = (lang) => {
        document.getElementById('updateTermCond').reset()
        setLangType(lang)
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between d-md-none">
                        <div className="col mb-2">
                            <h4 className="page-heading">Terms Conditions Page Settings</h4>
                        </div>
                    </div>
                    <div className="row">
                        <form className="needs-validation" id="updateTermCond" noValidate method="post">
                            <div className="col-sm-12">
                                <ul className="nav nav-pills nav-pills-common mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'en' ? ' active' : ''}`} data-tablang="#englishbox" type="button" onClick={(e) => setLang('en')} role="tab">In English</button>
                                    </li>
                                    <li className="nav-item px-1" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ar' ? ' active' : ''}`} data-tablang="#arabicbox" type="button" onClick={(e) => setLang('ar')} role="tab">In Arabic</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link rounded-pill ${langType === 'ur' ? ' active' : ''}`} data-tablang="#urdubox" type="button" onClick={(e) => setLang('ur')} role="tab">In Urdu</button>
                                    </li>
                                </ul>
                                <div className="bg-white p-3 rounded-10" id="englishbox" role="tabpanel">
                                    <div className="row my-3">
                                        <div className="col-md-12">
                                            <h6 className="text-blue">Terms & Conditions Page in {langType === 'en' ? 'English' : langType === 'ar' ? 'Arabic' : 'Urdu'}<strong className="text-danger">*</strong>
                                            </h6>
                                            <div className="h-4 maxw-40 bg-light-blue opacity-50"></div>
                                        </div>
                                    </div>
                                    {data ?
                                        <div className="row mb-3">
                                            <div className="col-md-12" dir={langType !== 'en' ? 'rtl' : ''}>
                                                <textarea className="form-control d-none" name="cms__terms_conds" id="cms__terms_conds" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} readOnly required></textarea>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => onEditorStateChange(e)}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                    <div className="row mt-3 py-3">
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-primary rounded-pill minw-160 fw-medium" onClick={(e) => updateTermConds(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Submit'
                                                        :
                                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default TermsConditions;
